import React from 'react';
import {createMuiTheme, withStyles} from "@material-ui/core";
import Routes from "./Utils/Routes/Routes";
import {BrowserRouter} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import ScrollToTop from "./Utils/Routes/ScrollToTop";
import {globalFontFamily} from "./Utils/Constants/Constants";

const style = makeStyles({
        '@global': {
            '*::-webkit-scrollbar': {
                width: '0.75em',
                height: '0.75em',
            },
            '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.3)',
                // outline: '1px solid slategrey'
            },
        },
    })
;

const theme = createMuiTheme({

    palette: {

        primary: {
            main: '#283445',
            light: '#3259af'
        },
        secondary: {
            main: '#b4b4b4',
        },
        delete: {
            main: 'red'
        },
        submit: {
            main: 'green'
        }

    },

    overrides: {
        MuiButton: {
            root: {
                fontFamily: globalFontFamily,
            }
        }
    }
});

function App(props) {

    return (

        <BrowserRouter>
            <ScrollToTop/>
            <ThemeProvider theme={theme}>
                <div
                    style={{fontFamily: globalFontFamily}}
                >
                    <Routes/>
                </div>
            </ThemeProvider>
        </BrowserRouter>

    );
}

export default withStyles(style)(App);
