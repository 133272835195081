import React from 'react';
import {colorCodes} from "../../../../Utils/Constants/Constants";
import {makeStyles} from "@material-ui/styles";
import SectionHeader from "../../../../Components/HomePage/Home/SectionHeader/SectionHeader";
import MultiCarousel from "../../../../Components/HomePage/Home/OurPartners/OurPartnersSliderLayout/MultiCarousel";

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: '5%',
        marginRight: '5%',
        backgroundColor: '#eaf3f6',
        boxShadow: `-3px 3px 3px 1px ${colorCodes.homeShadow}, 0 0, 3px 3px 3px 1px ${colorCodes.homeShadow}`
    },
    blogSlider: {
        paddingTop: 5,
        paddingBottom: 10,
        // marginLeft: 50,
        // marginRight: 50
    },
    header: {
        paddingTop: 30,
        paddingBottom: 10
    }
}));

const OurPartners = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <SectionHeader text={'COLLABORATORS'}/>
            </div>
            <div className={classes.blogSlider}>
                <MultiCarousel/>
            </div>

        </div>
    );
};

export default OurPartners;