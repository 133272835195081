import React from 'react';
import {colorCodes, globalFontFamily} from "../../Utils/Constants/Constants";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {FaWrench} from 'react-icons/fa';
import {withRouter} from "react-router-dom";

const ErrorPage = (props) => {


    const handleClick = () => {
        props.history.replace('/user/blog/3')
    };

    return (
        <div>
            <Grid container style={{
                padding: 10,
                textAlign: "center",
                height: '100vh',
                width: '100vw',
                boxSizing: "border-box",
                background: colorCodes.homeInnerComponent,
                fontFamily: globalFontFamily
            }}
                  justify={"center"} alignItems={"center"}
            >
                <Grid item>
                    <div style={{paddingBottom: 10}}>
                        <FaWrench size={'50'}/>
                    </div>
                    <div style={{padding: '10px 10px 30px 10px', fontSize: 25, fontWeight: 700}}>

                        Currently our server is under maintenance! <br/>
                        <span style={{fontSize: 14, paddingTop: 10}}>Please try again later.</span>
                    </div>

                    <div style={{paddingTop: 20, paddingBottom: 10,}}>
                        In the meantime,
                    </div>
                    <div>
                        <Button variant={"contained"}
                                style={{color: "white", textTransform: "none", backgroundColor: colorCodes.headerBlue}}
                                onClick={handleClick}
                                disableElevation={true}>
                            Read some tips for the Aspiring PG students
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default withRouter(ErrorPage);
