import React, {Component, Fragment} from 'react';
import HeroArea from "./HeroArea/HeroArea";
import BlogSlider from "./BlogSlider/BlogSlider";
import Footer from "./Footer/Footer";
import FooterNote from "./FooterNote/FooterNote";
import {withStyles} from "@material-ui/core";
import {colorCodes, globalFontFamily, metaDescription} from "../../../Utils/Constants/Constants";
import OurPartners from "./OurPartners/OurPartners";
import Messenger from "../Messenger/Messenger";
import Helmet from "react-helmet";
import {withRouter} from "react-router-dom";
import QuickTestDialog from "../../StudentDashboard/QuickTest/QuickTestDialog";
import LoginLayout from "./HeroArea/RegisterSection/LoginLayout/LoginLayout";
import TopBar from "./TopBar/TopBar";
import ErrorWrapper from "../../ErrorBoundary/ErrorWrapper/ErrorWrapper";
import ErrorDisplayerTestList from "../../ErrorDisplayer/ErroDisplayerTestList/ErrorDisplayerTestList";
import SignUpStepper from "../../../Components/HomePage/SignUp/SignUpStepper/SignUpStepper";

const style = theme => ({
    root: {
        background: colorCodes.backgroundMain,
        minHeight: '100vh',
        width: '100%',
        boxSizing: 'border-box',
        paddingBottom: 80,
    },
    hiddenDiv: {
        height: 80
    },
    topBarContainer: {
        marginLeft: '5%',
        marginRight: '5%',
        marginBottom: 20
    },
    formContainer: {
        boxSizing: 'border-box',
        marginLeft: '5%',
        marginRight: '5%',
        boxShadow: `0px 0px 5px 2px ${colorCodes.homeShadow}`
        // '-1px 0px 10px -4px #999999, 2px 2px 5px #999999, 10px 5px 10px -5px #999999, -5px -7px 10px -1px #999999',
    }

});


class Home extends Component {

    componentDidMount(): void {
        document.body.scrollTop = 0;
    }


    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>

                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Login</title>
                    <meta name="robots" content="disallow"/>
                    <meta name="description"
                          content={"Ready to practice CEE Pattern Live test and subject wise test in the best PG/MDMS preparation platform in Nepal? Sign-up now!\n"}
                    />
                </Helmet>

                <div className={classes.topBarContainer}>
                    <TopBar/>
                </div>

                <div className={classes.formContainer} style={{backgroundColor: colorCodes.homeInnerComponent, padding: '40px 0 20px 0'}}>

                    <ErrorWrapper
                        fallbackComponent={
                            <div style={{maxWidth: 750, margin: '0 auto', paddingTop: 30, paddingBottom: 30}}>
                                <ErrorDisplayerTestList
                                    message={
                                        <Fragment>
                                            <div>
                                                An error occurred.
                                            </div>
                                            <div>
                                                Please try again later.
                                            </div>
                                        </Fragment>
                                    }/>
                            </div>
                        }
                    >
                        <LoginLayout/>
                    </ErrorWrapper>
                </div>

                <QuickTestDialog/>

            </div>


        );
    }
}

export default withStyles(style)(withRouter(Home));
