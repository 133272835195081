const initialState = {
    showPopup: false
}

const PopupStatusReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'changePopUpStatus':

            return {
                ...state,
                showPopup: action.value
            };


        default:

            return state

    }

};


export default PopupStatusReducer;
