const initialState = {};

const DashboardReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'addDashboard':
            return {
                ...action.value
            };

        case 'addImageURL':
            return {
                ...state,
                profilePicUrl: action.value
            };

        case 'removeDashboard':
            return {
                ...initialState
            };

        case 'testPackageComplete':

            return {
                ...state,
                userPlan: {
                    ...state.userPlan,
                    consumedSheduleTest: 10
                }
            };

        default:
            return state;
    }

};

export default DashboardReducer;