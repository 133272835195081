const initialState = {

    refreshUrl: []

};

const SocketRefreshUrlReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'setRefreshUrl':

            let tempUrl = state.refreshUrl;

            if (action.value !== process.env.REACT_APP_getNotification.slice(0, -1)) {
                tempUrl.push(process.env.REACT_APP_getNotification.slice(0, -1));
                tempUrl.push(action.value);
            } else {
                tempUrl.push(action.value);
            }


            return {
                ...state,
                refreshUrl: tempUrl
            }


        case 'removeRefreshUrl':

            let tempArr = state.refreshUrl.filter(item => item !== action.value);

            return {
                ...state,
                refreshUrl: tempArr
            }


        default:

            return state;

    }

}

export default SocketRefreshUrlReducer;
