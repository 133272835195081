import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core";
import {colorCodes} from "../../../../Utils/Constants/Constants";
import {Link, withRouter} from "react-router-dom";
import {makeStyles} from "@material-ui/styles";
import Styles from "./BlogView.module.css";


const style = theme => ({
    root: {
        cursor: "pointer",
        color: colorCodes.textBlue
    },
    blogTitle: {
        fontSize: 20,
    },
    blogContent: {
        fontSize: 14,
        paddingTop: 5,
        paddingBottom: 5
    },
    divPaddingImage: {
        paddingTop: 7,
        paddingBottom: 5,
    },
    divPaddingBlogInfo: {
        paddingTop: 7,
        paddingBottom: 5,
    },
    blogName: {
        marginBottom: '1px solid black'
    }
});

class BlogViewTemp extends Component {
    render() {
        const {classes} = this.props;
        let small = this.props.size === 'small';


        let descriptionText = this.props.content.content[1].slice(0, 220) + '...';
        return (
            <div className={classes.root}
                 onClick={() => this.props.history.push(`/user/blog/${this.props.blogIndex + 1}`)}>
                <div className={classes.divPaddingImage}
                     style={{
                         height: 200,
                         backgroundImage: `url(${this.props.content.imageURLPreview})`,
                         backgroundRepeat: 'no-repeat',
                         backgroundPosition: 'center center',
                         backgroundSize: 'cover'
                     }}>
                </div>
                <div className={classes.divPaddingBlogInfo} style={{fontSize: 16}}>
                    <Grid container justify={"space-between"}
                          style={{
                              borderBottom: small ? '1px solid black' : '2px solid black',
                              borderColor: colorCodes.textBlue,
                              paddingBottom: 5
                          }}
                          alignItems={"center"}
                    >
                        <Grid item>
                            {this.props.content.author}
                        </Grid>
                        <Grid item style={{fontSize: 14}}>
                            {this.props.content.date}
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <div className={classes.blogTitle} style={{fontSize: small ? 12 : null}}>
                        {this.props.content.title}
                    </div>
                    <div className={classes.blogContent} style={{fontSize: small ? 10 : null, textAlign: "justify"}}>
                        {descriptionText}
                    </div>
                </div>
            </div>
        );
    }
}


const useStyles = makeStyles(theme => ({
    root: {
        fontFamily: `'Nunito', sans-serif`,
        paddingBottom: 10,
        [theme.breakpoints.down('sm')]: {}
    },
    blogContainer: {
        width: 310,
        borderRadius: 5,
        boxShadow: `0 0 3px 1px ${colorCodes.shadowLightGray}`,
        background: '#f1f1f1',
        color: colorCodes.textBlue,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 300
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: 230
        },

    }
}));


const BlogView = (props) => {

    const classes = useStyles();


    let small = props.size === 'small';

    let descriptionText = props?.content?.content[1].length > 220 ? props?.content?.content[1]?.slice(0, 220) + '...': props.content.content[1];

    return (
        <Fragment>
            <Grid container className={classes.root}
                  justify={"center"}
            >
                <Link to={`/user/blog/${props.blogIndex + 1}`}>
                    <div
                        className={classes.blogContainer}
                        style={{cursor: 'pointer', padding: 10}}
                    >
                        <div style={{height: 283, overflow: 'hidden', padding: '1px 5px 5px 5px',}}>
                            <div style={{
                                fontSize: 20,
                                fontWeight: 600,
                                padding: '0 0 0px 0',
                                textAlign: 'left',
                                marginBottom: -2
                            }}>
                                <h4 style={{padding: 0, margin: 0, fontWeight: 600}}>
                                    {
                                        props.content.title.length >= 27 ? props.content.title.slice(0, 27) + '...':props.content.title
                                    }
                                </h4>
                            </div>
                            <div style={{
                                fontSize: 12,
                                fontWeight: 600,
                                padding: '0 0 0px 0',
                                textAlign: 'left',
                                color: colorCodes.buttonGray,
                                marginBottom: -5
                            }}>

                                {
                                    props.content.author ?
                                        <div>
                                            Author: &nbsp;
                                            <i>
                                                {props.content.author}
                                            </i>
                                        </div>
                                        : null
                                }
                            </div>
                            <div className={Styles.preLoaderLayout}
                                 style={{fontSize: 16, padding: '0 0 0px 0', textAlign: "left"}}>
                                <div style={{margin: '10px 0 10px 0'}}>
                                    <img src={`${props.content.imageURLPreview}`} alt="imgBlog"/>
                                </div>
                                <div>
                                    {descriptionText}
                                </div>
                            </div>
                        </div>
                        <div style={{
                            textAlign: "center",
                            fontSize: 12,
                            fontWeight: 600,
                            padding: '0px -5px 0px -5px',
                            margin: 10
                        }}>
                        </div>
                        <div style={{
                            textAlign: "center",
                            fontSize: 12,
                            zIndex: 5000,
                            fontWeight: 600,
                            color: colorCodes.headerBlue,
                            borderRadius: '0 0 5px 5px',
                            padding: 3
                        }}>
                            Click to read more..
                        </div>
                    </div>
                </Link>
            </Grid>
        </Fragment>
    );

};


export default withStyles(style)(withRouter(BlogView));
