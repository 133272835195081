import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import {colorCodes, globalFontFamily} from "../../../../../../Utils/Constants/Constants";
import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import SvgSignInBoxHeader from "../../../../../../Icons/SignInBoxHeader";
import IconButton from "@material-ui/core/IconButton";
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Button from "@material-ui/core/Button";
import querystring from "querystring";
import {authorizationRequest} from "../../../../../../Utils/HttpRequests/HttpRequests";
import Grid from "@material-ui/core/Grid";
import Collapse from "@material-ui/core/Collapse";
import {withRouter} from "react-router-dom";
import {CircularProgress, useMediaQuery} from "@material-ui/core";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

const theme = createMuiTheme({
    overrides: {
        MuiInput: {
            input: {
                '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 30px white inset',
                    // WebkitTextFillColor: '#fff',
                },
            },
        },
    }
})

const useStyles = makeStyles(theme => ({
    inputLabel: {
        color: 'gray',
        fontSize: '11px',
        marginBottom: 0
    },
    inputFieldCustomMargin: {
        paddingTop: 6,
        paddingBottom: 6,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 3,
            paddingBottom: 3,
        },
    },
    inputFieldProps: {
        background: 'white',
        textAlign: 'center',
        height: 40,
        borderRadius: 5,
        padding: '0px 10px 0px 10px',
        fontSize: 14,
        fontWeight: 600,
        color: colorCodes.textBlue,
        [theme.breakpoints.down('sm')]: {
            height: 30,
            fontSize: 12,
            padding: '0px 5px 0px 5px',
        },

    },
    captcha: {
        '&:before': {
            content: '',
            background: 'red',
            display: 'block'
        }
    },
    button: {
        margin: theme.spacing(1),
    },
}));

const LoginLayout = (props) => {

    const classes = useStyles();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    // showPassword: false,
    //     account: {
    //     userName: '',
    //         password: ''
    // },
    // submitting: false,
    //     submitted: false,
    //     message: ''

    const [showPassword, setShowPassword] = useState(false);
    const [account, setAccount] = useState(
        {
            userName: '',
            password: ''
        },
    );

    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState('');


    const handleChange = name => ({target: {value}}) => {
        setAccount(prevState => ({
            ...prevState,
            [name]: value
        }))
    };

    const handleShowPassword = () => {
        setShowPassword(prevState => !prevState);
    };

    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };
    const handleSubmit = () => {

        if (localStorage.getItem('user') !== null) {
            props.history.push('/user/dashboard/home');
        }

        if (validateForm()) {

            setSubmitting(true);

            let data = querystring.stringify({
                "grant_type": "password",
                "client_id": "medical-test",
                "username": account.userName.trim(),
                "password": account.password.trim()
            });

            authorizationRequest(props, 'loginURL', data).then(response => {

                if (response.status === 'failure') {
                    setSubmitting(false);
                    if (response.body.response.data.error_description === 'User account is locked') {
                        setMessage('Please verify your email first. If you did not receive email in you inbox, please check spam folder OR contact support.');
                    } else {
                        setMessage(response.body.response.data.error_description);
                    }


                }else{
                    setMessage('');
                }

            });

        }
    };

    const validateForm = () => {
        // eslint-disable-next-line
        const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (account.userName === '') {
            setMessage('Enter Username.');
            return false
        } else if (!regEmail.test(account.userName.trim())) {
            setMessage('Email is invalid.');
            return false
        } else if (account.password === '') {
            setMessage('Enter Password.');
            return false;
        }
        setMessage('');
        return true;
    };


    const loggedIn = localStorage.getItem('user') !== null;

    return (
        <Grid container
              justify={'center'}
            // alignItems={"flex-start"}
        >

            <div style={{
                // background: 'rgba(255,255,255,0.71)',
                borderRadius: 5,
                padding: matches ? 10 : 20,
                minHeight: matches ? loggedIn ? 70 : 170 : loggedIn ? 100 : 230,
                marginTop: matches ? 10 : 20
                // width: '100%',
            }}>

                <div style={{position: 'relative', marginRight: 40}}>
                    <IconButton style={{
                        color: 'white',
                        position: "absolute",
                        top: -50,
                        left: '47%',
                        margin: '0 auto'

                    }}
                                size={"small"}
                                disabled
                    >
                        <SvgSignInBoxHeader/>
                    </IconButton>
                </div>
                <div style={{
                    textAlign: "center",
                    color: colorCodes.textBlue,
                    fontSize: 18,
                    paddingTop: matches ? 5 : 10,
                    paddingBottom: matches ? 5 : 10,
                    fontWeight: 600
                }}>
                    {loggedIn ? 'You are Logged In' : 'Sign In'}
                </div>

                <Collapse in={message}>
                    <div style={{
                        textAlign: 'center',
                        paddingBottom: matches ? 5 : 10,
                        maxWidth: loggedIn ? 260 : 300,
                        color: colorCodes.buttonLightRed
                    }}>
                        <span style={{fontWeight: 600, fontSize: 14}}>{message}</span>
                    </div>
                </Collapse>

                {!loggedIn && (
                    <ThemeProvider theme={theme}>
                        <div style={{marginBottom: matches ? -10 : null}}>

                            <TextField
                                value={account.userName}
                                onKeyDown={handleKeyDown}
                                onChange={handleChange('userName')}
                                disabled={submitting}
                                InputProps={{
                                    className: classes.inputFieldProps,
                                    disableUnderline: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PersonIcon style={{color: colorCodes.headerBlue, fontSize: 30}}/>
                                        </InputAdornment>
                                    ),

                                }}
                                name='email'

                                fullWidth
                            />

                        </div>
                        <br/>
                        <div style={{marginBottom: matches ? -5 : null}}>

                            <TextField
                                onKeyDown={handleKeyDown}
                                name={'password'}
                                value={account.password}
                                onChange={handleChange('password')}
                                type={showPassword ? 'text' : 'password'}
                                disabled={submitting}
                                InputProps={{
                                    className: classes.inputFieldProps,
                                    disableUnderline: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon style={{
                                                color: colorCodes.headerBlue,
                                                fontSize: 25,
                                                paddingLeft: 2,
                                                paddingRight: 2
                                            }}/>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position='end' onClick={handleShowPassword}>
                                            {showPassword ? (
                                                <VisibilityOffIcon style={{
                                                    color: colorCodes.headerBlue,
                                                    fontSize: 25,
                                                    paddingLeft: 2,
                                                    cursor: 'pointer'
                                                }}/>
                                            ) : (
                                                <VisibilityIcon style={{
                                                    color: colorCodes.headerBlue,
                                                    fontSize: 25,
                                                    paddingLeft: 2,
                                                    cursor: 'pointer'
                                                }}/>
                                            )}
                                        </InputAdornment>
                                    )
                                }}

                                fullWidth
                            />

                        </div>
                    </ThemeProvider>
                )}
                <div style={{paddingTop: loggedIn ? 10 : 20,}}>
                    <Button color={"primary"} variant={"contained"}
                            disabled={submitting}
                            size={matches ? "small" : 'medium'}
                            style={{
                                width: loggedIn ? 260 : 300,
                                borderRadius: 5, textTransform: "none", fontFamily: globalFontFamily,
                                fontWeight: 600,
                                backgroundColor: colorCodes.topBarButton,
                                fontSize: matches ? 14 : 16,
                            }}
                            fullWidth
                            onClick={handleSubmit}
                    >
                        {!submitting ? (
                            loggedIn ? (
                                <strong>Go to Dashboard</strong>
                            ) : (
                                <strong>Login</strong>
                            )

                        ) : (
                            <CircularProgress style={{color: "white"}} size={'1.7rem'}/>
                        )}
                    </Button>
                </div>

                {!loggedIn && (

                    <div style={{
                        fontSize: matches ? 12 : 14,
                        fontWeight: 600,
                        color: colorCodes.textBlue,
                        textAlign: 'center',
                        paddingTop: matches ? 5 : 20
                    }}>

                        <a
                            href={'/user/forget-password'}
                            style={{
                                textDecoration: 'none',
                                color: colorCodes.textBlue,
                                borderBottom: `1px solid ${colorCodes.textBlue}`,
                                cursor: 'pointer'
                            }}
                            // onClick={() => props.history.push('/user/forget-password')}
                        >

                            Forgot Password?

                        </a>

                    </div>
                )}
            </div>
        </Grid>
    );
};

export default withRouter(LoginLayout);
