import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {combineReducers, createStore} from "redux";

import {Provider} from "react-redux";
import QuestionReducer from "./Store/QuestionReducer";
import AccountReducer from "./Store/AccountReducer";
import TestSessionReducer from "./Store/TestSessionReducer";
import AlertReducer from "./Store/AlertReducer";
import TestPreviewReducer from "./Store/TestPreviewReducer";
import TestSessionDialogReducer from "./Store/TestSessionDialogReducer";
import DrawerReducer from "./Store/DrawerReducer";
import PreventRouting from "./Store/PreventRouting";
import DashboardReducer from "./Store/DashboardReducer";
import TestResultReducer from "./Store/TestResultReducer";
import FlaggedQuestionReducer from "./Store/FlaggedQuestionReducer";
import NonScheduledTestListState from "./Store/NonScheduledTestListState";
import ScheduledTestReviewReducer from "./Store/ScheduledTestReviewReducer";
import NotificationReducer from "./Store/NotificationReducer";
import RandomTestReducer from "./Store/RandomTestReducer";
import SocketRefreshUrlReducer from "./Store/SocketRefreshUrlReducer";
import FeedbackDialogReducer from "./Store/FeedbackDialogReducer";
import QuickTestReducer from "./Store/QuickTestReducer";
import PopupStatusReducer from "./Store/PopupStatusReducer";
import AdvertisementReducer from "./Store/AdvertisementReducer";


const rootReducer = combineReducers({
    account: AccountReducer,
    question: QuestionReducer,
    testSession: TestSessionReducer,
    alertReducer: AlertReducer,
    testPreviewReducer: TestPreviewReducer,
    dialogReducer: TestSessionDialogReducer,
    drawerReducer: DrawerReducer,
    preventRoutingReducer: PreventRouting,
    dashboard: DashboardReducer,
    testResult: TestResultReducer,
    flaggedQuestion: FlaggedQuestionReducer,
    nonScheduledTestListState: NonScheduledTestListState,
    scheduledTestReview: ScheduledTestReviewReducer,
    notificationReducer: NotificationReducer,
    randomTestReducer: RandomTestReducer,
    socketRefreshUrlReducer: SocketRefreshUrlReducer,
    feedbackDialogReducer: FeedbackDialogReducer,
    quickTestReducer: QuickTestReducer,
    popupStatusReducer: PopupStatusReducer,
    advertisementReducer: AdvertisementReducer
});

let store;

if (process.env.NODE_ENV === 'development') {

    store = createStore(
        rootReducer,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );

} else {

    store = createStore(
        rootReducer
    );

}


// Sentry.init({dsn: "https://88f45515a4ba44f1ac6a144b8093a08c@o426983.ingest.sentry.io/5370453"});

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
