import React, {useEffect, useState} from 'react';
import Helmet from "react-helmet";
import TopBar from "../Home/TopBar/TopBar";
import BlogsLayout from "../../../Components/HomePage/Blogs/BlogsLayout";
import {colorCodes, metaDescription} from "../../../Utils/Constants/Constants";
import Footer from "../Home/Footer/Footer";
import FooterNote from "../Home/FooterNote/FooterNote";
import {makeStyles} from "@material-ui/styles";
import File from "../../../Assets/HowTo.pdf";
import PdfViewer from "../../StudentDashboard/HowToUse/PdfViewer";
import {getRequest} from "../../../Utils/HttpRequests/HttpRequests";
import {withRouter} from "react-router-dom";
import {Collapse} from "@material-ui/core";
import ReactPlayer from "react-player";
import Styles from "../../StudentDashboard/Home/DashboardVideo/DashboardVideo.module.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles(theme => ({
    root: {
        background: colorCodes.backgroundMain,
        minHeight: '100vh',
        width: '100%',
        boxSizing: 'border-box',
    },
    topBarContainer: {
        marginLeft: '5%',
        marginRight: '5%',
        marginBottom: 20
    },
    blogContainer: {
        // boxSizing: 'border-box',
        // minHeight: 1000,
        marginLeft: '5%',
        marginRight: '5%',
        boxShadow: `0px 0px 5px 2px ${colorCodes.homeShadow}`
        // '-1px 0px 10px -4px #999999, 2px 2px 5px #999999, 10px 5px 10px -5px #999999, -5px -7px 10px -1px #999999',
        // `-3px 3px 3px 1px ${colorCodes.homeShadow}, 0px -4px 5px 1px ${colorCodes.homeShadow}, 3px -0px 3px 1px ${colorCodes.homeShadow}`,
    }
}));


const HowToUseHome = (props) => {
    const classes = useStyles();

    const [videoUrl, setVideoUrl] = useState(null);

    useEffect(() => {

        getRequest(props, 'videoUrl', null, null, 'public').then(response => {

            if (response.status === "success") {
                if (response.body.data.filter(item => item.section === "HOW_TO_USE").length > 0) {
                    setVideoUrl(response.body.data.filter(item => item.section === "HOW_TO_USE")[0].link)
                }
            }

        })

    }, []);


    return (
        <div className={classes.root}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>How to use?</title>
                <meta name="description"
                      content={"There are three different kinds of tests that you can practice in PrePG Nepal. Schedule model tests happen at a fixed time and you can only appear for the test at the given time."}/>
            </Helmet>
            <div className={classes.topBarContainer}>
                <TopBar/>
            </div>
            <div className={classes.blogContainer}>

                <div style={{background: '#f2f2f2', paddingTop: 15, paddingBottom: 5, height: '100%'}}>
                    <div>
                        <Collapse in={videoUrl} mountOnEnter unmountOnExit>
                            <div
                                style={{
                                    maxWidth: 620,
                                    margin: '0 auto',
                                    textAlign: 'center',
                                    padding: '10px 8% 20px 8%'
                                }}
                            >
                                <ReactPlayer

                                    url={videoUrl}
                                    playing={false}
                                    width="100%"
                                    muted={false}
                                    loop={true}
                                    // height="100%"
                                    className={Styles.reactPlayer}
                                    config={{

                                        youtube: {

                                            // embedOptions: {
                                            //     height: 200,
                                            //     width: 300,
                                            //     boxShadow: '0px 0px 2px red'
                                            //     // host: 'https://www.youtube-nocookie.com'
                                            // },

                                            playerVars: {
                                                autoplay: 1
                                            }
                                        }
                                    }}

                                />
                            </div>
                        </Collapse>
                        <Collapse in={!videoUrl} mountOnEnter unmountOnExit>
                            <Grid container style={{height: 500}} justify={"center"} alignItems={"center"}>
                                <CircularProgress color={"secondary"}/>
                            </Grid>

                        </Collapse>
                    </div>


                    {/*<div style={{marginLeft: 10}}>*/}
                    {/*    <PdfViewer home={true}/>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div style={{background: colorCodes.backgroundMain}}>
                <Footer hideExtraSpace={true}/>
            </div>
            <div style={{background: colorCodes.backgroundMain}}>
                <FooterNote/>
            </div>

        </div>
    );
};

export default withRouter(HowToUseHome);
