import React, {Component} from 'react';
import SectionHeader from "../../SectionHeader/SectionHeader";
import {withStyles} from "@material-ui/core";
import {Link, withRouter} from "react-router-dom";
import Fade from "@material-ui/core/Fade";

const style = theme => ({
    header: {
        paddingTop: 30,
        paddingBottom: 10
    },
    textContainer: {
        cursor: 'pointer',
        transition: '0.2s',
        '&:hover ': {
            '& #more': {
                color: 'red',
                transition: '0.2s',
            }
        }
    }
});

class AboutUs extends Component {


    state = {
        showMore: true
    };


    handleClick = () => {

        this.props.history.push('/user/about-us')

    };

    render() {
        const {classes} = this.props;
        return (
            <div>

                <div className={classes.header}>
                    <SectionHeader text={"ABOUT US"} whiteUnderline={true}/>
                </div>
                <Link to={'/user/about-us'}>
                    <div
                        className={classes.textContainer}
                        style={{fontSize: 14, color: 'white'}}


                    >
                        We are a team of experienced doctors trying to innovate in the domain of medical education and
                        healthcare. In the realm of PG preparation, we have a reading room - “Central Readers’ Club”,
                        that
                        has been serving PG aspirants for over 2 years now. With the combined experience of our
                        versatile
                        team members...
                        <Fade
                            in={this.state.showMore}
                        >
                            <span style={{color: '#d7dfea', fontSize: 12}}> &nbsp;&nbsp;click to read more</span>
                        </Fade>

                    </div>
                </Link>
            </div>
        );
    }
}

export default withStyles(style)(withRouter(AboutUs));
