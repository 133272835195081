import React, {Component, Fragment} from 'react';
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {colorCodes} from "../../../../Utils/Constants/Constants";

const style = theme => ({
    textOuter: {
        fontSize: 18,
        borderBottom: '2px outset gray',
        paddingBottom: 5,
        width: 150,
        margin: '0 auto'
    },
    textInner: {
        fontSize: 18,
        borderBottom: '2px outset gray',
        paddingBottom: 4,
        width: 90,
        margin: '0 auto'
    },
});

class SectionHeader extends Component {
    render() {
        const {classes} = this.props;
        return (
            <Fragment>
                <Grid container alignItems={"center"} justify={"center"}>
                    <Grid item xs={12} style={{textAlign: "center"}}>

                        <span style={{
                            color: this.props.upgrade ? colorCodes.textBlue : this.props.whiteUnderline ? 'white' : colorCodes.textBlue,
                            fontWeight: this.props.upgrade ? 600 : 600,
                            fontSize: this.props.upgrade ? 16 : 17
                        }}
                        >
                            <h4 style={{padding: 0, margin: 0, fontWeight: 600}}>{this.props.text}</h4>
                        </span>

                        <div className={classes.textOuter}
                             style={{
                                 borderBottom: this.props.whiteUnderline ? '2px solid white'
                                     : this.props.upgrade ? `2px solid ${colorCodes.textBlue}`
                                         : `2px solid ${colorCodes.textBlue}`
                             }}>
                        </div>
                        <div className={classes.textInner}
                             style={{
                                 borderBottom: this.props.whiteUnderline ? '2px solid white'
                                     : this.props.upgrade ? `2px solid ${colorCodes.textBlue}`
                                         : `2px solid ${colorCodes.textBlue}`,
                             }}>
                        </div>
                    </Grid>
                </Grid>
            </Fragment>


        );
    }
}

export default withStyles(style)(SectionHeader);