import React, {Fragment, useEffect, useState} from 'react';
import ActiveBlogLayout from "./ActiveBlogLayout/ActiveBlogLayout";
import RecommendationBlogs from "./RecommendationBlogs/RecommendationBlogs";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/styles";
import {useTheme} from "@material-ui/core";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import {withRouter} from "react-router-dom";
import {globalFontFamily} from "../../../Utils/Constants/Constants";
import SectionHeader from "../Home/SectionHeader/SectionHeader";
import Helmet from "react-helmet";
import {blogContent} from "../../../Utils/Constants/BlogContent";

const useStyles = makeStyles(theme => ({

    root: {
        padding: 0,
        fontFamily: globalFontFamily,
        background: '#f2f2f2',
    },
    blogContainer: {
        background:  '#f2f2f2',
        margin: '0 auto',
        maxWidth: 850,
        padding: '20px 20px 20px 20px '
    }

}));


const BlogsLayout = (props) => {

    const theme = useTheme();
    const [blogId, setBlogId] = useState(props.match.params.id);
    const [tempArray] = useState([1, 2, 3, 4]);




    useEffect(() => {

        setBlogId(props.match.params.id);

    }, [props.match.params.id]);


    const classes = useStyles();


    return (
        <div className={classes.root}>



            <Helmet>
                <meta charSet="utf-8"/>
                <title>{blogContent[blogId].title}</title>
                <meta name="description"
                      content={blogContent[blogId].content[0]}
                />
            </Helmet>

            <ThemeProvider theme={theme}>

                <div className={classes.blogContainer}>

                    <div style={{width: '100%'}}>

                        <Grid container justify={"center"} spacing={2}>

                            <Grid item xs={12}>
                                <ActiveBlogLayout blogIndex={blogId}/>
                            </Grid>

                            <Grid item container justify={"center"} xs={12}>
                                <Grid style={{width: 180}}>
                                    <div className={classes.header}>
                                        <SectionHeader text={'Recommended'}/>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} container justify={"center"}
                                  style={{marginTop: 10, marginBottom: 10}}
                            >

                                {[...tempArray].map((item, index) => {

                                    return (
                                        <Fragment>
                                            {index + 1 !== parseInt(blogId) && (
                                                <Grid item xs={12} sm={3} style={{margin: 10}}>
                                                    <RecommendationBlogs blogIndex={item} tempArray={tempArray}
                                                                         selectedBlog={blogId}/>

                                                </Grid>
                                            )}
                                        </Fragment>
                                    )


                                })}

                            </Grid>
                        </Grid>
                    </div>

                </div>
            </ThemeProvider>
        </div>
    );
};

export default withRouter(BlogsLayout);
