import React, {useEffect, useState} from 'react';
// import { Document, Page } from 'react-pdf';
// import { Document, Page } from 'react-pdf/build/entry.noworker';
import './PdfViewer.css';


import {Document, Page, pdfjs} from "react-pdf";
import Grid from "@material-ui/core/Grid";
import {pdfData} from "../../../Utils/Constants/Constants";
import 'react-pdf/src/Page/AnnotationLayer.css';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const PdfViewer = (props) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(props.home ? 1 : 5); //setting 1 to show fisrt page

    // useEffect(() => {
    //
    //
    //     var fileReader = new FileReader();
    //     var base64;
    //     // Onload of file read the file content
    //     fileReader.onload = function(fileLoadedEvent) {
    //         base64 = fileLoadedEvent.target.result;
    //         // Print data in console
    //         console.log(base64);
    //     };
    //     // Convert data to base64
    //     fileReader.readAsDataURL(File);
    //
    // }, [])

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
        setPageNumber(props.home ? 1 : 5);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        if (!props.home && pageNumber === 5) return;
        changePage(-1);
    }

    function nextPage() {
        if (props.home && pageNumber === 5) return;
        changePage(1);
    }

    // const {pdf} = props;

    return (
        <div style={{
            maxWidth: props.dashboard ? 650 : 630, margin: '0 auto',
            // height: 800
            overflowX: 'auto',
            padding: 10
        }}>
            <Grid container justify={"space-between"} style={{marginTop: 0, marginBottom: 15}} alignItems={"center"}>
                <Grid item xs style={{textAlign: 'left'}}>
                    <button type="button" disabled={pageNumber <= 1} onClick={previousPage} className='buttonNav'>
                        Previous
                    </button>

                </Grid>
                <Grid item xs style={{textAlign: 'center'}}>
                    <p style={{fontSize: 14}}>
                        Page {pageNumber - (!props.home ? 4 : 0) || (numPages ? 1 : "--")} of {(parseInt(numPages) - (props.home ? 8 : 4)) || "--"}
                    </p>
                </Grid>
                <Grid item xs style={{textAlign: 'right'}}>
                    <button
                        type="button"
                        className='buttonNav'
                        disabled={pageNumber >= numPages}
                        onClick={nextPage}
                    >
                        Next
                    </button>

                </Grid>
            </Grid>
            <div>
                <Document
                    loading={null}
                    file={`data:application/pdf;base64,${pdfData}`}
                    // file={File}
                    options={{workerSrc: "pdf.worker.js"}}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page pageNumber={pageNumber} width={620} loading={<div style={{height: 700}}/>}
                          renderAnnotationLayer={true}/>
                </Document>
            </div>

        </div>
    );
};

export default PdfViewer;
