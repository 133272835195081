import React, {Component} from 'react';
import SectionHeader from "../../../../Components/HomePage/Home/SectionHeader/SectionHeader";
import BlogSliderComponent from "../../../../Components/HomePage/Home/BlogSliderComponent/BlogSliderComponent";
import {createMuiTheme, withStyles} from "@material-ui/core";
import {colorCodes} from "../../../../Utils/Constants/Constants";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

const style = theme => ({
    root: {
        marginLeft: '5%',
        marginRight: '5%',
        backgroundColor: colorCodes.homeInnerComponent,
        boxShadow: `-3px 3px 3px 1px ${colorCodes.homeShadow}, 0 0, 3px -0px 3px 1px ${colorCodes.homeShadow}`
    },
    blogSlider: {
        paddingTop: 10,
        paddingBottom: 5,
        // marginLeft: 50,
        // marginRight: 50
    },
    header: {
        paddingTop: 30,
        paddingBottom: 10
    }
});

const theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 850,
            md: 1220,
            lg: 1280,
            xl: 1920,
        },
    },
})

class BlogSlider extends Component {

    render() {

        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.header}>
                    <SectionHeader text={'BLOGS'}/>
                </div>
                <ThemeProvider theme={theme}>
                    <div className={classes.blogSlider}>
                        <BlogSliderComponent/>
                    </div>
                </ThemeProvider>
            </div>
        );
    }
}

export default withStyles(style)(BlogSlider);