/* eslint-disable */
import React, {Component} from 'react';
import {CircularProgress, createMuiTheme, TextField} from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import "react-image-crop/dist/ReactCrop.css";
import {connect} from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {withStyles} from "@material-ui/styles";
import axios from "axios";
import SecureLS from "secure-ls";
import Grid from "@material-ui/core/Grid";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {colorCodes, globalFontFamily} from "../../../../../Utils/Constants/Constants";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Collapse from "@material-ui/core/Collapse";

const styles = theme => ({
    inputLabel: {
        color: 'gray',
        fontSize: '11px',
        marginBottom: 0
    },
    inputFieldCustomMargin: {
        paddingTop: 6,
        paddingBottom: 6
    },
    inputFieldProps: {
        fontSize: 14,
        color: colorCodes.textBlue,
        // WebkitBoxShadow: '0 0 0 30px white inset',

    },
    captcha: {
        '&:before': {
            content: '',
            background: 'red',
            display: 'block'
        }
    },
});

const theme = createMuiTheme({
    palette: {
        primary: {
            main: colorCodes.formPrimary,
            light: '#3259af'
        },
        secondary: {
            main: colorCodes.formSecondary,
        },
    },
    overrides: {
        MuiPaper: {
            root: {
                display: "none"
            }
        },
        MuiInputBase: {
            input: {
                '&:-webkit-autofill': {
                    transitionDelay: '9999s',
                    transitionProperty: 'background-color, color',

                },
            },
        },
    }
});

const themeField = createMuiTheme({
    overrides: {
        MuiInput: {
            input: {
                '&:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 30px white inset',
                    // WebkitTextFillColor: '#fff',
                },
            },
        },
    }
})

let formDataToUpload = new FormData();

let ls = new SecureLS({
    encodingType: 'des',
    isCompression: false,
    encryptionSecret: 'thesecret'
});

class ProfileForm extends Component {
    state = {
        //question state
        open: false,
        account: {
            profilePictureURL: this.props.accountFromRedux.profilePictureURL,
            profilePictureName: this.props.accountFromRedux.profilePictureName,
            contactNumber: this.props.accountFromRedux.contactNumber,
            nmcNumber: this.props.accountFromRedux.nmcNumber,
            confirmPassword: '',
            password: '',
            userName: this.props.accountFromRedux.userName,
            fullName: this.props.accountFromRedux.fullName,
        },
        error: {
            userNameError: false,
            passwordError: null,
            confirmPasswordError: null,
            addressError: false,
            districtError: false,
            contactNumberError: false,
            // profilePictureURLError: false
        },
        helperText: {
            userNameText: '',
            passwordText: 'At least 8 characters with Lower case, Upper case, Number and Special character(among @$!%*?&)',
            confirmPasswordText: '',
            addressText: '',
            districtText: '',
            contactNumberText: '',
            profilePictureText: ''
        },
        show: {
            password: false,
            confirmPassword: false
        },

        passwordRequirementValidation: {

            lowerCase: false,
            upperCase: false,
            specialCharacter: false,
            number: false,
            eightCharacter: false

        },
        showPasswordHelper: false,
        submitting: false,
        submitted: false,
        ableToSubmit: null,
        imageSrcBase64: '',
        imageSrcBlob: '',
        croppedImageBlobUrl: '',
        croppedImageDataUrl: '',
        uploadingImage: false,
        uploadedImage: false,
        imageName: this.props.accountFromRedux.imageName,
        src: null,
        showCroppedImage: false,
        uploadDisable: false,

    };

    componentDidMount() {

        this.props.changeFormValue('termsAndConditions', false);
        this.setState({uploadedImage: true});
        //console.log(this.props.accountFromRedux.imageReference.src);
        // this.setState({
        //     account: {
        //         ...this.state.account,
        //         userName: this.props.accountFromRedux.userName,
        //         address: this.props.accountFromRedux.address,
        //         district: this.props.accountFromRedux.district,
        //         contactNumber: this.props.accountFromRedux.contactNumber,
        //         profilePictureURL: this.props.accountFromRedux.profilePictureURL
        //     }
        // })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.accountFromRedux !== this.props.accountFromRedux) {
            this.setState({
                account: {
                    ...this.state.account,
                    profilePictureURL: this.props.accountFromRedux.profilePictureURL,
                    profilePictureName: this.props.accountFromRedux.profilePictureName,
                    contactNumber: this.props.accountFromRedux.contactNumber,
                    nmcNumber: this.props.accountFromRedux.nmcNumber,
                    userName: this.props.accountFromRedux.userName,
                    fullName: this.props.accountFromRedux.fullName,
                }
            })
        }
        if (this.props.changeStepFromRedux === true) {
            this.handleSubmit();

        }
    }

    componentWillUnmount() {
        this.setState({submitting: false});
    }

    handleChange = name => ({target: {value}}) => {

        if (name === "password" || name === "confirmPassword") {
            this.handlePasswordValidation(name);
            if (name === 'password') {
                this.setState({showPasswordHelper: true});
                this.isLowerCaseCheck(value);
            }
            this.props.changeFormValue(name, value);
        } else {
            this.props.changeFormValue(name, value);
        }

        this.setState({
            account: {
                ...this.state.account,
                [name]: value
            },
        });

        if (name === "password" || name === "confirmPassword") {
            this.handlePasswordValidation(name, value)
        }

    };


    isLowerCaseCheck = (text) => {

        const {passwordRequirementValidation} = this.state;
        let letters = text;
        let specialCharacterSet = ['@', '!', '&', '*', '?', '&', '%'];

        passwordRequirementValidation.lowerCase = false;
        passwordRequirementValidation.upperCase = false;
        passwordRequirementValidation.specialCharacter = false;
        passwordRequirementValidation.number = false;
        passwordRequirementValidation.eightCharacter = false;

        for (let i = 0; i < letters.length; i++) {

            if (specialCharacterSet.includes(letters[i])) {
                passwordRequirementValidation.specialCharacter = true;
            }

            if (letters[i].charCodeAt(0) >= 97 && letters[i].charCodeAt(0) <= 122) {
                passwordRequirementValidation.lowerCase = true;
            }

            if (letters[i].charCodeAt(0) >= 65 && letters[i].charCodeAt(0) <= 90) {
                passwordRequirementValidation.upperCase = true;
            }

            if (parseInt(letters[i])) {
                passwordRequirementValidation.number = true;
            }

            if (letters.length >= 8 && letters.length <= 20) {
                passwordRequirementValidation.eightCharacter = true;
            }

        }

    };

    handleContactChange = (name) => (value) => {
        this.props.changeFormValue("contactNumber", value);
        this.setState({
            account: {
                ...this.state.account,
                contactNumber: value
            },
        })

    };


    onSelectFile = e => {
        //console.log(e.target.files[0]);
        this.props.changeFormValue('imageName', e.target.files[0].name);
        this.setState({uploadingImage: true});
        setTimeout(() => {
            this.props.changeFormValue("profilePictureURL", 'something');
            this.setState({uploadedImage: true, uploadingImage: false});
        }, 2000)
    };


    handleCropDone = () => {
        //TODO: submit cropped image to server
        this.setState({open: false, uploadingImage: true, uploadedImage: false, uploadDisable: true});

        this.props.changeFormButtonState(true);
        axios({
            url: 'https://admin-operation-services.herokuapp.com/admin/upload-image/user-profile',
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${ls.get('key1').data}`
            },
            data: formDataToUpload
        }).then((response) => {
            this.setState({
                uploadingImage: false,
                uploadedImage: true,
                uploadDisable: false,
                account: {
                    ...this.state.account,
                    profilePictureURL: response.data.profilePictureURl,

                    profilePictureName: response.data.profile_pic_name
                },
            });
            this.props.changeFormButtonState(false);
            this.props.changeFormValue('profilePictureURL', response.data.profilePictureURl);
            this.props.changeFormValue('profilePictureName', response.data.profile_pic_name);
            // this.props.changeFormValue('croppedImageBlobUrlInServer', this.props.accountFromRedux.croppedImageBlobUrlNotInServer);
        }).catch((error) => {
            //console.log(error);
            this.props.changeFormError('Error during image upload!');
            this.props.changeFormButtonState(false);
            this.setState({
                uploadingImage: false,
                uploadedImage: true,
                uploadDisable: false,
                account: {
                    ...this.state.account,
                    profilePictureURL: ''
                },
                error: {
                    ...this.state.error,
                    profilePictureURLError: false
                }
            });
        });

        //TODO: store url to redux state

    };

    validateForm = () => {

        const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const regPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
        let error = false;
        Object.keys(this.state.account).map(key => {
            switch (key) {
                case 'contactNumber':
                    if (this.state.account.contactNumber.trim()) {
                        if (this.state.account.contactNumber.trim().length !== 14) {
                            error = this.showError('Contact number must be of length 10.');
                        }
                    } else {
                        error = this.showError('Contact number can not be empty.');
                    }
                    break;
                case 'userName':
                    if (this.state.account.userName.trim()) {
                        if (!regEmail.test(this.state.account.userName.trim())) {
                            error = this.showError('Email is invalid.');
                        }
                    } else {
                        error = this.showError('Email cannot be empty.');
                    }
                    break;
                case 'fullName':
                    if (this.state.account.fullName.trim()) {
                        if (this.state.account.fullName.trim().length < 6) {
                            error = this.showError('Full name is too short.');
                        } else if (this.state.account.fullName.trim().length > 40) {
                            error = this.showError('Full name is too long.');
                        }
                    } else {
                        error = this.showError('Full name cannot be empty.');
                    }
                    break;
                case 'password':
                    if (this.state.account.password) {
                        if (!regPassword.test(this.state.account.password)) {
                            error = this.showError('Password didn\'t meet the requirement.');
                        }
                    } else {
                        error = this.showError('Password cannot be empty.');
                    }
                    break;

                case 'confirmPassword':
                    if (this.state.account.confirmPassword.trim()) {
                        if (this.state.account.password.trim() !== this.state.account.confirmPassword.trim()) {
                            error = this.showError('Confirmation password does not match.');
                        }
                    } else {
                        error = this.showError('Password confirmation is required.');
                    }
                    break;
                case 'nmcNumber':
                    if (this.state.account.nmcNumber.trim()) {
                        if (this.state.account.nmcNumber.trim().length > 5) {
                            error = this.showError('NMC number must be a 5 digit number.');
                        }
                    }
                    break;

                default:
                    break;

            }
        });

        return error;
    };

    showError = (message) => {
        this.props.changeFormError(message);
        return true
    };

    handleCheckboxChange = ({target: {checked}}) => {
        this.props.changeFormValue('termsAndConditions', checked);
    };


    handleSubmit = () => {

        this.props.changeStepMethod();
        if (this.validateForm()) {

        } else {
            this.setState({submitting: true});
            this.setState({submitted: true});
            this.props.changeFormError('');
            this.props.changeFormButtonState(false);
            this.props.changeActiveStepToMethod(this.props.changeToStepFromRedux);

        }

    };


    handleShowPassword = () => {
        this.setState(prevState => ({
                show: {
                    ...prevState.show,
                    password: !prevState.show['password']
                },
            })
        );

    };

    handleShowConfirmPassword = () => {
        this.setState(prevState => ({
                show: {
                    ...prevState.show,
                    confirmPassword: !prevState.show['confirmPassword']
                }
            })
        )
    };

    handleMouseDown = (event) => {
        event.preventDefault();
    };

    callback = (response) => {
    };

    getResponse = () => {

    };

    handleOnFocus = (field) => ({target: {value}}) => {
        if (field === 'password') {
            this.setState({showPasswordHelper: true});
        }
        this.handlePasswordValidation(field, value);
    };

    handlePasswordValidation = (field, value) => {
        let passwordError = false;
        let confirmPasswordError = false;

        const regPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
        let testValuePassword = field === 'password' ? value : this.state.account.password;
        if (!regPassword.test(testValuePassword)) {
            passwordError = true;
            confirmPasswordError = null;
        }

        if (!passwordError) {
            let testValueConfirmPassword = field === 'confirmPassword' ? value : this.state.account.confirmPassword;
            if (testValuePassword !== testValueConfirmPassword) {
                confirmPasswordError = true
            }
        }


        this.setState({
            error: {
                ...this.state.error,
                passwordError: passwordError,
                confirmPasswordError: confirmPasswordError
            }
        });
    };


    render() {

        const {classes} = this.props;
        // const {crop, croppedImageBlobUrl, imageSrcBlob} = this.state;
        let uploadState;


        if (this.state.uploadingImage) {
            uploadState = null;
        } else if (this.state.uploadedImage) {
            uploadState = (
                <div style={{textAlign: 'center'}}>
                    <div style={{marginTop: '15px'}}>
                        {this.props.accountFromRedux.profilePictureURL && (
                            this.props.accountFromRedux.imageName
                        )}
                    </div>
                </div>
            );
        }
        let tempContent;
        if (this.state.submitting) {
            tempContent = (
                <Grid container alignItems={"center"} justify={"center"} style={{minHeight: 420}}>
                    <Grid item>
                        <CircularProgress color="primary" size="3rem"/>
                    </Grid>
                </Grid>
            );
        } else {

            tempContent = (
                <div>

                    <div className={classes.inputFieldCustomMargin}>
                        <TextField
                            id="fullName"
                            label="Full Name"
                            color="secondary"
                            variant="outlined"
                            size={"small"}
                            InputProps={{
                                className: classes.inputFieldProps
                            }}
                            InputLabelProps={{
                                className: classes.inputFieldProps
                            }}
                            // value={this.props.accountFromRedux.fullName}
                            value={this.state.account.fullName}
                            onChange={this.handleChange('fullName')}
                            fullWidth
                        />
                    </div>
                    <div className={classes.inputFieldCustomMargin}>
                        <TextField
                            id="userName"
                            label="Email"
                            color="secondary"
                            variant="outlined"
                            size={"small"}
                            InputProps={{
                                className: classes.inputFieldProps
                            }}
                            InputLabelProps={{
                                className: classes.inputFieldProps
                            }}
                            value={this.state.account.userName}
                            onChange={this.handleChange('userName')}
                            fullWidth
                        />
                    </div>
                    <div className={classes.inputFieldCustomMargin}>
                        <TextField
                            // helperText={this.state.helperText.passwordText}
                            type={this.state.show.password ? 'text' : 'password'}
                            id="password"
                            label="Password"
                            color="secondary"
                            variant="outlined"
                            size={"small"}
                            InputLabelProps={{
                                className: classes.inputFieldProps
                            }}
                            FormHelperTextProps={{
                                className: classes.inputLabel
                            }}
                            value={this.state.account.password}
                            onChange={this.handleChange('password')}
                            onFocus={this.handleOnFocus('password')}
                            onBlur={this.handleOnFocus('password')}
                            fullWidth
                            InputProps={{
                                className: classes.inputFieldProps,
                                endAdornment:
                                    <InputAdornment position="end">
                                        {this.state.error.passwordError !== null ? (
                                            this.state.error.passwordError ? (
                                                <CloseIcon fontSize={"small"}
                                                           style={{color: colorCodes.buttonLightRed}}/>
                                            ) : (
                                                <CheckIcon fontSize={"small"}
                                                           style={{color: colorCodes.buttonLightGreen}}/>
                                            )
                                        ) : null}
                                        <IconButton
                                            onMouseDown={this.handleMouseDown}
                                            onClick={this.handleShowPassword}> {this.state.show.password ?
                                            <VisibilityOff size={"small"}/> :
                                            <Visibility size={"small"}/>}
                                        </IconButton>
                                    </InputAdornment>,
                            }}
                        />
                    </div>
                    <Collapse in={this.state.showPasswordHelper}>
                        {/*'At least 8 characters with Lower case, Upper case, Number and Special character(among @$!%*?&)'*/}
                        <Grid container style={{fontSize: 12, fontWeight: 700, margin: '10px 0 10px', paddingLeft: 20}}>

                            <Grid container>
                                <Grid item xs={6}
                                      style={{color: this.state.passwordRequirementValidation.specialCharacter ? colorCodes.buttonLightGreen : colorCodes.buttonLightRed}}>
                                    <li>Special character (among @$!%*?&)</li>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={6}
                                      style={{color: this.state.passwordRequirementValidation.eightCharacter ? colorCodes.buttonLightGreen : colorCodes.buttonLightRed}}>
                                    <li>Between 8 and 20 characters</li>
                                </Grid>
                                <Grid item xs={4}
                                      style={{color: this.state.passwordRequirementValidation.lowerCase ? colorCodes.buttonLightGreen : colorCodes.buttonLightRed}}>
                                    <li>Lower case</li>
                                </Grid>
                            </Grid>

                            <Grid container>

                                <Grid item xs={6}
                                      style={{color: this.state.passwordRequirementValidation.upperCase ? colorCodes.buttonLightGreen : colorCodes.buttonLightRed}}>
                                    <li>Upper case</li>
                                </Grid>
                                <Grid item xs={4}
                                      style={{color: this.state.passwordRequirementValidation.number ? colorCodes.buttonLightGreen : colorCodes.buttonLightRed}}>
                                    <li>Number</li>
                                </Grid>
                            </Grid>

                        </Grid>

                    </Collapse>
                    <div className={classes.inputFieldCustomMargin}>
                        <TextField
                            type={this.state.show.confirmPassword ? 'text' : 'password'}
                            id="confirmPassword"
                            label="Confirm Password"
                            color="secondary"
                            variant="outlined"
                            size={"small"}
                            InputLabelProps={{
                                className: classes.inputFieldProps
                            }}
                            value={this.state.account.confirmPassword}
                            onChange={this.handleChange('confirmPassword')}
                            onFocus={this.handleOnFocus('confirmPassword')}
                            onBlur={this.handleOnFocus('confirmPassword')}
                            fullWidth
                            InputProps={{
                                className: classes.inputFieldProps,
                                endAdornment:
                                    <InputAdornment position="end">
                                        {this.state.error.confirmPasswordError !== null ? (
                                            this.state.error.confirmPasswordError ? (
                                                <CloseIcon fontSize={"small"}
                                                           style={{color: colorCodes.buttonLightRed}}/>
                                            ) : (
                                                <CheckIcon fontSize={"small"}
                                                           style={{color: colorCodes.buttonLightGreen}}/>
                                            )
                                        ) : null}
                                        <IconButton
                                            onMouseDown={this.handleMouseDown}
                                            onClick={this.handleShowConfirmPassword}>
                                            {this.state.show.confirmPassword ?
                                                <VisibilityOff size={"small"}/> :
                                                <Visibility size={"small"}/>}
                                        </IconButton>
                                    </InputAdornment>,
                            }}
                        />
                    </div>
                    <div className={classes.inputFieldCustomMargin}>
                        <TextField
                            id="nmcNumber"
                            label="NMC Number (Optional)"
                            color="secondary"
                            variant="outlined"
                            type={'number'}
                            size={"small"}
                            InputProps={{
                                className: classes.inputFieldProps
                            }}
                            InputLabelProps={{
                                className: classes.inputFieldProps
                            }}
                            value={this.state.account.nmcNumber}
                            onChange={this.handleChange('nmcNumber')}
                            fullWidth
                        />
                    </div>
                    <div className={classes.inputFieldCustomMargin}>
                        <ThemeProvider theme={theme}>
                            <MuiPhoneNumber
                                color={"secondary"}
                                variant={"outlined"}
                                label="Contact Number"
                                size={"small"}
                                defaultCountry={"np"}
                                InputLabelProps={{
                                    className: classes.inputFieldProps
                                }}
                                InputProps={{
                                    className: classes.inputFieldProps
                                }}
                                value={this.state.account.contactNumber}
                                onChange={this.handleContactChange('contactNumber')}
                                onlyCountries={['np']}
                                countryCodeEditable={false}
                                fullWidth
                            />
                        </ThemeProvider>
                    </div>

                    {/*<div className={classes.inputFieldCustomMargin} style={{textAlign: 'center'}}>*/}
                    {/*    Profile Picture: &nbsp;&nbsp;*/}

                    {/*    <Button*/}
                    {/*        color={"primary"}*/}
                    {/*        variant="contained"*/}
                    {/*        size={"small"}*/}
                    {/*        component="label"*/}
                    {/*        style={{textTransform: "none"}}*/}
                    {/*        // startIcon={<CloudUploadIcon/>}*/}
                    {/*        // disabled={this.state.uploadDisable}*/}
                    {/*        disabled={true}*/}
                    {/*    >*/}
                    {/*        {this.state.uploadingImage ? (*/}
                    {/*            <CircularProgress color="secondary" size="1.3rem"/>*/}
                    {/*        ) : (*/}
                    {/*            'Upload'*/}
                    {/*        )}*/}
                    {/*        <input type="file" accept="image/jpeg, image/jpg" onChange={this.onSelectFile}*/}
                    {/*               onClick={this.value = null}*/}
                    {/*               style={{display: "none"}}/>*/}

                    {/*    </Button>*/}


                    {/*    &nbsp;&nbsp;&nbsp;*/}
                    {/*    {uploadState}*/}
                    {/*</div>*/}


                    <div style={{textAlign: 'center', marginTop: 10}}>
                        <FormControlLabel
                            value="end"
                            onChange={this.handleCheckboxChange}
                            control={<Checkbox size={"small"} color="primary"/>}
                            label={<span style={{fontFamily: globalFontFamily, fontSize: 16}}>I agree to <a
                                href="/terms-and-condition" target="_blank"> <u>Terms & Conditions</u></a></span>}
                            labelPlacement="end"

                        />
                    </div>

                </div>
            )
            ;
        }

        return (
            <div>
                {tempContent}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeStepFromRedux: state.account.activeStep,
        changeStepFromRedux: state.account.changeStep,
        changeToStepFromRedux: state.account.changeToStep,
        accountFromRedux: state.account.account,
        disableFormButtonFromRedux: state.account.disableFormButton

    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeStepMethod: () => {
            dispatch({type: 'changeStep', value: false})
        },
        changeStepToMethod: (value) => {
            dispatch({type: 'changeToStep', value: value})
        },
        changeActiveStepToMethod: (value) => {
            dispatch({type: 'changeActiveStep', value: value})
        },
        changeFormValue: (formField, value) => {
            dispatch({type: 'changeAccount', formField: formField, value: value})
        },
        changeFormError: (value) => {
            dispatch({type: 'formError', value: value})
        },
        changeFormButtonState: (value) => {
            dispatch({type: 'disableFormButton', value: value})
        }

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProfileForm));