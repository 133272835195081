import HouseOfHealth from '../../Assets/Houseofhealthlogo.png'
import ProgressiveLabs from '../../Assets/ProgressiveLogo.png'
import CRC from '../../Assets/CentralReadersClubLogo.png'

export const OurPartnersContent = [

    {
        imageURL: ProgressiveLabs,
        itemWidth: 220,
        link: 'http://progressivelabs.tech/',
        text: 'It was a delight working with amazing bunch of passionate doctors in this project. We hope to provide cutting edge technology in this pursuit to pioneer wholistic digital service in this domain. Thanks to House of Health for their effort.'
    },
    {
        imageURL: HouseOfHealth,
        itemWidth: 110,
        link: 'http://houseofhealthnepal.com/',
        text: 'Medical entrance education needs to be simplified. We doctors are already overworked and support in career growth is a big value addition. This project is not only a diversification in our portfolio but a beginning of something spectacular.'
    },
    {
        imageURL: CRC,
        itemWidth: 'auto',
        link: 'https://m.facebook.com/centralreadingroom/community/?ref=page_internal&mt_nav=0',
        text: 'We are super delighted to continue our support to PG aspirant with this application. We hope to soon contribute even further because we believe every doctor deserves to reduce the difficulties they face in PG preparation.'
    },


];