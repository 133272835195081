const initialState = {
    activeQuestion: null,
    testType: ''
};

const TestResultReducer = (state = initialState, action) => {

    switch (action.type) {


        case 'setResult':

            return {
                ...state,
                ...action.value
            };

        case 'activeQuestionInReview':

            return {
                ...state,
                activeQuestion : action.value
            };

        case 'setResultTestType':

            return {
                ...state,
                testType: action.value
            }

        case 'clearActiveQuestionInReview':

            return {
                ...initialState
            };

        default:
            return state;

    }

};

export default TestResultReducer;
