const initialState = {
    open: false,
};

const FeedbackDialogReducer = (state = initialState, action) => {

    switch (action.type) {


        case 'changeFeedbackDialogState':

            return {
                open: action.value
            };

        case 'clearFeedbackDialog':

            return {
                ...initialState
            };


        default:

            return state

    }

};


export default FeedbackDialogReducer;
