import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";

const style = theme => ({
   root: {
       paddingTop: 40,
       paddingBottom: 40,
       textAlign: 'center'
   }
});

class FooterNote extends Component {
    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                Copyright @ 2021 All rights reserved | Progressive Labs Pvt. Ltd
            </div>
        );
    }
}

export default withStyles(style)(FooterNote);
