import React, {useEffect} from 'react';
import {withRouter} from "react-router-dom";

const EsewaNativePage = (props) => {

    useEffect(() => {


        function searchToObject(url) {
            let pairs = url.substring(1).split("&"),
                obj = {},
                pair,
                i;

            for ( i in pairs ) {
                if ( pairs[i] === "" ) continue;

                pair = pairs[i].split("=");
                obj[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
            }

            obj['userId'] = props.match.params.id;

            return obj;
        }


        window.ReactNativeWebView.postMessage(JSON.stringify(searchToObject(window.location.search)));


    }, []);

    return (
        <div>

        </div>
    );
};

export default withRouter(EsewaNativePage);
