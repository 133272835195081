const initialState = {
    activeStep: 0,
    changeStep: false,
    changeToStep: null,
    account: {
        packageType: 'FREE',
        password: '',
        userName: '',
        confirmPassword: '',
        fullName: '',
        address: '',
        contactNumber: '',
        profilePictureURL: '',
        profilePictureName: '',
        imageName: '',
        nmcNumber: '',
        paymentMethod: 'ESEWA',
        termsAndConditions: false,
    },
    paymentInfo: {},
    upgradeInfo: {},
    paymentResponse: {},
    formError: '',
    disableFormButton: true,
    // accountCreationErrorMessage: 'Thirdparty payment has been indefinitely locked. Please click <a href=\'https://khalti.com/#/account/transaction_pin\' target=\'_blank\'>here</a> to reset pin.Thirdparty payment has been indefinitely locked. Please click <a href=\'https://khalti.com/#/account/transaction_pin\' target=\'_blank\'>here</a> to reset pin.'
    accountCreationErrorMessage: '',
    upgradeCoupon: '',
    specificUpgrade: '',
    descriptionDialog: false,
    partialCouponTransactionid: null
};

const AccountReducer = (state = initialState, action) => {


    switch (action.type) {

        case 'changeStep':
            return {
                ...state,
                changeStep: action.value
            };

        case 'changeToStep':
            return {
                ...state,
                changeToStep: action.value
            };

        case 'changeActiveStep':
            return {
                ...state,
                activeStep: action.value
            };

        case 'changeAccount':
            return {
                ...state,
                account: {
                    ...state.account,
                    [action.formField]: action.value
                }
            };

        case 'formError':

            return {
                ...state,
                formError: action.value,
            };

        case 'disableFormButton':

            return {
                ...state,
                disableFormButton: action.value
            };

        case 'resetForm':
            return {
                ...state,
                account: initialState.account
            };

        case 'setPaymentResponse':
            return {
                ...state,
                paymentResponse: action.value
            };

        case 'setPaymentInfo':
            return {
                ...state,
                paymentInfo: {
                    ...action.value
                }
            };

        case 'setUpgradeInfo':
            return {
                ...state,
                upgradeInfo: {
                    ...action.value
                }
            };

        case 'setAccountCreationError':
            return {
                ...state,
                accountCreationErrorMessage: action.value
            };

        case 'handleCouponChange':
            return {
                ...state,
                upgradeCoupon: action.value
            };

        case 'openCouponDescriptionDialog':
            return {
                ...state,
                descriptionDialog: action.value
            };

        case 'setPartialCouponTransactionid':
            return {
                ...state,
                partialCouponTransactionid: action.value
            };

        case 'setSpecificUpgrade':
            return {
                ...state,
                specificUpgrade: action.value
            };


        case 'resetOnUnmount':

            return {
                ...initialState
            };


        default:
            return state;
    }
};

export default AccountReducer;
