const initialState = {

    // notifications: [
    //     {
    //         "message": "Your account has expired. So you plan is downgraded to free plan.",
    //         "redirect_link": "http://localhost:4001/user/dashboard/home",
    //         "slug": false,
    //         "read": false,
    //         "user_id": 1
    //     },
    //     {
    //         "message": "Your account has expired. So you plan is downgraded to free plan.",
    //         "redirect_link": "http://localhost:4001/user/dashboard/home",
    //         "slug": false,
    //         "read": false,
    //         "user_id": 1
    //     },
    //     {
    //         "message": "Your account has expired. So you plan is downgraded to free plan.",
    //         "redirect_link": "http://localhost:4001/user/dashboard/home",
    //         "slug": false,
    //         "read": false,
    //         "user_id": 1
    //     },
    //     {
    //         "message": "Your account has expired. So you plan is downgraded to free plan.",
    //         "redirect_link": "http://localhost:4001/user/dashboard/home",
    //         "slug": false,
    //         "read": false,
    //         "user_id": 1
    //     },
    //     {
    //         "message": "Your account has expired. So you plan is downgraded to free plan.",
    //         "redirect_link": "http://localhost:4001/user/dashboard/home",
    //         "slug": false,
    //         "read": false,
    //         "user_id": 1
    //     }
    // ],
    notifications: [],
    newNotification: {},
    noticeBadge: 0,
    showNotificationAlert: false,
    openNotification: false,
    openedNotification: {},
    hasMore: true
};

const NotificationReducer = (state = initialState, action) => {

    switch (action.type) {


        case 'setNewNotification':

            let unread = action.value.filter(item => !item.read_status).length

            return {
                ...state,
                notifications: action.value,
                noticeBadge: unread
            };

        case 'setNotifications':

            let tempNotifications;

            if (state.notifications.length > 0) {
                tempNotifications = [...state.notifications, ...action.value]
            } else {
                tempNotifications = action.value
            }


            // let newUnread = state.noticeBadge + action.value.filter(item => item.message.includes('not_read')).length
            // let unread = state.noticeBadge + 10;


            return {
                ...state,
                notifications: tempNotifications,
                noticeBadge: 0
            };


        case 'setNotificationBadge':
            return {
                ...state,
                noticeBadge: action.value
            }


        case 'newNotification':

            let tempArray = state.notifications;

            tempArray.reverse();
            tempArray.push({...action.value});
            tempArray.reverse();


            return {
                ...state,
                newNotification: action.value,
                noticeBadge: state.noticeBadge + 1,
                notifications: tempArray,
                showNotificationAlert: true
            };

        case 'changeNotificationAlert':

            return {
                ...state,
                showNotificationAlert: action.value
            };

        case 'openClickedNotification':

            return {
                ...state,
                openNotification: true,
                openedNotification: action.value
            }

        case 'closeClickedNotification' :

            return {
                ...state,
                openedNotification: {},
                openNotification: false
            }

        case 'changeNotificationReadState':

            state.notifications.map(item => {
                if (item.id === action.value) {
                    item.message = item.message.replace('not_read', 'read');
                }

                return item
            })


            return {
                ...state,
                // noticeBadge: state.noticeBadge - 1
            }

        case 'setNotificationHasMore':

            return {
                ...state,
                hasMore: action.value
            };

        case 'clearNotificationBadge':

            return {
                ...state,
                noticeBadge: 0
            }

        default:

            return state

    }

};


export default NotificationReducer;
