import React, {Component} from 'react';
import Button from "@material-ui/core/Button";
import {colorCodes, globalFontFamily} from "../../../../Utils/Constants/Constants";
import {withRouter} from "react-router-dom";
import {createMuiTheme, withStyles} from "@material-ui/core";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#0693e3',
        },
    },
    overrides: {
        MuiButton: {
            root: {
                fontFamily: globalFontFamily,
            }
        }
    }
});

const styles = theme => ({
    active: {
        color: 'white',
        backgroundColor: 'white'
    },
    button: {
        backgroundColor: colorCodes.topBarButton
    }
});

class CircularBorderButton extends Component {


    handleClick = () => {

        if(window.location.pathname === this.props.to){
            this.props.history.push('/');
            this.props.history.push(this.props.to)
        }

    };

    render() {

        const {classes} = this.props;


        return (
            <div>
                <ThemeProvider theme={theme}>
                    <Button
                        onClick={this.props.buttonClick}
                        color={"primary"}
                        variant={"contained"}
                        component={this.props.component}
                        to={this.props.to}

                        style={{
                            borderRadius: 5,
                            textTransform: "none",
                            minWidth: this.props.buttonWidth,
                            fontFamily: globalFontFamily,
                            color: 'white',
                            paddingLeft: 10,
                            paddingRight: 10,
                        }}

                        activeStyle={{
                            color: 'white'
                        }}

                    >
                        <strong>
                            {this.props.text}
                        </strong>
                    </Button>
                </ThemeProvider>
            </div>
        );
    }
}

export default withStyles(styles)(withRouter(CircularBorderButton));
