import React from 'react';
import Grid from "@material-ui/core/Grid";
import {colorCodes} from "../../../Utils/Constants/Constants";

const NoDataLabel = (props) => {
    return (

        <Grid container
              style={{
                  padding: 10,
                  minHeight: 60,
                  background: colorCodes.backgroundLightRed,
                  borderRadius: props.withRadius ? 5 : 0
              }}
              justify={"center"} alignItems={"center"}>

            <Grid item style={{textAlign: 'center'}}>
                {props.children}
            </Grid>

        </Grid>

    );
};

export default NoDataLabel;