const initialState = {
    interruptingAd: null,
    subjectBasedAd: null,
    pastQuestionBasedAd: null
};

const AdvertisementReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'addInterruptingAd':

            return {
                ...state,
                interruptingAd: action.payload
            };


        case 'removeInterruptingAd':

            return {
                ...state,
                interruptingAd: null
            };

        case 'addSubjectBasedAd':

            return {
                ...state,
                subjectBasedAd: action.payload
            };

        case 'removeSubjectBasedAd':

            return {
                ...state,
                subjectBasedAd: null
            };

        case 'addPastQuestionBasedAd':

            return {
                ...state,
                pastQuestionBasedAd: action.payload
            };

        case 'removePastQuestionBasedAd':

            return {
                ...state,
                pastQuestionBasedAd: null
            };

        default:

            return state

    }

};


export default AdvertisementReducer;