import SampannaImage from '../../Assets/SampannaBlogImagePreview.jpg';
import SampannaImageBlog from '../../Assets/SampannaImageBlog.jpg';
import RomiDahal from '../../Assets/RomiDahal.jpg'
import RomiDahalPreview from '../../Assets/RomiDahalPreview.jpg'
import DrSujan from '../../Assets/drSujan.jpg';
import DrSujanPreview from '../../Assets/drSujanPreview.jpg';
import DrSurendraPreview from '../../Assets/DrSurendraPreview.png';
import DrSurendra from '../../Assets/DrSurendra.png';

import SubashPaudel from '../../Assets/SubashPaudel.png';
import SubashPaudelPreview from '../../Assets/SubashPaudelPreview.png';

export const blogContent = {
    1: {
        "title": "Topper Week 1 CEE Mega Test series.",
        "author": "PrePG Nepal",
        "imageURLPreview": SubashPaudel,
        "imageURLBlog": SubashPaudel,
        "date": '2021/12/24',
        "content": [
            '',
            "PrePG Nepal congratulates Dr. Subhash Paudel for getting ranked 1 in first week of CEE Mega Test series created by IOM Toppers.",
            ""
        ]
    },
    2: {
        "title": "Topper’s Advice: How to spend your last 2 weeks before CEE Entrance?",
        "author": "Dr. Surendra Rayamajhi",
        "imageURLPreview": DrSurendraPreview,
        "imageURLBlog": DrSurendra,
        "date": '2020/09/28',
        "content": [
            'I am going to share how I spent the last 15 days before my PG Entrance exam with the intention of helping you guys with your preparation. These techniques helped me crack the exam and helped me achieve 1st rank in IOM PG entrance.',
            'To begin this is a time for revision. Hopefully by now, you have already covered most of the important topics and practiced important past questions and high yield questions. If you have not, bad luck. But do not start a new book or a complex new topic at this moment. You will not be able to conquer the new thing while also not being able to revise the things you got covered. ',
            'Now how exactly to revise? You may be one of those person who can skim read really fast or you may love to take it easy and slow down the pace. Personally for me, fast skim read were more helpful. I was able to do multiple revision and understood plus remembered more by doing it many times. ',
            'Always remember, you must try to increase your accuracy with the easy or predictable questions rather than try harder with the difficult ones. It is important you do not spend your time obsessed with the difficult questions with the Fear of Missing Out at the last moments of your preparation journey. This has worked for me as well as many of my friends and I am pretty sure this approach will help you too.',
            'Also, practice the high yield questions multiple times. The best bet is to repeatedly give the All India past examination questions. This will definitely improve your score as the Nepalese PG entrance seems to consider all India past questions a strong source of reference. Also, look into the past questions of Nepalese boards. PrePG Nepal also has a lot of past question based high yield test. You can give it a try.',
            'Do not forget to look into statistical or fact based questions including national demographics at the last moment of the test. Of course you would want to memorize them far before but a final looks a day before the exam is a must.'
        ]
    },
    3: {
        "title": "Why PG Nepal?",
        "author": "Dr. Sujan Regmee",
        "imageURLPreview": DrSujanPreview,
        "imageURLBlog": DrSujan,
        "date": '2020/06/20',
        "content": [
            'The most difficult part of post graduate medical education in Nepal is the limited number of seats and a very primordial method of selection process to get a placement. The main challenge for the students is the rank that one needs to secure to get enrolled in the specialty of their interest. The performance of a student throughout their undergraduate education does not count when it comes to getting a placement in the PG seats. What decides for the student is the performance they put on the day, at the time of the particular entrance examination.',
            'The system asks for the students who have pursued their MBBS degree through government scholarship to work in remote areas of the country. This definitely has aided in improving the health system of those area, but the students who have to work in such areas under the given condition often fall behind in the race to acquire a placement in the PG seat.',
            'The culture of sitting long hours at the libraries and reading rooms and participating in the practice examination organized in such places has helped students a lot to prepare themselves for the entrance examination, which by far is an one point assessment for the students who prepare the whole year for the exams.',
            'What our platform brings forward is the opportunity for the students who are working in the remote areas to get connected to the pupils all around the country and assess their own standards with the help of online examination. The students can monitor their weekly progress and see where they need to improve themselves to stand a chance to qualify in the entrance examination. The urban doctors can also enroll themselves and connect themselves to their peer against whom they are competing in the examination.',
            'Our online examination platform and well designed analytics can help students analyze their performance and strategize their preparation. They can monitor their performance in respect to their competitors; they can analyze their performance in terms of the subject, performance in the previous exams, and their progress over time.',
            'We will also provide an online platform to discuss and connect with seniors and peers and take conceptual lectures. With the acquisition of the premier plan students get the facility to discuss the exam questions with senior faculties and the ones who have prepared the examination. Along with the online test we plan to organize offline tests on monthly basis which would give students real time “EXAM EXPERIENCE”.',
            'With the motto of together we can, we PG-NEPAL, would like to aid you in your journey to become the specialist that you dream to be. We are determined to make your preparation period, which by far is one of the most difficult times of your lives, lot more scientific and efficient. You can enroll to our platform and prepare for the most important days of your lives without having to leave your job and without wasting much time in constant grinding and dissatisfaction.'
        ]
    },
    4: {
        "title": "Tips to the Aspiring PG students",
        "author": "Dr. Romi Dahal",
        "date": '2020/06/20',
        "imageURLPreview": RomiDahalPreview,
        "imageURLBlog": RomiDahal,
        "content": [
            'Ahoy to everyone reading this! I am Dr. Romi Dahal. I am a surgeon, currently pursuing my MCH at IOM, Teaching Hospital. Personally, I have spent a lot of time to crack medical entrance examinations to get to this point. And also have helped many many candidates throughout the past decade to crack the medical entrance examination in the capacity of a mentor, instructor, and in many cases - a good friend. Let me share some of my thoughts regarding this domain.',
            "First and foremost, there is no substitution for HARD WORK! That's right - you must work diligently and consistently if you want to succeed in PG entrance and I guess everyone knows it. Second, hard work is only the necessary condition, it is not a sufficient one. Knowing how to work SMART can relieve a lot of pains surrounding the PG preparation.",
            'Talking about pain, The problem with PG preparation is that it is a real time consuming process - you need a real dedicated time. Time is a constraint to many, and to highly skilled and earning individuals like doctors, it also presents a huge opportunity cost. To decide to devote time means to let go of the income that can be made working in that given time. Even with a dedicated time and tremendous effort, it seems that what you have studied may fade away if there is gap in between. So, you need to be able to work smartly in a way that allows you to try and start where you left when you resume your preparation after a gap.',
            'Also, it is necessary to consolidate your preparation efforts and your notes/knowledge/insights and any understandings in one place (or as fewer places as possible) and take the right direction for cracking PG preparation. In this digital age with the vast number of internet-based opportunities and glorious preparation help schemes, students need to be able to leverage these new opportunities but with caution. Please take some time to decide which one is the best for you, which one provides the features that will add significant value to your preparation, which one will help you build further on your knowledge and insights and not just give an exam set!',
            "As me and my team faced these challenges, we looked around to find help but got only few. With determination and constant effort, we are able to bring this platform that we believe has the potential to address most of the challenges that PG aspirants face during their preparation. This platform is a dynamic platform which we are determined to grow up to the point that it can help all the PG aspirant reduce their efforts and time for PG preparation.",
            "Hope you can join this platform and help us grow together with you. Feel free to reach out to me anytime with any feedback through email: romidahal@prepgnepal.com.",
            "Cheers,",
            "Dr. Romi Dahal"

        ]
    },
    5: {
        "title": 'The moment we passed.',
        "author": 'Dr. Sampanna Pandey',
        "date": '2020/06/20',
        "authorAffiliation": "MS General Surgery at Kathmandu medical college and teaching hospital",
        "imageURLPreview": SampannaImage,
        "imageURLBlog": SampannaImageBlog,
        "content": [
            'SLC was considered the IRON GATE when we were young. The gate got stronger by the day as we moved ahead in our academic career. The moment we passed the final exams and got that prized prefix (Dr.) added to our names, we felt that everything is falling into place now - a sense of pride and achievement. But after the aura of internship is over, only then most of us realize that MBBS is just a beginning in our pursuit of success and glory. The harsh reality hits you when you have to balance time between work and studies. At one hand you wish to work day and night for the betterment of the patients you are attending to and the pursuit of satisfaction that you get with patient care while at the other hand you have to spend significant time with your books and study materials to improve your chances of getting into MD/MS. The pressure of our peer and the constant sense of competition always keep us worried. And what is even worse is not knowing where do you stand in comparison to other students competing for the seats and how much time you actually need to dedicate. A platform like PGNepal will always help us maintain balance between work and studies.',
            'It will keep us updated and better prepared by utilizing the extra time we have on our busy career. PG preparation does not have to be a full time pursuit. I suggest all the PG aspirants to give it a try. It does not really matter if you are in your internship or have finished it already. If you are preparing for your PG entrance take out your laptops or smart phones and give it a try. It won’t take you long to notice that this will definitely help you in your endeavor.',
        ],
    }

};
