import React from 'react';
import * as Sentry from '@sentry/react';

const ErrorWrapper = (props) => {

    return (
        <div>
            <Sentry.ErrorBoundary fallback={props.fallbackComponent}>
                {props.children}
            </Sentry.ErrorBoundary>
        </div>
    );

};

export default ErrorWrapper;