import React, {Component} from 'react';
import SectionHeader from "../../SectionHeader/SectionHeader";
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import {FaYoutube} from 'react-icons/fa';

const style = theme => ({
    header: {
        paddingTop: 30,
        paddingBottom: 10
    },
    iconButton: {
        cursor: 'pointer',
        transition: '0.3s',
        "&:hover": {
            color: '#e5e5e5',
            transition: '0.3s',
        },
    },

    phoneCall: {
        color: 'white',
        transition: '0.3s',
        "&:hover": {
            color: '#e5e5e5',
            transition: '0.3s',
        },
    }
});

class FollowUs extends Component {


    handleClick = (icon) => () => {

        let link;

        switch (icon) {
            case 'linkedIn':
                link = 'https://www.linkedin.com/in/prepg-nepal-8179211a4/';
                break;

            case 'instagram':
                link = 'https://www.instagram.com/prepg_nepal/';
                break;

            case 'facebook':
                link = 'https://www.facebook.com/Prepg-Nepal-107535104201053/?ref=br_rs';
                break;
        }

        window.open(link, '_blank');
    };

    render() {
        const {classes} = this.props;
        return (

            <div>

                <div className={classes.header}>
                    <SectionHeader text={"FOLLOW US"} whiteUnderline={true}/>
                </div>

                <div style={{fontSize: 14}}>
                    Let us be social
                </div>

                <div>
                    <Grid container justify={"space-evenly"}
                          style={{paddingLeft: 60, paddingRight: 60, paddingTop: 10}}>

                        <Grid item>
                            <a href="https://www.instagram.com/prepg_nepal/" style={{color: 'white'}}>
                                <InstagramIcon className={classes.iconButton}/>
                            </a>
                        </Grid>
                        <Grid item>
                            <a href="https://www.facebook.com/Prepg-Nepal-107535104201053/?ref=br_rs"
                               style={{color: 'white'}}>
                                <FacebookIcon className={classes.iconButton}/>
                            </a>
                        </Grid>
                        <Grid item>
                            <a href="https://twitter.com/PrepgNepal" style={{color: 'white'}}>
                                <TwitterIcon className={classes.iconButton}/>
                            </a>
                        </Grid>
                        <Grid item>
                            <a href="https://www.youtube.com/channel/UCtMrfUiXKhvuK57knTBHU4g" style={{color: 'white'}}>
                                <FaYoutube className={classes.iconButton} size={'1.55rem'}/>
                            </a>
                        </Grid>
                        <Grid item>
                            <a href="https://www.linkedin.com/company/prepg-nepal/" style={{color: 'white'}}>
                                <LinkedInIcon className={classes.iconButton}/>
                            </a>
                        </Grid>


                    </Grid>

                    <Grid container justify={"center"} alignItems={"center"}>

                        <span>
                        <a href='https://play.google.com/store/apps/details?id=com.progressive.prepgnepalofficial&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
                            alt='Get it on Google Play'
                            src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                            target='_blank'
                            style={{width: 150}}
                        /></a>
                        </span>
                        <span style={{marginTop: 6}}>
                            {/*<span style={{marginBottom: 10}}>*/}
                            <a href="https://apps.apple.com/us/app/prepg-nepal/id1539370515?itsct=apps_box&amp;itscg=30200"
                               target='_blank' style={{borderRadius: 0, width: 120, height: 50, paddingBottom: 4}}>
                                <img
                                    src='https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?size=250x83&amp;releaseDate=1604880000&h=38b90b34d52633a46b01c0c323fcef74'
                                    alt="Download on the App Store"
                                    style={{borderRadius: 0, width: 120, height: 50, paddingBottom: 4}}
                                />
                            </a>
                        </span>
                    </Grid>
                    <div className={classes.phoneCall}>
                        <Grid container justify={"center"} alignItems={"center"}
                              style={{paddingLeft: 60, paddingRight: 60, marginTop: -5}}>
                            <Grid item xs={3}>
                                <div style={{marginTop: 1, cursor: 'pointer'}}>
                                    <a href="tel:9823009334">
                                        <LocalPhoneIcon fontSize={"large"} style={{color: "white", fontSize: 45}}/>
                                    </a>
                                </div>
                            </Grid>
                            <Grid item container xs={7} spacing={1}>
                                <Grid item style={{color: 'white', marginTop: -5}} xs={12}>
                                    <a href="tel:9823009334">
                                    <span
                                        style={{marginBottom: 10, wordBreak: 'none', color: "white"}}>
                                        9823009334
                                    </span>
                                    </a>
                                </Grid>

                                <Grid item style={{color: 'white', marginTop: -5}} xs={12}>
                                    <a href="tel:9801012580">
                                    <span style={{marginBottom: 10, wordBreak: 'none', color: 'white'}}>
                                        9801012580
                                    </span>
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(style)(FollowUs);
