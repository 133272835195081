const initalState = {

    serverCalled: false,
    currentlyShowingTest: "PAST_TEST_QUESTION",
    pastTestPageShown: 0,
    categoryTestPageShown: 0,
    nmcleTestPageShown: 0,
    displayPageSize: 1,
    onlyPastTest: [],
    onlyCategoryTest: [],
    serverResponse: {}

};

const RandomTestReducer = (state = initalState, action) => {


    switch (action.type) {

        case 'addRandomResponse':

            return {
                ...state,
                serverCalled: true,
                serverResponse: action.value,
                onlyPastTest: action.onlyPastTest,
                onlyCategoryTest: action.onlyCategoryTest,
                onlyNMCLE: action.onlyNMCLE

            };


        case "setCurrentRandomList":
            return {
                ...state,
                currentlyShowingTest: action.value
            }

        case "setPastTestPageShown":

            return {
                ...state,
                pastTestPageShown: action.value
            }

        case "setCategoryTestPageShown":

            return {
                ...state,
                categoryTestPageShown: action.value
            }

        case "setNMCLETestPageShown":

            return {
                ...state,
                nmcleTestPageShown: action.value
            }


        case "clearRandomTestReducer":

            return {
                ...initalState
            }


        default:
            return state;
    }
};

export default RandomTestReducer;
