const initialState = {
    open: false,
    dialogContext: ''
};

const TestSessionDialogReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'openDialog':

            return {
                ...action.value
            };

        case 'closeDialog':

            return {
                ...state,
                open: false
            };

        default:
            return state;

    }

};

export default TestSessionDialogReducer;