import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import CircularBorderButton from "../../../../Components/HomePage/Home/CircularBorderButton/CircularBorderButton";
import {withStyles} from "@material-ui/core";
import {Link, NavLink, withRouter} from "react-router-dom";
import logo from '../../../../Assets/PrePgLogo.png';
import withWidth from "@material-ui/core/withWidth";
import {connect} from "react-redux";

const style = theme => ({
    topBar: {
        paddingTop: 15,
        marginLeft: '5%',
        marginRight: '5%',
        paddingLeft: '1%',
        paddingRight: '1%',
        [theme.breakpoints.down('xs')]: {
            paddingBottom: 5
        },

    }
});

class TopBar extends Component {

    changeHandler = (name) => () => {
        switch (name) {
            case 'blog':
                break;
            case 'register':
                this.props.resetOnUnmount();
                break;
            default:
                break;


        }
    };


    render() {

        const {classes} = this.props;

        return (
            <div>
                <div className={classes.topBar} style={{margin: this.props.width === 'xs' ? 0: '0 5% 0 5%'}}>
                    <Grid container sm alignItems={"center"}
                          justify={this.props.width === 'xs' ? 'center' : 'space-between'}>
                        <Grid item sm={4} style={{padding: '10px 0 10px 0'}}>
                            <Link to={'/'}>
                                <img src={logo} alt="logo" height={50} width={142}
                                     style={{cursor: 'pointer'}}/>
                            </Link>
                        </Grid>
                        {/*<Grid item >*/}
                        <Grid item container sm  alignItems={"center"} spacing={2}
                              justify={this.props.width === 'xs' ? 'center' : "flex-end"}>
                            <Grid item>
                                <CircularBorderButton text={'Login'} component={NavLink} to={`/`}
                                                      buttonClick={this.changeHandler('login')} buttonWidth={95}/>
                            </Grid>
                            <Grid item>
                                <CircularBorderButton text={'Register'} component={NavLink} to={`/user/sign-up`}
                                                      buttonClick={this.changeHandler('register')} buttonWidth={95}/>
                            </Grid>


                            {/*<Grid item>*/}
                            {/*    <CircularBorderButton text={'Blog'} component={NavLink} to={`/user/blog/1`}*/}
                            {/*                          buttonClick={this.changeHandler('blog')}*/}
                            {/*                          buttonWidth={95}/>*/}
                            {/*</Grid>*/}

                        </Grid>
                        {/*</Grid>*/}
                    </Grid>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {

    return {

        resetOnUnmount: () => {
            dispatch({type: 'resetOnUnmount'});
        }

    };

};

export default connect(null, mapDispatchToProps)(withRouter(withStyles(style)(withWidth()(TopBar))));
