import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {postRequest} from "../../../../../Utils/HttpRequests/HttpRequests";
import RecaptchaComponent from "./RecaptchaComponent";
import KhaltiCheckout from "khalti-web";

class ReCaptchaForm extends Component {

    state = {

        response: '',
        submitting: false,
        submitted: false,
        errorMessage: '',
    };

    componentDidMount(): void {
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        // if(this.state !== prevState){
        //     if(this.state.response !== ''){
        //         this.props.changeFormButtonState(false);
        //     }
        // }

        if (this.props !== prevProps) {

            if(this.props.handleServerCall !== prevProps.handleServerCall){

                if (this.props.handleServerCall === true) {
                    this.handleSubmit();
                }

            }


        }
    }

    componentWillUnmount() {
        this.props.changeFormError('');
    }

    onloadCallback = () => {
        this.setState({submitting: false});
    };

    handleSubmit = () => {

        this.props.handleServerCallChange();
        // this.props.changeStepMethod();
        this.props.changeFormError('');
        // this.setState({submitting: true});
        this.props.handleSubmittingChange(true);

        let errorHandler = (message) => {
            this.props.handleSubmittingChange(false);
            this.setState({submitting: false, response: ''});
            this.props.changeFormButtonState(false);
            this.props.changeFormError(message);

            // this.props.setAccountCreationError(message);
            // this.props.changeActiveStepToMethod(5);
        };

        if (this.state.response === '') {

            this.setState({submitting: false});
            this.props.changeFormError('Captcha not verified!');

        } else {

            this.props.changeFormButtonState(true);
            if (this.props.accountFromRedux.packageType === "FREE") {
                let data = {
                    "username": this.props.accountFromRedux.userName.toLowerCase().trim(),
                    "fullName": this.props.accountFromRedux.fullName.trim(),
                    "password": this.props.accountFromRedux.password.trim(),
                    "passwordConfirmation": this.props.accountFromRedux.confirmPassword.trim(),
                    "planName": this.props.accountFromRedux.packageType.trim(),
                    "address": "",
                    "contactNumber": this.props.accountFromRedux.contactNumber.trim(),
                    "nmcNumber": this.props.accountFromRedux.nmcNumber.trim(),
                    "district": "",
                    "profile_pic_name": "",
                    "profilePictureURl": ""
                };
                postRequest(this.props, 'registerUser', data, '?g-recaptcha-response=' + this.state.response).then(response => {


                    if (response.status === "success") {
                        if (response.body.data.t.message === 'success') {
                            this.props.changeFormButtonState(false);
                            this.props.changeActiveStepToMethod(3);

                        } else {
                            this.props.setAccountCreationError(response.body.data.t.t);
                            this.props.changeActiveStepToMethod(4);
                        }

                    } else {
                        if (response.body.response.status === 500) {

                            if (response.body.response.data.message.includes('constraint [UK_lqjrcobrh9jc8wpcar64q1bfh]')) {
                                this.props.setAccountCreationError('This email address is already being used.');
                                this.props.changeActiveStepToMethod(4);
                            } else {
                                errorHandler('Server error.');
                            }

                        } else {
                            errorHandler(response.body.response.data);
                        }

                    }
                })
            } else {
                let packageName = this.props.accountFromRedux.paymentMethod;

                let stepperNavigation = (step) => {
                    this.props.changeFormButtonState(false);
                    this.props.changeActiveStepToMethod(step);
                };


                // if (!this.isEmpty(this.props.paymentInfo)) {
                //     this.khaltiPayment(this.props.paymentInfo, packageName, stepperNavigation, errorHandler);
                // } else {

                // let path="https://esewa.com.np/epay/main";
                // let params= {
                //     amt: 1,
                //     psc: 0,
                //     pdc: 0,
                //     txAmt: 0,
                //     tAmt: 1,
                //     pid: "PREMIUM5",
                //     scd: "NP-ES-ANISHTEST",
                //     su: "http://localhost:3000/user/sign-up/" + 'USER_ID',  //Add user id after /sign-up i.e. /sign-up/80
                //     fu: "http://localhost:3000/user/sign-up"
                // };
                //
                // let form = document.createElement("form");
                // form.setAttribute("method", "POST");
                // form.setAttribute("action", path);
                // // form.setAttribute("target", "_blank");
                //
                // for(let key in params) {
                //     let hiddenField = document.createElement("input");
                //     hiddenField.setAttribute("type", "hidden");
                //     hiddenField.setAttribute("name", key);
                //     hiddenField.setAttribute("value", params[key]);
                //     form.appendChild(hiddenField);
                // }
                //
                // document.body.appendChild(form);
                // form.submit();

                let data = {
                    "username": this.props.accountFromRedux.userName.toLowerCase().trim(),
                    "fullName": this.props.accountFromRedux.fullName.trim(),
                    "password": this.props.accountFromRedux.password.trim(),
                    "passwordConfirmation": this.props.accountFromRedux.confirmPassword.trim(),
                    "planName": this.props.accountFromRedux.packageType.trim(),
                    // "planName": 'LITE',
                    "serviceName": packageName.trim(),
                    // "paymentName": 'KHALTI',
                    "address": "",
                    "contactNumber": this.props.accountFromRedux.contactNumber.trim(),
                    "district": "",
                    "profile_pic_name": "",
                    "profilePictureURl": ""
                };


                postRequest(this.props, 'registerUser', data, '?g-recaptcha-response=' + this.state.response, 'public').then(response => {


                    if (response.status === "success") {


                        if (response.body.data.t.message === 'success') {

                            this.props.setPaymentInfo(response.body.data.t.t);

                            if (packageName === 'KHALTI') {

                                this.khaltiPayment(response.body.data.t.t, packageName, stepperNavigation, errorHandler);


                            } else if (packageName === 'ESEWA') {


                                const generateRandomString = function (length = 20) {
                                    return Math.random().toString(20).substr(2, length)
                                };


                                let path = "https://esewa.com.np/epay/main";
                                let params = {
                                    amt: response.body.data.t.t.ammount,
                                    psc: 0,
                                    pdc: 0,
                                    txAmt: 0,
                                    tAmt: response.body.data.t.t.ammount,
                                    pid: response.body.data.t.t.productName + '-' + response.body.data.t.t.productIdentity,
                                    scd: response.body.data.t.t.publicKey,
                                    su: `${process.env['REACT_APP_domainURL']}/user/sign-up/` + response.body.data.t.t.userId,  //Add user id after /sign-up i.e. /sign-up/80
                                    fu: `${process.env['REACT_APP_domainURL']}/user/sign-up`
                                };

                                let form = document.createElement("form");
                                form.setAttribute("method", "POST");
                                form.setAttribute("action", path);
                                // form.setAttribute("target", "_blank");

                                for (let key in params) {
                                    let hiddenField = document.createElement("input");
                                    hiddenField.setAttribute("type", "hidden");
                                    hiddenField.setAttribute("name", key);
                                    hiddenField.setAttribute("value", params[key]);
                                    form.appendChild(hiddenField);
                                }

                                document.body.appendChild(form);
                                form.submit();


                            }

                        } else {
                            this.props.setAccountCreationError(response.body.data.t.t);
                            this.props.changeActiveStepToMethod(4);
                        }


                    } else {
                        if (response.body.response.status === 500) {
                            errorHandler('Server Error!');
                        } else {
                            errorHandler('User cannot be registered!');
                        }

                    }
                });
                // }
            }

        }


    };

    khaltiPayment = (khaltiInfo, packageName, stepperNavigation, errorHandler) => {

        let handleClose = () => {
            this.props.handleSubmittingChange(false);
            this.setState({submitting: false, response: ''});
            this.props.disableFormButton(true);
            this.handleTokenChange('');
        };

        const handlePaymentTermination = (message) => {
            this.props.setAccountCreationError(message);
            this.props.changeActiveStepToMethod(4);
        };

        let config = {

            "publicKey": khaltiInfo.publicKey,
            "productIdentity": khaltiInfo.productIdentity,
            "productName": khaltiInfo.productName,
            "productUrl": `https://prepgnepal.com/${khaltiInfo.productName}`,
            "paymentPreference": ["KHALTI"],
            "eventHandler": {
                onSuccess(payload) {
                    // hit merchant api for initiating verfication
                    let data = {
                        "idx": payload.idx,
                        "token": payload.token,
                        "amount": payload.amount,
                        "mobile": payload.mobile,
                        "serviceName": packageName,
                        "userId": khaltiInfo.userId,
                        "product_identity": payload.product_identity,
                        "product_name": khaltiInfo.productName,
                        "product_url": payload.product_url,
                        "widget_id": payload.widget_id
                    };

                    postRequest(null, 'paymentVerification', data, null, 'public').then(response => {

                        if (response.status === "success") {

                            if (response.body.data.message === 'success') {
                                stepperNavigation(3);
                            } else {
                                errorHandler(response.body.data.t);
                            }

                        } else {
                            handlePaymentTermination('Payment could not be verified. Please contact PrePg Nepal for further process.');
                            // errorHandler('Error occurred while verifying payment. Please contact PrePg Nepal for further process.');
                        }
                    });


                },

                onError(error) {
                    handleClose();
                    errorHandler('Payment was terminated because there was an issue with Khalti Wallet.');
                },
                onClose() {
                    handleClose();
                }
            }
        };

        let checkout = new KhaltiCheckout(config);
        checkout.show({amount: khaltiInfo.ammount});
    };

    isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    };

    handleTokenChange = (token) => {
        this.props.setCaptchaResponse(token);
        this.setState({response: token});
    };


    render() {
        return (
            <div>


                {!this.props.submitting && (
                    <div style={{textAlign: 'center', background: 'unset'}}>
                        <div style={{display: 'inline-block', background: 'unset'}}>

                            <RecaptchaComponent handleTokenChange={this.handleTokenChange}/>

                        </div>
                    </div>
                )}
                {/*)}*/}

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeStepFromRedux: state.account.activeStep,
        changeStepFromRedux: state.account.changeStep,
        changeToStepFromRedux: state.account.changeToStep,
        accountFromRedux: state.account.account,
        disableFormButtonFromRedux: state.account.disableFormButton,
        paymentInfo: state.account.paymentInfo,

    };
};

const mapDispatchToProps = dispatch => {
    return {

        changeStepMethod: () => {
            dispatch({type: 'changeStep', value: false})
        },
        changeStepToMethod: (value) => {
            dispatch({type: 'changeToStep', value: value})
        },
        changeActiveStepToMethod: (value) => {
            dispatch({type: 'changeActiveStep', value: value})
        },
        changeFormValue: (formField, value) => {
            dispatch({type: 'changeAccount', formField: formField, value: value})
        },
        changeFormError: (value) => {
            dispatch({type: 'formError', value: value})
        },
        changeFormButtonState: (value) => {
            dispatch({type: 'disableFormButton', value: value})
        },
        setPaymentInfo: (value) => {
            dispatch({type: 'setPaymentInfo', value: value})
        },
        setAccountCreationError: (value) => {
            dispatch({type: 'setAccountCreationError', value: value})
        },
        disableFormButton: (value) => {
            dispatch({type: 'disableFormButton', value: value})
        },

    };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReCaptchaForm));
