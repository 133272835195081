const initialState = {
    currentQuestion: 0,
    changeQuestion: false,
    nextQuestion: 1,
    testId: null,
    time: null,
    completionTime: null,
    questions: [],
    submitting: false,
    initiated: false,
    timed: true,
    isNmcle: false,
    testSessionType: '',
};


const TestSessionReducer = (state = initialState, action) => {

    let tempArray = [];
    let tempQuestion = [];

    switch (action.type) {

        case 'setTime' :

            return {
                ...state,
                time: action.value
            };


        case 'setCompletionTime' :

            return {
                ...state,
                completionTime: action.value
            };

        case 'setTestListSessionType' :

            return {
                ...state,
                testSessionType: action.value
            };

        case 'setTimedTest' :

            return {
                ...state,
                timed: action.value
            };

        case 'setTestId' :

            return {
                ...state,
                testId: action.value
            };

        case 'setQuestions' :

            tempArray = [...action.value];

            for (let i = 0; i < action.value.length; i++) {
                tempArray[i].selectedAnswer = null;
                tempArray[i].submittedAnswer = null
                tempArray[i].imageLoaded = false
            }

            return {
                ...state,
                questions: tempArray
            };

        case "changeImageLoadedState":

            tempQuestion = [...state.questions];

            for (let i = 0; i < tempQuestion.length; i++) {
                if (tempQuestion[i].id === action.questionId) {
                    tempQuestion[i].imageLoaded = true;
                }
            }

            return {
                ...state,
                questions: [...tempQuestion]
            };


        case 'setIsNmcle':

            return {
                ...state,
                isNmcle: action.value
            }


        case "changeSelectedAnswer":

            tempQuestion = [...state.questions];

            for (let i = 0; i < tempQuestion.length; i++) {
                if (tempQuestion[i].id === action.questionId) {
                    if (tempQuestion[i].selectedAnswer === null) {
                        tempQuestion[i].selectedAnswer = action.value;
                    } else {
                        if (tempQuestion[i].selectedAnswer === action.value) {
                            // tempQuestion[i].selectedAnswer = null;
                            tempQuestion[i].selectedAnswer = action.value;
                        } else {
                            tempQuestion[i].selectedAnswer = action.value;
                        }
                    }

                }
            }

            return {
                ...state,
                questions: [...tempQuestion]
            };

        case "changeSubmittedAnswer":

            tempQuestion = [...state.questions];

            for (let i = 0; i < tempQuestion.length; i++) {
                if (tempQuestion[i].id === action.questionId) {
                    tempQuestion[i].submittedAnswer = action.value;
                }
            }

            return {
                ...state,
                initiated: true,
                questions: [...tempQuestion]
            };

        case "changeQuestion":


            return {
                ...state,
                changeQuestion: action.value
            };

        case "currentQuestion":

            tempQuestion = [...state.questions];

            tempQuestion[state.currentQuestion].selectedAnswer = tempQuestion[state.currentQuestion].submittedAnswer;

            return {
                ...state,
                currentQuestion: action.value,
                questions: [...tempQuestion]
            };

        case "nextQuestion":
            return {
                ...state,
                nextQuestion: action.value
            };

        case "setSubmitting":
            return {
                ...state,
                submitting: action.value
            };

        case 'clearExamSession':

            return {
                ...initialState
            };

        default:
            return state
    }
};


export default TestSessionReducer;
