import React from 'react';
import {colorCodes} from "../../../../Utils/Constants/Constants";
import {makeStyles} from "@material-ui/styles";
import {blogContent} from "../../../../Utils/Constants/BlogContent";

const useStyles = makeStyles(theme => ({
    imageContainer: {
        marginLeft: 10,
        float: 'left',
        margin: '10px 20px 10px 0'
    },
    textContainer: {
        marginLeft: 10,
        textAlign: 'justify',
        color: colorCodes.textBlue,
        letterSpacing: 0.3,
        lineHeight: 1.4,
        fontSize: 14,
    }
}));

const ActiveBlogLayout = (props) => {

    const classes = useStyles();
    const blogIndex = props.blogIndex;
    const blog = blogContent[blogIndex];


    return (
        <div>

            <div className={classes.imageContainer}

            >
                <img src={blog.imageURLBlog} alt="img" style={{objectFit: 'cover', objectPosition: `50% top`}}
                     height={380} width={300}/>

            </div>

            <div className={classes.textContainer}>
                <div style={{color: colorCodes.textBlue, fontWeight: 600, textAlign: 'left',
                    padding: '0px 0px 10px 0px',
                    fontSize: 20
                }}
                >
                    {/*<strong> {blog.title}</strong>*/}
                    <h1 style={{padding: 0, margin: 0}}> {blog.title}</h1>

                </div>
                <div style={{
                    color: colorCodes.textBlue,
                    fontWeight: 600,
                    padding: blog.authorAffiliation ? '0px 0px 3px 0px' : '0px 0px 0px 0px',
                    fontSize: 14
                }}>
                    Author: <span><i>{blog.author}</i></span>
                </div>
                {blog.authorAffiliation && (
                    <div style={{color: colorCodes.textBlue, padding: '0px 0px 0px 0px', fontSize: 12}}>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;<span><i>{blog.authorAffiliation}</i></span>
                    </div>
                )}


                <div style={{fontSize: 18, wordSpacing: '0.15rem', color: '#383838', textAlign: 'left'}}>

                    {blog.content.map((item) => {
                        return (
                            <p>
                                {item}
                            </p>
                        )
                    })}
                </div>

            </div>

        </div>
    );
};

export default ActiveBlogLayout;
