import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {colorCodes} from "../../../Utils/Constants/Constants";
import TopBar from "../Home/TopBar/TopBar";
import Footer from "../Home/Footer/Footer";
import FooterNote from "../Home/FooterNote/FooterNote";
import AboutUsLayout from "../../../Components/HomePage/AboutUs/AboutUsLayout";
import Helmet from "react-helmet";


const useStyles = makeStyles(theme => ({
    root: {
        background: colorCodes.backgroundMain,
        minHeight: '100vh',
        width: '100%',
        boxSizing: 'border-box',
    },
    topBarContainer: {
        marginLeft: '5%',
        marginRight: '5%',
        marginBottom: 20
    },
    blogContainer: {
        boxSizing: 'border-box',
        marginLeft: '5%',
        marginRight: '5%',
        boxShadow: `0px 0px 5px 2px ${colorCodes.homeShadow}`
        // '-1px 0px 10px -4px #999999, 2px 2px 5px #999999, 10px 5px 10px -5px #999999, -5px -7px 10px -1px #999999',
        // `-3px 3px 3px 1px ${colorCodes.homeShadow}, 0px -4px 5px 1px ${colorCodes.homeShadow}, 3px -0px 3px 1px ${colorCodes.homeShadow}`,
    }
}));

const AboutUs = (props) => {

    const classes = useStyles();


    return (
        <div className={classes.root}>

            <Helmet>
                <meta charSet="utf-8"/>
                <title>About Us</title>
                <meta name="description"
                      content={'We are a team of experienced doctors trying to innovate in the domain of medical education and healthcare. In the realm of PG preparation, we have a reading room - “Central Readers’ Club”, that has been serving PG aspirants for over 2 years now.'}
                />
            </Helmet>
            <div className={classes.topBarContainer}>
                <TopBar/>
            </div>
            <div className={classes.blogContainer}>
                {/*<BlogsLayout/>*/}
                <AboutUsLayout/>
            </div>
            <div style={{background: colorCodes.backgroundMain}}>
                <Footer/>
            </div>
            <div style={{background: colorCodes.backgroundMain}}>
                <FooterNote/>
            </div>

        </div>
    );
};


export default AboutUs;
