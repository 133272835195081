import React, {useEffect, useState} from 'react';
import Carousel from "react-multi-carousel";

import HeroAreaImage from '../../../../../../Assets/HeroAreaImage.jpg'
import HeroAreaImage2 from '../../../../../../Assets/PrePGHeroArea2.jpg';
import HeroAreaImage3 from "../../../../../../Assets/PrePGHeroArea3.jpg";
import HeroAreaImage4 from "../../../../../../Assets/PrePGHeroArea4.jpg";
import HeroAreaImage5 from "../../../../../../Assets/PrePGHeroArea5.png";
import HeroAreaImageSmall1 from '../../../../../../Assets/HeroAreaImageSmall1.png';
import HeroAreaImageSmall2 from '../../../../../../Assets/HeroAreaImageSmall2.png';
import HeroAreaImageSmall3 from '../../../../../../Assets/HeroAreaImageSmall3.png';
import HeroAreaImageSmall4 from '../../../../../../Assets/HeroAreaImageSmall4.png';
import HeroAreaImageSmall5 from '../../../../../../Assets/HeroAreaImageSmall5.png';
import NewPackagesHomeSmall from '../../../../../../Assets/NewPackagesHomeSmall.jpg';
import NewPackagesHomeBig from '../../../../../../Assets/NewPackagesHomeBig.jpg';


import {makeStyles} from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const responsive = {
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

const useStyles = makeStyles(theme => ({
    dotPadding: {
        paddingBottom: 10,
    }

}));

const ImageCarouselSlider = () => {

    const [images, setImages] = useState([]);
    const [height, setHeight] = useState(0);
    const matchesXS = useMediaQuery(theme => theme.breakpoints.down('xs'));

    const matchesSM = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const classes = useStyles();


    useEffect(() => {

        // window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        setHeight(vh);
        // });

    }, [])

    useEffect(() => {

        if (matchesSM) {
            setImages([
                {url: HeroAreaImageSmall5, alt: 'image'},
                {url: NewPackagesHomeSmall, alt: 'image1'},
                {url: HeroAreaImageSmall1, alt: 'image1'},
                {url: HeroAreaImageSmall2, alt: 'image2'},
                {url: HeroAreaImageSmall3, alt: 'image3'},
                {url: HeroAreaImageSmall4, alt: 'image4'}
            ])
        }

    }, [matchesSM])

    useEffect(() => {

        setImages([
            {url: HeroAreaImage5, alt: 'image'},
            {url: NewPackagesHomeBig, alt: 'image1'},
            {url: HeroAreaImage, alt: 'image1'},
            {url: HeroAreaImage2, alt: 'image2'},
            {url: HeroAreaImage3, alt: 'image3'},
            {url: HeroAreaImage4, alt: 'image4'}
        ])

    }, []);

    return (
        <div>
            <Carousel
                // swipeable={true}
                // draggable={true}
                showDots={true}
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                keyBoardControl={false}
                arrows={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                // customTransition="all .5s"
                // transitionDuration={1000}
                // containerClass="carousel-container"
                // removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass={classes.dotPadding}
                // item
                // Class="carousel-item-padding-40-px"
            >
                {images.map((image, index) => {

                    return (
                        <div style={{
                            height: matchesXS ? '69.2vh' : matchesSM ? '80vh' : '83.2vh',
                            // height: matchesXS ? 69.2 * height : matchesSM ? 80 * height : 83.2 * height,
                            minHeight: '-webkit-fill-available',
                            boxSizing: "border-box"
                        }} key={image.alt}>
                            <img src={image.url} alt={image.alt}
                                 style={{
                                     height: matchesXS ? '69.2vh' : matchesSM ? '80vh' : '83.2vh',
                                     minHeight: '-webkit-fill-available',
                                     width: '100%', objectFit: 'cover'
                                 }}/>
                        </div>
                    )

                })}
            </Carousel>

        </div>
    );
};

export default ImageCarouselSlider;
