import React, {Component} from 'react';
import {createMuiTheme, withStyles, withWidth} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ScrollTrigger from 'react-scroll-trigger';
import LoginLayout from "./LoginLayout/LoginLayout";
import {colorCodes} from "../../../../../Utils/Constants/Constants";
import ImageCarouselSlider from "./VideoContainer/ImageCarouselSlider";
import ThemeProvider from "@material-ui/styles/ThemeProvider";

const responsive = {
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

const style = theme => ({
    paper: {
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        // boxShadow: `
        // -1px 0px 5px -2px ${colorCodes.homeShadow},
        //  -5px -5px rgb(000,000,000,0) ,
        //   10px 5px 10px -5px ${colorCodes.homeShadow},
        //    -5px -7px 10px -1px ${colorCodes.homeShadow}`,
        // boxShadow: `-3px 3px 3px 1px ${colorCodes.homeShadow}, 0px -4px 5px 1px ${colorCodes.homeShadow}, 3px -0px 3px 1px ${colorCodes.homeShadow}`,
        boxShadow: `-3px 3px 3px 0px ${colorCodes.homeShadow}, 0px -4px 3px 0px ${colorCodes.homeShadow}, 3px -0px 3px 0px ${colorCodes.homeShadow}`,

        // height: '87.5vh',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        boxSizing: 'border-box',

        //for newer

        //for older
        // padding: '20px 6% 20px 6%',
        marginTop: 20,
        // position: 'absolute',
        // backgroundImage: `url(${HeroAreaImage})`,

    },

    contentContainer: {
        position: 'absolute',
    },

    content: {
        padding: '20px 6% 20px 6%',
        position: 'relative',
        zIndex: 50
    },
    nonSticky: {
        height: '100%',
        width: '100%'
    },
    sticky: {
        zIndex: 10,
        position: 'fixed',
        bottom: 220,
        right: 120,
        transform: 'translateY(100%)',
        // animation: '.3s ease forwards'
    },
});

const theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 387,
            md: 599,
            lg: 1280,
            xl: 1920,
        },
    },
})

class RegisterSection extends Component {

    state = {
        stickyVideo: false,
        playing: true,
        pausedVideo: true
    };

    onEnterViewport = () => {
        this.setState({stickyVideo: false})
    };

    onLeaveViewport = () => {
        this.setState({stickyVideo: true});
    };

    closeVideoPlayer = () => {
        this.setState({
            stickyVideo: false,
            playing: false,
            pausedVideo: true
        })
    };

    handlePlayPause = name => () => {


        if (name === 'play') {
            this.setState({playing: true, pausedVideo: false})
        } else {
            this.setState({playing: false, pausedVideo: true})
        }
    };


    render() {
        const {classes} = this.props;

        return (
            <ScrollTrigger onEnter={this.onEnterViewport} onExit={this.onLeaveViewport}>
                <div>


                    <div className={classes.paper}
                         style={{height: '100%'}}
                        // style={{height: this.props.width === 'xs' ? '50vh' : 'auto'}}
                    >
                        <div>
                            <div style={{
                                position: 'absolute', zIndex: 100, width: '90%',
                                // height: 'auto'
                            }}>

                                <div style={{position: 'relative'}}>
                                    <Grid container
                                          alignItems={"flex-start"}
                                          style={{
                                              padding: '20px 6% 20px 6%',
                                          }}
                                    >


                                        <Grid item xs={12} sm={8}>
                                            <div style={{paddingLeft: 0}}>
                                                <div style={{marginTop: 10}}>
                                                </div>

                                            </div>
                                        </Grid>

                                        <Grid item container xs={12} sm
                                        >
                                            <LoginLayout/>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>

                        <div style={{height: 'auto'}}>
                            <ThemeProvider theme={theme}>
                                <ImageCarouselSlider/>
                            </ThemeProvider>
                        </div>
                    </div>


                    {/*new*/}


                    {/*old*/}

                    {/*/!*<div className={classes.contentContainer}>*!/*/}
                    {/*    <Grid container*/}
                    {/*        // justify={"flex-start"}*/}
                    {/*          alignItems={"flex-start"}*/}
                    {/*          className={classes.paper}>*/}


                    {/*        <Grid item xs={12} sm={8}>*/}
                    {/*            <div style={{paddingLeft: 0}}>*/}
                    {/*                <div*/}
                    {/*                    // className={*/}
                    {/*                    //     // this.state.playing ?*/}
                    {/*                    //         this.state.stickyVideo ? classes.sticky : classes.nonSticky*/}
                    {/*                    //         // : null*/}
                    {/*                    // }*/}
                    {/*                >*/}
                    {/*                    /!*<button onClick={this.closeVideoPlayer}>close</button>*!/*/}
                    {/*                    /!*<iframe width={!this.state.stickyVideo ? 560: 300} height={!this.state.stickyVideo? 315: 150}*!/*/}
                    {/*                    /!*        src="https://www.youtube-nocookie.com/embed/chYBlArm9Ao"*!/*/}
                    {/*                    /!*        frameBorder="0"*!/*/}
                    {/*                    /!*        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*!/*/}
                    {/*                    /!*        allowFullScreen/>*!/*/}
                    {/*                    /!*<VideoContainer stickyVideo={*!/*/}
                    {/*                    /!*    // this.state.playing ?*!/*/}
                    {/*                    /!*    this.state.stickyVideo*!/*/}
                    {/*                    /!*    // : false*!/*/}
                    {/*                    /!*} pausedVideo={this.state.pausedVideo} playing={this.state.playing}*!/*/}
                    {/*                    /!*                handlePlayPause={this.handlePlayPause}*!/*/}
                    {/*                    /!*                closeVideoPlayer={this.closeVideoPlayer}/>*!/*/}
                    {/*                    /!*<iframe width="560" height="315" src="https://www.youtube.com/embed/LXb3EKWsInQ"*!/*/}
                    {/*                    /!*        frameBorder="0"*!/*/}
                    {/*                    /!*        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*!/*/}
                    {/*                    /!*        allowFullScreen/>*!/*/}
                    {/*                </div>*/}

                    {/*            </div>*/}
                    {/*        </Grid>*/}
                    {/*        <Grid item container xs={12} sm*/}
                    {/*            // justify={"flex-end"}*/}
                    {/*        >*/}
                    {/*            <LoginLayout/>*/}
                    {/*        </Grid>*/}
                    {/*    </Grid>*/}
                    {/*</div>*/}
                </div>
            </ScrollTrigger>

        );
    }
}

export default withStyles(style)(withWidth()(RegisterSection));
