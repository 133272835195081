import React, {Component, Fragment} from 'react';
import {colorCodes} from "../../../../../Utils/Constants/Constants";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";


const style = theme => ({
    successText: {
        fontSize: 22,
        padding: '10px 20px 20px 20px'
    },
    loginAnchor: {
        color: 'red'
    },
    buttonStyle: {
        background: colorCodes.backgroundMain,
        color: '#283445',
        textTransform: "none",
        fontWeight: 600
    }
});

class ErrorPage extends Component {


    handleTryAgain = () => {
        this.props.resetOnUnmount();
    };

    render() {
        const {classes} = this.props;

        return (
            <div>
                <Grid container alignItems={"center"} justify={"center"}
                      style={{height: 339, paddingLeft: 20, paddingRight: 20}}>
                    <Grid item style={{textAlign: 'center'}}>

                        <Fragment>
                            <div className={classes.successText}>
                                Error occurred while creating an account!
                            </div>
                            <div className={classes.createAccount}>
                                <div>
                                    {this.props.accountCreationErrorMessage}
                                </div>
                                <div style={{paddingTop: 20}}>
                                        <span
                                            style={{

                                                fontSize: 14,
                                                borderBottom: `1px solid ${colorCodes.textBlue}`,
                                                cursor: 'pointer'
                                            }}
                                            onClick={this.handleTryAgain}
                                        >
                                            Try again
                                        </span>
                                </div>
                            </div>
                        </Fragment>

                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {

    return {

        accountCreationErrorMessage: state.account.accountCreationErrorMessage,


    };

};

const mapDispatchToProps = dispatch => {

    return {


        resetOnUnmount: () => {
            dispatch({type: 'resetOnUnmount'});
        }

    };

};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(ErrorPage));