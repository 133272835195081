const initialState = {
    show: true,
    drawerFullWidth: true
};

const DrawerReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'hideDrawer':

            return {
                ...state,
                show: false
            };

        case 'showDrawer':

            return {
                ...state,
                show: true
            };

        case 'drawerFullWidth':

            return {
                ...state,
                drawerFullWidth: action.value
            };

        default:

            return state

    }

};


export default DrawerReducer;