const initialState = {
    prevent: false,
    message: ''
};

const PreventRouting = (state = initialState, action) => {

    switch (action.type) {

        case 'preventRouting':

            return {
                prevent: true,
                message: action.value
            };

        case 'enableRouting':

            return {
                prevent: false
            };

        default:

            return state

    }

};


export default PreventRouting;