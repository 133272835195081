const initialState = {
    flaggedQuestion: [],
    questionNote: []
};

const FlaggedQuestionReducer = (state = initialState, action) => {

    let tempList = [];

    switch (action.type) {

        case 'addFlaggedQuestion':

            return {
                ...state,
                flaggedQuestion: [...action.value],
            };

        case 'deleteFlaggedQuestion':

            if (action.listType === 'flaggedList') {
                tempList = state.flaggedQuestion.filter(question => question.id !== action.value);
            } else {
                tempList = state.flaggedQuestion.filter(question => question.question_id !== action.value);
            }


            return {
                ...state,
                flaggedQuestion: [...tempList]
            };

        case 'appendNewQuestion':

            state.flaggedQuestion.push(action.value);
            tempList = state.flaggedQuestion.filter(item => item);

            return {
                ...state,
                flaggedQuestion: tempList
            };

        case 'addFlaggedNote':

            tempList = [...state.flaggedQuestion];

            if (action.listType === 'flaggedList') {
                tempList.find(question => question.id === action.value.question_id).note = action.value.note;
            } else {
                tempList.find(question => question.question_id === action.value.question_id).note = action.value.note;
            }


            return {
                ...state,
                flaggedQuestion: [...tempList]
            };

        case 'setQuestionNote':

            let requiredArray = [];

            requiredArray = action.value.map(item => {
                return {
                    id: item.id,
                    note: ''
                }
            });


            return {
                ...state,
                questionNote: [...requiredArray]
            };

        case 'updateQuestionNote' :

            tempList = [...state.questionNote];

            // tempList.find(question => question.id === action.value.id).note = action.value.note;

            return {
                ...state,
                questionNote: [...tempList]
            };

        default:

            return state

    }

};


export default FlaggedQuestionReducer;