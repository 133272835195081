import React, {Component} from 'react';
import {ReCaptcha} from 'react-recaptcha-google'
import {connect} from "react-redux";

class RecaptchaComponent extends Component {
    //
    constructor(props, context) {
        super(props, context);
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    componentDidMount(): void {

        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    onLoadRecaptcha(){

        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }

    };

    verifyCallback(recaptchaToken){

        this.props.handleTokenChange(recaptchaToken);
    };

    render() {
        return (
            <div>
                {/* You can replace captchaDemo with any ref word */}
                <ReCaptcha
                    ref={(el) => {
                        this.captchaDemo = el;
                    }}

                    size="normal"
                    data-theme="dark"
                    render="explicit"
                    sitekey={process.env.REACT_APP_googleRecaptcha}
                    onloadCallback={this.onLoadRecaptcha}
                    verifyCallback={this.verifyCallback}
                />
            </div>
        );
    };
};


const mapDispatchToProps = dispatch => {
    return {
        changeFormButtonState: (value) => {
            dispatch({type: 'disableFormButton', value: value})
        }

    };
};

export default connect(null, mapDispatchToProps)(RecaptchaComponent);
