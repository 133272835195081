import React, {useEffect} from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {OurPartnersContent} from "../../../../../Utils/Constants/OurPartners";
import Grid from "@material-ui/core/Grid";
import {withWidth} from "@material-ui/core";
import {colorCodes} from "../../../../../Utils/Constants/Constants";

const responsive = {
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};


const MultiCarousel = (props) => {

    useEffect(() => {

        let blob = new Blob([], {type: 'text/plain'});
        let reader = new FileReader();
        reader.readAsDataURL(blob); // converts the blob to base64 and calls onload

        reader.onload = function () {
        };

    }, []);

    const handleClick = (link) => () => {

        window.open(link, '_blank');

    };


    return (
        <div>

            <div style={{marginLeft: '10%', marginRight: '10%', padding: props.width === 'xs' ? 10 : null}}>

                <Carousel
                    // swipeable={true}
                    // draggable={true}
                    showDots={true}
                    responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={2500}
                    keyBoardControl={true}
                    arrows={false}
                    renderButtonGroupOutside={true}
                    // customTransition="all .5s"
                    // transitionDuration={1000}
                    // containerClass="carousel-container"
                    // removeArrowOnDeviceType={["tablet", "mobile"]}
                    // dotListClass="custom-dot-list-style"
                    // itemClass="carousel-item-padding-40-px"
                >
                    {OurPartnersContent.map((item, index) => {

                        return (

                            <div key={index} style={{textAlign: 'center', marginBottom: 20}}>

                                <Grid container justify={'center'}>
                                    <a href={item.link} target="_blank"
                                       style={{textDecoration: 'none', color: colorCodes.textBlue}}>
                                        <Grid item container justify={"center"} xs={12} style={{textAlign: 'center'}}>

                                            <div>
                                                <img src={item.imageURL}
                                                     style={{
                                                         height: 110,
                                                         width: 230,
                                                         objectFit: 'contain',
                                                         cursor: 'pointer'
                                                     }}
                                                     alt={'image'} onClick={handleClick(item.link)}/>
                                            </div>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <div onClick={handleClick(item.link)}
                                                 style={{
                                                     textAlign: 'center',
                                                     cursor: 'pointer',
                                                     maxWidth: 500,
                                                     margin: '0 auto'
                                                 }}>
                                                <p>
                                                    {item.text}
                                                </p>
                                            </div>
                                        </Grid>
                                    </a>
                                </Grid>
                            </div>
                        )

                    })}

                </Carousel>
            </div>
        </div>
    );
};


export default withWidth()(MultiCarousel);