import React, {Component, Fragment} from 'react';
import SectionHeader from "../../../../Home/SectionHeader/SectionHeader";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {colorCodes, globalFontFamily} from "../../../../../../Utils/Constants/Constants";

const style = theme => ({

    packageContainer: {

        width: 200,
        background: 'white',

    },

    imageContainer: {

        minHeight: 150,
        fontWeight: 500,
        padding: 10,
        paddingBottom: 20,
        fontSize: 12

    },

    buttonContainer: {
        padding: '10px 0 10px 0'
    }

});

class PackageLayout extends Component {


    render() {

        const {classes} = this.props;
        let currentlySelected = false;

        if (this.props.isSelected === this.props.package.plan_type) {
            currentlySelected = true;
        }


        let oldAmount, discountAmount, discountedAmount, betaPrice;
        let scheduledTestAmount;


        switch (this.props.package.plan_type) {

            case 'FREE':
                scheduledTestAmount = this.props.package.schedule_test;
                betaPrice = 0;
                oldAmount = 0;
                discountAmount = 0;
                discountedAmount = this.props.package.cost;
                break;

            case 'LITE':
                scheduledTestAmount = this.props.package.schedule_test;
                betaPrice = 10;
                oldAmount = parseInt(this.props.package.schedule_test) * 100;
                discountAmount = 500;
                discountedAmount = this.props.package.cost;
                break;

            case 'GOLD':
                scheduledTestAmount = this.props.package.schedule_test;
                betaPrice = 10;
                oldAmount = parseInt(this.props.package.schedule_test) * 100;
                discountAmount = 1000;
                discountedAmount = this.props.package.cost;
                break;

            case 'PREMIUM':
                scheduledTestAmount = this.props.package.schedule_test;
                betaPrice = 10;
                oldAmount = parseInt(this.props.package.schedule_test) * 100;
                discountAmount = 2000;
                discountedAmount = this.props.package.cost;
                break;

            default:
                break;

        }

        let planDescription;

        if (this.props.package.plan_type === 'FREE') {

            planDescription = (

                <div style={{minHeight: 170, fontWeight: this.props.upgrade ? 600 : null}}>

                    <div>
                        <ArrowRightIcon fontSize={"small"}
                                        style={{verticalAlign: '-6px'}}/>
                        Unlimited Subscription
                    </div>
                    {/*<div>*/}
                    {/*    <ArrowRightIcon fontSize={"small"}*/}
                    {/*                    style={{verticalAlign: '-6px'}}/>*/}
                    {/*    {this.props.package.assesment_test} Assessment*/}
                    {/*    Test*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    <ArrowRightIcon fontSize={"small"}*/}
                    {/*                    style={{verticalAlign: '-6px'}}/>*/}
                    {/*    /!*{this.props.package.schedule_test} Scheduled Mock Tests*!/*/}
                    {/*    {scheduledTestAmount} Competitive Live Model*/}
                    {/*    <br/>*/}
                    {/*    <span style={{paddingLeft: 20, marginBottom: -5}}>*/}
                    {/*        Exam (CEE or NMCLE)*/}
                    {/*    </span>*/}
                    {/*</div>*/}


                    <div>
                        <ArrowRightIcon fontSize={"small"}
                                        style={{verticalAlign: '-6px'}}/>
                        Unlimited Subject
                        Based
                        <br/>
                        <span style={{paddingLeft: 20, marginTop: -5}}>
                                Tests
                            </span>
                    </div>

                    <div>
                        <ArrowRightIcon fontSize={"small"}
                                        style={{verticalAlign: '-6px'}}/>
                        Unlimited Past Question Based
                        <br/><span style={{paddingLeft: 20, marginTop: -5}}>Tests (with RESET option)</span>

                    </div>


                    <div>
                        <ArrowRightIcon fontSize={"small"}
                                        style={{verticalAlign: '-6px'}}/>
                        Performance Analytics
                    </div>

                </div>
            );

        } else {

            planDescription = (

                <div style={{minHeight: 170, fontWeight: this.props.upgrade ? 600 : null}}>

                    <div>

                        <ArrowRightIcon fontSize={"small"}
                                        style={{verticalAlign: '-6px'}}/>
                        {this.props.package.duration} {this.props.package.duration > 1 ? 'Months' : 'Month'} Subscription

                    </div>

                    {/*<div>*/}

                    {/*    <ArrowRightIcon fontSize={"small"}*/}
                    {/*                    style={{verticalAlign: '-6px'}}/>*/}

                    {/*    {this.props.package.assesment_test} Assessment*/}

                    {/*    Test*/}
                    {/*</div>*/}

                    <div>

                        <ArrowRightIcon fontSize={"small"}
                                        style={{verticalAlign: '-6px'}}/>

                        {/*{this.props.package.schedule_test} Scheduled Mock Tests*/}
                        {scheduledTestAmount} Competetive Live Model<br/>
                        <span style={{paddingLeft: 20, marginBottom: -5}}>
                            Exam (CEE or NMCLE)
                        </span>

                    </div>

                    <div>

                        <ArrowRightIcon fontSize={"small"}
                                        style={{verticalAlign: '-6px'}}/>


                        <span>
                            Unlimited Subject Based
                            <br/>
                            <span style={{paddingLeft: 20, marginTop: -5}}>
                                Tests
                            </span>
                        </span>

                    </div>

                    <div>

                        <ArrowRightIcon fontSize={"small"}
                                        style={{verticalAlign: '-6px'}}/>


                        <span>
                                Unlimited Past Question Based
                                <br/><span style={{paddingLeft: 20, marginTop: -5}}>Tests (with RESET option)</span>
                            {/*<br/>*/}
                            {/*<span style={{paddingLeft: 20, marginTop: -5}}>*/}
                            {/*   */}
                            {/*</span>*/}
                            </span>


                    </div>



                    <div>
                        <ArrowRightIcon fontSize={"small"}
                                        style={{verticalAlign: '-6px'}}/>
                        Performance Analytics
                    </div>

                    {this.props.package.plan_type === 'PREMIUM' && (

                        <div>

                            <ArrowRightIcon fontSize={"small"}
                                            style={{verticalAlign: '-6px'}}/>

                            Flashcards for memorization

                        </div>

                    )}
                </div>

            );

        }


        return (

            <Paper className={classes.packageContainer}

                   style={{
                       // fontSmoothing: 'subpixel-antialiased',
                       transform: currentlySelected ? `scale(1.03)` : `scale(0.99)`,
                       // backfaceVisibility: "hidden",
                       boxShadow: currentlySelected ? `0 0 8px 1px ${colorCodes.buttonGray}` : 'none',
                       border: this.props.upgrade ? `1px solid gray` : null,

                       transition: '0.2s ease-in-out',

                       color: this.props.upgrade ? colorCodes.textBlue : null,
                       cursor: 'pointer',
                       minWidth: 220
                   }}

                   onClick={this.props.package.status === 'invisible' ? null : this.props.handleClick(this.props.package.plan_type)}
            >

                <div className={classes.buttonContainer}>
                    <SectionHeader text={this.props.package.status.split('-')[1]} upgrade={true}/>
                </div>


                <div className={classes.imageContainer}>
                    {planDescription}
                </div>

                <div>

                    <Button fullWidth variant={"contained"} color={"primary"}
                            disableElevation={true}

                            style={{

                                height: 60,
                                borderRadius: 0,
                                background: currentlySelected ? colorCodes.headerBlue : colorCodes.topBar,
                                color: this.props.upgrade ? 'white' : currentlySelected ? 'white' : null,
                                fontWeight: 600,
                                fontFamily: globalFontFamily,
                                textTransform: "unset",
                                transition: '0.3s'
                            }}

                    >

                        {this.props.package.status === 'invisible' ? 'Coming Soon' :

                            <div style={{fontSize: 16}}>
                                {this.props.package.plan_type === 'FREE' ? (

                                    <div>
                                        <div style={{marginBottom: -3}}>
                                            <span style={{fontSize: 12}}>Price:</span> Rs. {discountedAmount}
                                        </div>
                                    </div>


                                ) : (

                                    <div>
                                        <div style={{marginBottom: -10}}>
                                        <span style={{fontSize: 13,}}>
                                             <span style={{color: 'white', fontSize: 12}}>Price:</span>
                                            &nbsp;
                                            <del style={{color: 'red'}}>
                                                <span
                                                    style={{color: this.props.upgrade ? 'white' : currentlySelected ? 'white' : 'white',}}><span>Rs.</span> {oldAmount}</span></del>
                                        </span>
                                        </div>
                                        <div>
                                                <span>
                                            <span style={{fontSize: 12}}>Discounted Price:</span> Rs. {discountedAmount}
                                        </span>
                                        </div>
                                        {/*{this.props.package.plan_type === 'LITE' && (*/}
                                        {/*    <div style={{fontSize: 8, marginTop: -3}}>*/}
                                        {/*        ** Beta Price: Rs {betaPrice}*/}
                                        {/*    </div>*/}
                                        {/*)}*/}
                                    </div>
                                )}
                            </div>
                        }

                    </Button>

                </div>

            </Paper>
        );
    }
}

export default withStyles(style)(PackageLayout);
