import React from 'react';
import NoDataLabel from "../../../Components/StudentDashboard/NoDataLabel/NoDataLabel";
import ErrorIcon from '@material-ui/icons/Error';
import {colorCodes} from "../../../Utils/Constants/Constants";

const ErrorDisplayerTestList = (props) => {


    return (
        <NoDataLabel
            withRadius={true}
        >

            <div style={{paddingBottom: 5}}>
                <ErrorIcon style={{color: colorCodes.buttonOrange, fontSize: 35}}/>
            </div>

            <div>
                {props.message}
            </div>

        </NoDataLabel>
    );
};

export default ErrorDisplayerTestList;