import axios from "axios";
import SecureLS from "secure-ls";
import {APIURL} from "../APIURL/APIURL";

//Token related task

let ls = new SecureLS({
    encodingType: 'des',
    isCompression: false,
    encryptionSecret: ' '
});

let responseMessage = {
    status: '',
    body: ''
};



export const getRequest = (routerInstance, pathName, urlParam, getData, authority, cancelObject, etagParam) => {


    let tempResponseMessage = {...responseMessage};

    let additionalHeader = {};
    if (authority === 'private') {
        additionalHeader["Authorization"] = `Bearer ${ls.get('user').data}`
    }

    let tempUrl;

    if (urlParam) {
        tempUrl = APIURL(pathName) + urlParam;
    } else {
        tempUrl = APIURL(pathName);
    }

    let tempEtagParam = etagParam ? etagParam: {};

    // console.log(tempEtagParam);

    return (
        axios({
            url: tempUrl,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                // 'If-None-Match': '"01e4cbbc91317ae1889f99ad76aac39b0"',
                ...additionalHeader,
                // ...tempEtagParam
            },
            params: getData,
            cancelToken: new axios.CancelToken((c) => cancelObject ? cancelObject.cancelFunction = c : null)
        }).then(response => {

            tempResponseMessage.status = 'success';
            tempResponseMessage.body = response;
            return tempResponseMessage
        }).catch(error => {
            // console.log(axios.isCancel(error));
            errorHandler(error, routerInstance);

            // if (error.response) {
            //     if (error.response.status === 401) {
            //         localStorage.clear();
            //         routerInstance.history.replace('/login');
            //     }
            // }

            tempResponseMessage.status = 'failure';
            tempResponseMessage.body = error;
            return tempResponseMessage
        })
    );

};

export const putRequest = (routerInstance, pathName, putData, urlParam, authority) => {

    let tempResponseMessage = {...responseMessage};


    let additionalHeader = {};
    if (authority === 'private') {
        additionalHeader["Authorization"] = `Bearer ${ls.get('user').data}`
    }

    let tempUrl;

    if (urlParam) {
        tempUrl = APIURL(pathName) + urlParam;
    } else {
        tempUrl = APIURL(pathName);
    }

    return (
        axios({

            url: tempUrl,
            method: 'PUT',
            headers: {
                'Access-Control-Allow-Origin': '*',
                ...additionalHeader
            },
            data: putData

        }).then(response => {

            tempResponseMessage.status = 'success';
            tempResponseMessage.body = response;
            return tempResponseMessage

        }).catch(error => {

            errorHandler(error, routerInstance);

            if (error.response) {
                if (error.response.status === 401) {
                    localStorage.clear();
                    routerInstance.history.replace('/login');
                }
            }

            tempResponseMessage.status = 'failure';
            tempResponseMessage.body = error;
            return tempResponseMessage
        })
    );

};

export const postRequest = (routerInstance, pathName, postData, urlParam, authority) => {

    let tempResponseMessage = {...responseMessage};

    let additionalHeader = {};
    if (authority === 'private') {
        additionalHeader["Authorization"] = `Bearer ${ls.get('user').data}`
    }

    let tempUrl;

    if (urlParam) {
        tempUrl = APIURL(pathName) + urlParam;
    } else {
        tempUrl = APIURL(pathName);
    }

    return (

        axios({
            url: tempUrl,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                ...additionalHeader

            },
            data: postData
        }).then((response) => {

            tempResponseMessage.status = 'success';
            tempResponseMessage.body = response;
            return tempResponseMessage

        }).catch((error) => {


            errorHandler(error, routerInstance);

            tempResponseMessage.status = 'failure';
            tempResponseMessage.body = error;
            return tempResponseMessage

        })
    );
};

export const deleteRequest = (routerInstance, pathName, urlParam, authority) => {

    let tempResponseMessage = {...responseMessage};


    let additionalHeader = {};
    if (authority === 'private') {
        additionalHeader["Authorization"] = `Bearer ${ls.get('user').data}`
    }

    let tempUrl;

    if (urlParam) {
        tempUrl = APIURL(pathName) + urlParam;
    } else {
        tempUrl = APIURL(pathName);
    }

    return (

        axios({
            url: tempUrl,
            method: 'DELETE',
            headers: {
                'Access-Control-Allow-Origin': '*',
                ...additionalHeader
            }
        }).then((response) => {

            tempResponseMessage.status = 'success';
            tempResponseMessage.body = response;
            return tempResponseMessage

        }).catch((error) => {

            errorHandler(error, routerInstance);

            tempResponseMessage.status = 'failure';
            tempResponseMessage.body = error;
            return tempResponseMessage

        })
    );
};


export const authorizationRequest = (routerInstance, pathName, params) => {

    let tempResponseMessage = {...responseMessage};

    return (

        axios({
            url: APIURL('loginURL'),
            method: 'POST',
            auth: {
                username: "medical-test",
                password: "medical"
            },
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Access-Control-Allow-Origin': '*',
            },
            data: params
        }).then((response) => {

            ls.set('user', {data: response.data.access_token});
            routerInstance.history.replace('/user/dashboard');

            tempResponseMessage.status = 'success';
            tempResponseMessage.body = response;
            return tempResponseMessage

        }).catch((error) => {

            tempResponseMessage.status = 'failure';
            tempResponseMessage.body = error;
            return tempResponseMessage

        })
    );
};


const errorHandler = (error, routerInstance) => {

    if (!error.message) {

        return false;

    } else {

        switch (error.message) {

            case 'Network Error':


                // routerInstance.history.push({
                //     pathname: '/network-error',
                //     state: {
                //         from: routerInstance.location.pathname
                //     }
                // });

                break;

            case 'timeout of 100ms exceeded':

                // routerInstance.history.push({
                //     pathname: '/network-error',
                //     state: {
                //         from: routerInstance.location.pathname
                //     }
                // });

                break;

            default:

                break;

        }

    }

    if (error.response) {

        switch (error.response.status) {

            case (500):

                // routerInstance.history.push({
                //     pathname: '/network-error',
                //     state: {
                //         from: routerInstance.location.pathname
                //     }
                // });

                break;

            case (401):

                localStorage.clear();
                routerInstance.history.replace('/');

                break;

            case (403):

                localStorage.clear();
                routerInstance.history.replace('/');

                break;

            case (404):

                localStorage.clear();
                routerInstance.history.replace('/');

                break;

            default:
                break;

        }
    }

};
