import * as React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {colorCodes} from "../../Utils/Constants/Constants";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        console.log('error', error)
        console.log('error info', errorInfo)
        // You can also log the error to an error reporting service
    }

    handleClick = () => {
        window.location.reload();
    };

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <Grid container style={{
                padding: 10,
                textAlign: "center",
                height: '100vh',
                width: '100vw',
                boxSizing: "border-box",
                background: colorCodes.homeInnerComponent,
                fontFamily: 'lato'
            }}
                         justify={"center"} alignItems={"center"}
            >
                <Grid item>
                    <div style={{padding: '10px 10px 30px 10px', fontSize: 35, fontWeight: 600}}>
                        Something went wrong!
                    </div>

                    <div>
                        <Button  variant={"contained"}
                                style={{color: "white", textTransform: "none", backgroundColor: colorCodes.headerBlue}} onClick={this.handleClick}
                                disableElevation={true}>
                            Refresh
                        </Button>
                    </div>
                </Grid>
            </Grid>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;