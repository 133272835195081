const initialState = {

    testReviewTimeSecond: null,
    testReviewTime: null,

    // for scheduled value will be 'SCHEDULED'
    testType: ''

};


const ScheduledTestReviewReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'setTestReviewTime':


            let tempTime = action.time;

            if (tempTime) {
                tempTime.setMinutes(tempTime.getMinutes() + 5);
            }


            return {
                ...state,
                testReviewTime: tempTime,
                testType: action.testType
            };

        case 'setTestReviewTimeSecond':

            return {
                ...state,
                testReviewTimeSecond: action.value
            };


        case 'resetTestReviewTime':

            return {
                ...initialState,
            };

        default:

            return state

    }

};


export default ScheduledTestReviewReducer