import React, {Component} from 'react';
import SectionHeader from "../../SectionHeader/SectionHeader";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from '@material-ui/icons/Send';
import {withStyles} from "@material-ui/core";

const style = theme => ({
    header: {
        paddingTop: 30,
        paddingBottom: 10
    },
    inputField: {
        background: 'white',
        '&:hover': {
            border: 'none'
        },
        '&:active': {
            border: 'none'
        }
    },
    notchedOutline: {
        border: 'none'
    },
    focused: {
        "& $notchedOutline": {
            borderColor: "yellow"
        }
    }
});


class Newsletter extends Component {

    render() {
        const {classes} = this.props;
        return (
            <div>
                <div className={classes.header}>
                    <SectionHeader text={"NEWSLETTER"} whiteUnderline={true}/>
                </div>
                <div style={{fontSize: 14}}>
                    Stay updated with our latest news.
                </div>
                <div>
                    <TextField variant={'outlined'} size={"small"} margin={"normal"}
                               InputProps={{
                                   className: classes.inputField,
                                   classes: {
                                       notchedOutline: classes.notchedOutline
                                   },
                                   endAdornment: (
                                       <InputAdornment position="end">
                                           <IconButton style={{margin: 0, padding: 0}}>
                                               <SendIcon fontSize={"small"}/>
                                           </IconButton>
                                       </InputAdornment>
                                   ),
                               }}
                    />
                </div>
            </div>
        );
    }
}

export default withStyles(style)(Newsletter);