import React, {Fragment, useEffect, useState} from 'react';
import {colorCodes, globalFontFamily, quickTestFontFamily} from "../../../Utils/Constants/Constants";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Challenge from '../../../Assets/5MinQuickTestSmall.png';
import PopupStatusReducer from "../../../Store/PopupStatusReducer";
import Test3 from '../../../Assets/TestPackage3.png';
import Test6 from '../../../Assets/TestPackage6.png';
import Test10 from '../../../Assets/TestPackage10.png';
import Grid from "@material-ui/core/Grid";

const QuickTestDialog = (props) => {


    const handleClose = name => () => {

        if (name === 'quickTest') {

            props.hideQuickTestDialog();
            props.setSlideQuickTest(true);

        } else {
            props.changePopUpStatus(false);
        }

    };

    const handleStart = (testName) => () => {

        // props.changePreview(props.quickTest, 'QUICK_TEST');
        props.hideQuickTestDialog();


        let testToStart = [];

        if (testName === 'cee') {
            testToStart = props.quickTest.filter(item => item.test_name === "QUICK_TEST")
            props.setIsNmcle(false)
        } else {
            testToStart = props.quickTest.filter(item => item.test_name === "QUICK_TEST_NMCLE")
            props.setIsNmcle(true)
        }

        props.setTestListSessionType('QUICK_TEST');

        props.setTestId(testToStart[0].id);


        let tempDate = new Date();
        let addedDate = new Date(tempDate.getTime() + parseInt(testToStart[0].duration) * 60000);

        console.log(testToStart[0])
        props.setCompletionTime(addedDate);

        props.setTime(calculateSeconds(parseInt(testToStart[0].duration)));
        props.setTimedTest(true);
        props.history.replace('/user/dashboard/test-session/' + testToStart[0].id);

        // props.history.push('/user/dashboard/test-practice/test/' + props.quickTest.id);

    };


    const calculateSeconds = (duration) => {
        return duration * 60;
    };


    return (

        <div>


            <Dialog
                open={props.open || props.popupStatusReducer}
                // open={true}
                fullWidth={true}
                maxWidth={'md'}

                PaperProps={{
                    style: {
                        backgroundColor: props.popupStatusReducer ? 'white' : colorCodes.topBar,
                        // backgroundColor: 'white',
                        borderRadius: 5,
                        boxShadow: 'none',
                    },
                }}

                style={{
                    // color:  colorCodes.topBar,
                    backgroundColor: 'transparent',
                    fontWeight: 600
                }}
            >


                <DialogContent
                    style={{
                        textAlign: "center",
                        color: colorCodes.textBlue,
                        marginTop: 5,

                        backgroundColor: 'rgb(255,255,255, 0)'
                    }}>

                    {props.popupStatusReducer ? (

                        <Fragment>
                            <div style={{marginTop: -10}}>
                                <span style={{padding: 10, color: "white", backgroundColor: colorCodes.textBlue}}>
                                    Notice
                                </span>
                            </div>

                            <div style={{marginTop: 20}}>
                                <div>
                                    You can now take Past-Test based questions (with reset options),
                                    Subject-based tests for <span style={{fontWeight: 700, fontSize: 16}}>FREE!! </span>
                                    You can also access our <span style={{fontWeight: 700, fontSize: 16}}>Performance Analytics </span> feature
                                    where you can analyze your
                                    strengths and weakness.
                                </div>
                                <div style={{margin: '0 auto', maxWidth: 500}}>
                                    <div style={{
                                        borderBottom: `2px solid ${colorCodes.textBlue}`,
                                        margin: '10px 50px 10px 50px'
                                    }}>
                                    </div>
                                </div>
                                <div>
                                    Participate in our <span
                                    style={{fontWeight: 700, fontSize: 16}}>Live CEE Test </span>twice a week.
                                    Questions are handpicked with the support of Doctors and successful CEE graduates.
                                    You can book your seat for just <span
                                    style={{fontWeight: 700, fontSize: 16}}>Rs.100 </span>. You can also subscribe to
                                    our packages.
                                </div>

                                <div style={{marginTop: 20}}>
                                    <span style={{padding: 10, backgroundColor: colorCodes.textBlue, color: 'white'}}>
                                        Our New Packages include:
                                    </span>
                                </div>

                                <div style={{marginTop: 30, marginBottom: 10}}>

                                    <div style={{marginTop: 20, maxWidth: 600, margin: '0 auto'}}>
                                        <Grid container alignItems={'center'} justify={"center"} spacing={1}>

                                            <Grid item xs={12} sm>
                                                <img src={Test3} alt="test3" height={250}/>
                                            </Grid>
                                            <Grid item xs={12} sm>
                                                <img src={Test6} alt="test6" height={250}/>
                                            </Grid>
                                            <Grid item xs={12} sm>
                                                <img src={Test10} alt="test`0" height={250}/>
                                            </Grid>

                                        </Grid>

                                    </div>
                                </div>
                            </div>

                            <div>
                                <div>
                                    For More Information: Please Contact: <span
                                    style={{fontWeight: 700, fontSize: 16}}>9823009334</span> or our Facebook
                                    page: <span style={{fontWeight: 700, fontSize: 16}}>PrePG Nepal</span>
                                </div>

                                <div style={{marginTop: 5}}>
                                    <div style={{
                                        margin: "0 auto",
                                        fontSize: 18,
                                        color: colorCodes.textBlue,
                                        fontWeight: 500,
                                        width: 45,
                                        textAlign: 'right',
                                        paddingBottom: 0,
                                        cursor: 'pointer',

                                    }}
                                         onClick={handleClose('c')}
                                    >
                                        <span
                                            style={{
                                                borderBottom: `2px solid ${colorCodes.textBlue}`,
                                                fontWeight: 600,
                                                paddingBottom: 1
                                            }}>
                                            Skip
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </Fragment>

                    ) : (
                        <Fragment>
                            <div style={{textAlign: "center"}}>
                                <img src={Challenge} height={100} alt={''}/>
                            </div>

                            <div style={{margin: '30px 0 30px 0'}}>

                        <span
                            style={{
                                padding: '25px 20px 10px 20px',
                                backgroundColor: "white",
                                borderRadius: 5,
                                fontWeight: 750,
                                letterSpacing: '0.025em'
                            }}
                        >

                            <span style={{fontSize: 26, color: colorCodes.textPurple}}>
                                Solve&nbsp;
                            </span>

                            <span style={{fontSize: 32, color: colorCodes.textOrange, fontFamily: quickTestFontFamily}}>
                                <i>10</i>
                            </span>

                            <span style={{fontSize: 26, color: colorCodes.textPurple}}>
                                 &nbsp;Questions In&nbsp;
                            </span>

                            <span style={{fontSize: 32, color: colorCodes.textOrange, fontFamily: quickTestFontFamily}}>
                                <i>5</i>
                            </span>

                            <span style={{fontSize: 26, color: colorCodes.textPurple}}>
                                 &nbsp;minutes
                            </span>

                        </span>

                            </div>

                            <div style={{
                                padding: 10,
                                fontSize: 30,
                                color: colorCodes.textBlue,
                                margin: '20px 0 20px 0'
                            }}>
                                Do you Accept the Challenge?
                            </div>

                            <Grid container justify={"center"} spacing={2}>
                                {props.quickTest.filter(item => item.test_name === 'QUICK_TEST').length > 0 && (
                                    <Grid item>

                                        <div style={{margin: '10px 0 10px 0'}}>
                                            <Button style={{
                                                backgroundColor: '#2aa518',
                                                color: 'white',
                                                // boxShadow: '0px 3px 5px 0px #d0d0d0',
                                                fontFamily: globalFontFamily,
                                                padding: '5px 40px 5px 40px',
                                                fontSize: 30,
                                                textTransform: 'none'
                                            }}
                                                    onClick={handleStart('cee')}
                                                    size={"small"}
                                            >
                                                <div style={{lineHeight: 1.2}}>
                                                    <strong>Yes, I Do</strong>
                                                    <div style={{fontSize: 12}}>Click for CEE</div>
                                                </div>
                                            </Button>
                                        </div>

                                    </Grid>
                                )}

                                {props.quickTest.filter(item => item.test_name === 'QUICK_TEST_NMCLE').length > 0 && (

                                    <Grid item>

                                        <div style={{margin: '10px 0 10px 0'}}>
                                            <Button style={{
                                                backgroundColor: '#2aa518',
                                                color: 'white',
                                                // boxShadow: '0px 3px 5px 0px #d0d0d0',
                                                fontFamily: globalFontFamily,
                                                padding: '5px 40px 5px 40px',
                                                fontSize: 30,
                                                textTransform: 'none'
                                            }}
                                                    onClick={handleStart('nmcle')}
                                                    size={"small"}
                                            >
                                                <div style={{lineHeight: 1.2}}>
                                                    <strong>Yes, I Do</strong>
                                                    <div style={{fontSize: 12}}>Click for NMCLE</div>
                                                </div>
                                            </Button>
                                        </div>

                                    </Grid>
                                )}

                            </Grid>


                            <div style={{margin: '20px 0 20px 0'}}>
                                <div style={{
                                    margin: "0 auto",
                                    fontSize: 22,
                                    color: 'black',
                                    fontWeight: 500,
                                    width: 45,
                                    textAlign: 'right',
                                    paddingBottom: 0,
                                    cursor: 'pointer',

                                }}
                                     onClick={handleClose('quickTest')}
                                >
                            <span
                                style={{borderBottom: '2px solid black', fontWeight: 600, paddingBottom: 1}}>skip</span>
                                </div>
                            </div>
                            <div style={{color: "black", fontSize: 16, margin: '20px 0 20px 0'}}>
                                FYI: This is a daily challenge targeted to help you stay updated with CEE and NMCLE
                                Pattern
                                Questions
                                regularly.
                            </div>

                        </Fragment>

                    )}


                </DialogContent>

            </Dialog>


        </div>

    );
};


const mapStateToProps = state => {

    return {
        open: state.quickTestReducer.open,
        quickTest: state.quickTestReducer.quickTest,
        anchor: state.quickTestReducer.anchor,
        popupStatusReducer: state.popupStatusReducer.showPopup
    }

};

const mapDispatchToProps = dispatch => {

    return {

        hideQuickTestDialog: () => {
            dispatch({type: 'hideQuickTestDialog'})
        },

        changePreview: (value, testListType) => {
            dispatch({type: 'changeTestPreview', value: value, testListType: testListType})
        },
        setSlideQuickTest: (value) => {
            dispatch({type: 'setSlideQuickTest', value: value})
        },
        setTime: (value) => {
            dispatch({type: 'setTime', value: value})
        },
        setTestId: (value) => {
            dispatch({type: 'setTestId', value: value})
        },
        setTimedTest: (value) => {
            dispatch({type: 'setTimedTest', value: value})
        },
        setCompletionTime: (value) => {
            dispatch({type: 'setCompletionTime', value: value})
        },
        setTestListSessionType: (value) => {
            dispatch({type: 'setTestListSessionType', value: value})
        },
        setIsNmcle: (value) => {
            dispatch({type: 'setIsNmcle', value: value})
        },

        changePopUpStatus: (value) => {
            dispatch({type: 'changePopUpStatus', value: value})
        },
    }

};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuickTestDialog));
