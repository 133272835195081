export const APIURL = (pathName) => {

    // let paths = {
    //
    //     // login URL
    //     loginURL: '/oauth/token',
    //
    //     // plan-list
    //     planList: '/user/plan-list',
    //
    //     // user registration
    //     registerUser: '/user/register-user',
    //
    //     // verification
    //     signUpVerification: '/user/verify-process/',
    //
    //     // dashboard
    //     dashboard: '/user/dashboard',
    //
    //     // test-session question fetch
    //     testSessionQuestion: '/user/test/question/',
    //
    //     // answer submit test session
    //     testAnswerSubmit: '/user/test/submit-answer/',
    //
    //     // payment verification khalti
    //     khaltiVerification: '/user/verification-payment',
    //
    //     // payment method list
    //     paymentList: '/user/payment-list',
    //
    //     // user password change
    //     preAuth: '/user/pre-auth',
    //     passwordChange: '/user/password-change',
    //
    //     // test result
    //     testResult: '/user/result/',
    //
    //     // completed test list
    //     completedTestList: '/user/test/book/',
    //
    //     // booked test list
    //     bookedTestList: '/user/test/book',
    //
    //     // scheduledTestList
    //     scheduledTestList: '/user/test/schedule-test',
    // };
    //



    let URL;

    URL = process.env["REACT_APP_" + pathName];

    return (URL);
};