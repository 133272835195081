import React, {Fragment, useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import {colorCodes} from "../../../../Utils/Constants/Constants";
import {blogContent} from "../../../../Utils/Constants/BlogContent";
import {Link, withRouter} from "react-router-dom";
import Styles from './RecommendationBlogs.module.css'


const useStyles = makeStyles(theme => ({
    root: {
        // margin: '0 auto',
        padding: '0 20px 0 20px',
        [theme.breakpoints.down('sm')]: {
            padding: '0 0px 0 0px',
        }
    },
    blogContainer: {
        padding: 10,
        maxWidth: 220,
        background: '#f1f1f1',
        color: colorCodes.textBlue,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 220
        },

    }
}));

const RecommendationBlogsTest = (props) => {

    const classes = useStyles();

    const [blogId, setBlogId] = useState(props.blogIndex);


    useEffect(() => {
        setBlogId(props.blogIndex);
    }, [props.selectedBlog]);

    const blog = blogContent[blogId];

    return (
        <Fragment>
            {blogId !== props.selectedBlog ? (
                <Grid container className={classes.root} justify={"center"}
                >
                    <div className={classes.blogContainer}>
                        <div style={{fontSize: 14, fontWeight: 600, padding: '0 0 2px 0'}}>
                            <h1 style={{margin: 0, padding: 0}}>{blog.title}</h1>
                        </div>
                        <div style={{fontSize: 12, fontWeight: 600, padding: '0 0 3px 0'}}>
                            Author: <i>{blog.author}</i>
                        </div>
                        <div style={{fontSize: 12, padding: '0 0 5px 0', textAlign: "justify"}}>
                            {blog.content[0].slice(0, 190) + '...'}
                        </div>
                        <div
                            style={{textAlign: "center", fontSize: 12, fontWeight: 600, padding: '5px 0 2px 0'}}

                        >
                            <Link to={`/user/blog/${blogId}`}>
                            <span
                                style={{
                                    backgroundColor: colorCodes.readMoreButtonColor,
                                    padding: 5,
                                    color: 'white',
                                    cursor: 'pointer'
                                }}
                                onClick={() => props.history.push(`/user/blog/${blogId}`)}
                            >
                                Click to read more..
                            </span>
                            </Link>
                        </div>
                    </div>
                </Grid>
            ) : null}
        </Fragment>
    );
};


const useStylesNew = makeStyles(theme => ({
    root: {
        // margin: '0 auto',
        fontFamily: `'Nunito', sans-serif`,
        // cursor: 'pointer',
        // padding: '0 5px 0 5px',
        paddingBottom: 10,
        [theme.breakpoints.down('sm')]: {}
    },
    blogContainer: {
        width: 200,
        borderRadius: 5,
        boxShadow: `0 0 3px 1px ${colorCodes.shadowLightGray}`,
        background: '#f7f7f7',
        color: colorCodes.textBlue,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 300
        },

    }
}));


const RecommendationBlogs = (props) => {

    const classes = useStylesNew();

    const [blogId, setBlogId] = useState(props.blogIndex);


    useEffect(() => {
        setBlogId(props.blogIndex);
    }, [props.selectedBlog]);

    const blog = blogContent[blogId];
    let descriptionText = blog.content[1].slice(0, 220) + '...';


    return (
        <Fragment>
            {blogId !== props.selectedBlog ? (
                <Fragment>
                    <Grid container className={classes.root}
                          justify={"center"}
                    >
                        <div
                            className={classes.blogContainer}
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => props.history.push(`/user/blog/${blogId}`)}
                        >
                            <div style={{height: 200, overflow: 'hidden', padding: '1px 5px 5px 5px',}}>
                                <div style={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    padding: '0 0 0px 0',
                                    textAlign: 'left',
                                    marginBottom: -2
                                }}>
                                    {blog.title.length > 23 ? `${blog.title.slice(0, 23)}...` : blog.title}
                                </div>
                                <div style={{
                                    fontSize: 12,
                                    fontWeight: 600,
                                    padding: '0 0 0px 0',
                                    textAlign: 'left',
                                    color: colorCodes.buttonGray,
                                    marginBottom: -5
                                }}>

                                    {
                                        blog.author ?
                                            <div>
                                                Author: &nbsp;
                                                <i>
                                                    {blog.author.length > 20 ? `${blog.author.slice(0, 20)}...` : blog.author}                                                </i>
                                            </div>
                                            : null
                                    }
                                </div>
                                <div className={Styles.preLoaderLayout}
                                     style={{fontSize: 12, padding: '0 0 0px 0', textAlign: "left"}}>
                                    <div style={{margin: '10px 0 10px 0'}}>
                                        <img src={`${blog.imageURLPreview}`} alt="imgBlog"/>
                                    </div>
                                    <div>
                                        {descriptionText}
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                textAlign: "center",
                                fontSize: 12,
                                fontWeight: 600,
                                padding: '0px -5px 0px -5px',
                                margin: 10
                            }}>

                            </div>
                            <div style={{
                                textAlign: "center",
                                fontSize: 12,
                                zIndex: 5000,
                                fontWeight: 600,
                                color: colorCodes.headerBlue,
                                borderRadius: '0 0 5px 5px',
                                paddingBottom: 3
                            }}>
                                Click to read more..
                            </div>
                        </div>
                    </Grid>
                </Fragment>
            ) : null}
        </Fragment>
    );
};

export default withRouter(RecommendationBlogs);
