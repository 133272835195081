import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {colorCodes} from "../../../../../Utils/Constants/Constants";
import {postRequest} from "../../../../../Utils/HttpRequests/HttpRequests";
import CircularProgress from "@material-ui/core/CircularProgress";

const style = theme => ({
    successText: {
        fontSize: 22,
        padding: 20
    },
    loginAnchor: {
        color: 'red'
    },
    buttonStyle: {
        background: colorCodes.backgroundMain,
        color: '#283445',
        textTransform: "none",
        fontWeight: 600
    }
});

class Confirmation extends Component {

    state = {
        dataReady: true,
    };

    componentDidMount() {


        if (!this.isEmpty(this.props.paymentResponse)) {
            this.setState({dataReady: false});
            let data = {
                "product_name": this.props.paymentResponse.oid.split('-')[0],
                "product_identity": this.props.paymentResponse.oid.split('-')[1],
                "amount": parseInt(this.props.paymentResponse.amt),
                "idx": this.props.paymentResponse.refId,
                "serviceName": "ESEWA",
                "userId": parseInt(this.props.paymentResponse.userId)
            };

            postRequest(this.props, 'paymentVerification', data, null, 'public').then(response => {

                if (response.status === "success") {

                    if (response.body.data.message === 'success') {
                        this.setState({dataReady: true})
                    } else {
                        this.props.setAccountCreationError('Could not verify Esewa payment!. Please contact PrePg Nepal for further process.');
                        this.props.changeActiveStepToMethod(4);
                    }

                } else {

                    this.props.setAccountCreationError('Could not verify Esewa payment! Please contact PrePg Nepal for further process.');
                    this.props.changeActiveStepToMethod(4);

                }
            });

        }

    }

    isEmpty = (obj) => {

        if (!obj) return false;

        return Object.keys(obj).length === 0;

    };


    handleLoginClick = () => {
        this.props.history.push('/user/login');
    };

    render() {

        const {classes} = this.props;

        return (
            <div>
                <Grid container alignItems={"center"} justify={"center"}
                      style={{height: 339, paddingLeft: 20, paddingRight: 20}}>
                    <Grid item style={{textAlign: 'center'}}>
                        {this.state.dataReady ? (
                            <Fragment>
                                <div className={classes.successText}>
                                    Account created successfully!
                                </div>
                                <div className={classes.createAccount}>
                                    <div>
                                        Verification link has been sent to your email. Please check spam folder if you
                                        do not receive the mail.
                                    </div>
                                    <div>
                                        In case of any problem contact support.
                                    </div>
                                    <div>
                                        Please follow the instruction provided to login.
                                    </div>
                                </div>
                            </Fragment>
                        ) : (
                            <div>
                                <CircularProgress color="primary" size="3rem"/>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        paymentResponse: state.account.paymentResponse
    }
};

const mapDispatchToProps = dispatch => {
    return {


        changeActiveStepToMethod: (value) => {
            dispatch({type: 'changeActiveStep', value: value})
        },
        setAccountCreationError: (value) => {
            dispatch({type: 'setAccountCreationError', value: value})
        },

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(style)(Confirmation)));
