import React, {useEffect} from 'react';
import BlogsLayout from "../../../Components/HomePage/Blogs/BlogsLayout";
import Footer from "../Home/Footer/Footer";
import FooterNote from "../Home/FooterNote/FooterNote";
import TopBar from "../Home/TopBar/TopBar";
import {makeStyles} from "@material-ui/styles";
import {colorCodes, metaDescription} from "../../../Utils/Constants/Constants";
import Helmet from "react-helmet";
import {withRouter} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        background: colorCodes.backgroundMain,
        minHeight: '100vh',
        width: '100%',
        boxSizing: 'border-box',
    },
    topBarContainer: {
        marginLeft: '5%',
        marginRight: '5%',
        marginBottom: 20
    },
    blogContainer: {
        boxSizing: 'border-box',
        marginLeft: '5%',
        marginRight: '5%',
        boxShadow: `0px 0px 5px 2px ${colorCodes.homeShadow}`
        // '-1px 0px 10px -4px #999999, 2px 2px 5px #999999, 10px 5px 10px -5px #999999, -5px -7px 10px -1px #999999',
        // `-3px 3px 3px 1px ${colorCodes.homeShadow}, 0px -4px 5px 1px ${colorCodes.homeShadow}, 3px -0px 3px 1px ${colorCodes.homeShadow}`,
    }
}));

const Blogs = (props) => {

    const classes = useStyles();
    useEffect(() => {
        //TODO server call
    });

    return (
        <div className={classes.root}>



            <div className={classes.topBarContainer}>
                <TopBar/>
            </div>
            <div className={classes.blogContainer}>
                <BlogsLayout/>
            </div>
            <div style={{background: colorCodes.backgroundMain}}>
                <Footer hideExtraSpace={true}/>
            </div>
            <div style={{background: colorCodes.backgroundMain}}>
                <FooterNote/>
            </div>

        </div>
    );
};

export default withRouter(Blogs);
