const initalState = {
    filter: ["1"]
};

const QuestionReducer = (state = initalState, action) => {


    switch (action.type) {

        case 'changeFilter':
            let tempArray = state.filter;
            tempArray.push("1");

            return {
                 ...state,
                filter: tempArray
            };

        default:
            return state;
    }
};

export default QuestionReducer;