import React, {Component, Fragment} from 'react';
import {Button,} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import AccountForm from "./AccountForm/AccountForm";
import ProfileForm from "./ProfileForm/ProfileForm";
import SectionHeader from "../../Home/SectionHeader/SectionHeader";
import Collapse from "@material-ui/core/Collapse";
import PackageForm from "./PackageForm/PackageForm";
import Confirmation from "./Confirmation/Confirmation";
import {withRouter} from "react-router-dom";
import {colorCodes} from "../../../../Utils/Constants/Constants";
import ErrorPage from "./ErrorPage/ErrorPage";
import ErrorDisplayerTestList from "../../../../Containers/ErrorDisplayer/ErroDisplayerTestList/ErrorDisplayerTestList";
import ErrorWrapper from "../../../../Containers/ErrorBoundary/ErrorWrapper/ErrorWrapper";

// const cropper = React.createRef(null);

// const muiTheme = createMuiTheme({
//     palette: {
//         primary: {
//             main: '#74d8e1',
//             color: 'white'
//         },
//         secondary: {
//             main: '#3259af',
//         },
//     }
// });


const styles = theme => ({

    header: {
        textAlign: 'center',
        padding: '30px 0 20px 0',
    },

    button: {
        padding: '20px 25% 30px 25%',
        [theme.breakpoints.down('sm')]: {
            padding: '20px 15% 30px 15%',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '20px 5% 30px 5%',
        },
    },

    profileFormContainer: {
        padding: '0 25% 0 25%',
        [theme.breakpoints.down('sm')]: {
            padding: '0 15% 0 15%',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '0 5% 0 5%',
        },


    },

    packageFormContainer: {
        padding: '20px 10% 0px 10%',
        [theme.breakpoints.down('sm')]: {
            padding: '20px 5% 0px 5%',
        }
    },

});

class SignUpStepper extends Component {

    state = {
        openDialog: true,
        activeStep: 0,

    };

    getSteps = () => {
        return ['Profile', 'Package', 'Payment', 'Complete'];
    };

    componentDidMount() {

        // console.log(JSON.parse(this.props.location.search));

        // let subParam = tempParam.slice(1);
        //
        // let obj= {};
        // let split = subParam.split('&');
        //
        // for(let i = 0; i < split.length; i++){
        //     let secondSplit = split[i].split('=');
        //     console.log(secondSplit);
        //     obj[secondSplit[0]] = secondSplit[1];
        // }


        let obj = {};

        let lastIndexOf = this.props.location.pathname.lastIndexOf('/');

        let split = ((this.props.location.search).slice(1)).split('&');

        for (let i = 0; i < split.length; i++) {

            let secondSplit = split[i].split('=');
            obj[secondSplit[0]] = secondSplit[1];

        }

        obj['userId'] = this.props.location.pathname.slice(lastIndexOf + 1);

        if (obj.refId) {

            this.props.setPaymentResponse(obj);
            this.props.changeActiveStepToMethod(3);

        }

    };

    componentWillUnmount(): void {
        this.props.resetOnUnmount();
    }


    handleClose = () => {

        this.props.close();
        this.props.changeFormError("");
        this.props.changeActiveStepToMethod(0);
        this.props.resetForm();

    };

    handleBack = () => {

        // if (this.props.accountFromRedux.packageType !== 'FREE') {
        //
        //     // if (this.props.activeStepFromRedux === 2) {
        //     //
        //     //     this.props.changeActiveStepToMethod(this.props.activeStepFromRedux + 1);
        //     //
        //     // } else if (this.props.activeStepFromRedux === 3) {
        //     //
        //     //     this.props.changeActiveStepToMethod(this.props.activeStepFromRedux - 2);
        //     //
        //     // } else {
        //
        //         this.props.changeActiveStepToMethod(this.props.activeStepFromRedux - 1);
        //
        //     // }
        //
        // } else {
        //
        //     this.props.changeActiveStepToMethod(this.props.activeStepFromRedux - 1);
        //
        // }

        this.props.changeFormError("");
        this.props.changeActiveStepToMethod(this.props.activeStepFromRedux - 1);
    };

    handleNext = () => {

        this.props.changeStepMethod();
        this.props.changeStepToMethod(this.props.activeStepFromRedux + 1);

    };

    isEmpty = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    };


    render() {

        const {classes} = this.props;

        let tempForm;

        switch (this.props.activeStepFromRedux) {

            case 0:
                tempForm = (
                    <div className={classes.profileFormContainer}>
                        <ErrorWrapper
                            fallbackComponent={
                                <div style={{maxWidth: 750, margin: '0 auto', paddingTop: 30, paddingBottom: 30}}>
                                    <ErrorDisplayerTestList
                                        message={
                                            <Fragment>
                                                <div>
                                                    An error occurred.
                                                </div>
                                                <div>
                                                    Please try again later.
                                                </div>
                                            </Fragment>
                                        }/>
                                </div>
                            }
                        >
                            <ProfileForm/>
                        </ErrorWrapper>
                    </div>
                );
                break;

            case 1:
                tempForm = (
                    <div className={classes.packageFormContainer}>
                        <ErrorWrapper
                            fallbackComponent={
                                <div style={{maxWidth: 750, margin: '0 auto', paddingTop: 30, paddingBottom: 30}}>
                                    <ErrorDisplayerTestList
                                        message={
                                            <Fragment>
                                                <div>
                                                    An error occurred.
                                                </div>
                                                <div>
                                                    Please try again later.
                                                </div>
                                            </Fragment>
                                        }/>
                                </div>
                            }
                        >
                            <PackageForm/>
                        </ErrorWrapper>
                    </div>
                );
                break;

            // case 2:
            //     tempForm = (
            //         <div className={classes.packageFormContainer}>
            //             <ReCaptchaForm/>
            //         </div>
            //     );
            //     break;

            case 2:
                tempForm = (
                    <div>
                        <ErrorWrapper
                            fallbackComponent={
                                <div style={{maxWidth: 750, margin: '0 auto', paddingTop: 30, paddingBottom: 30}}>
                                    <ErrorDisplayerTestList
                                        message={
                                            <Fragment>
                                                <div>
                                                    An error occurred.
                                                </div>
                                                <div>
                                                    Please try again later.
                                                </div>
                                            </Fragment>
                                        }/>
                                </div>
                            }
                        >
                            <AccountForm/>
                        </ErrorWrapper>
                    </div>
                );
                break;

            case 3:
                tempForm = (
                    <ErrorWrapper
                        fallbackComponent={
                            <div style={{maxWidth: 750, margin: '0 auto', paddingTop: 30, paddingBottom: 30}}>
                                <ErrorDisplayerTestList
                                    message={
                                        <Fragment>
                                            <div>
                                                An error occurred.
                                            </div>
                                            <div>
                                                Please try again later.
                                            </div>
                                        </Fragment>
                                    }/>
                            </div>
                        }
                    >
                        <Confirmation/>
                    </ErrorWrapper>
                );
                break;

            case 4:
                tempForm = (
                    <ErrorWrapper
                        fallbackComponent={
                            <div style={{maxWidth: 750, margin: '0 auto', paddingTop: 30, paddingBottom: 30}}>
                                <ErrorDisplayerTestList
                                    message={
                                        <Fragment>
                                            <div>
                                                An error occurred.
                                            </div>
                                            <div>
                                                Please try again later.
                                            </div>
                                        </Fragment>
                                    }/>
                            </div>
                        }
                    >
                        <ErrorPage/>
                    </ErrorWrapper>
                );
                break;


            default:
                break;
        }

        const steps = this.getSteps();


        let nextDisable =
            this.props.activeStepFromRedux === 0 ?
                !this.props.accountFromRedux.termsAndConditions
                : this.props.disableFormButtonFromRedux;


        return (

            <Fragment>

                <div style={{backgroundColor: colorCodes.homeInnerComponent}}>
                    <div className={classes.header}>
                        <SectionHeader text={'Register'}/>
                    </div>

                    <Collapse in={this.props.formErrorFromRedux}>

                        <Grid container style={{color: colorCodes.buttonLightRed, height: 40}} justify={"center"}
                              alignItems={"center"}>
                            <Grid item>
                                <strong>{this.props.formErrorFromRedux}</strong>
                            </Grid>
                        </Grid>

                    </Collapse>

                    <div>

                        <div>
                            <div className={classes.formContainer}>
                                {tempForm}
                            </div>
                        </div>

                    </div>


                    <div className={classes.button}>

                        {
                            this.props.activeStepFromRedux >= 3 ?

                                null

                                : (

                                    <div>

                                        <Grid container justify={"space-between"}>

                                            <Grid item>

                                                {this.props.activeStepFromRedux === 0 ? null : (

                                                    <Button
                                                        disabled={
                                                            false
                                                            // this.props.disableFormButtonFromRedux
                                                            // || (this.props.activeStepFromRedux === 1 && !this.isEmpty(this.props.paymentInfo))
                                                        }
                                                        onClick={this.handleBack}
                                                        className={classes.backButton}
                                                        size={"small"}
                                                        style={{color: colorCodes.textBlue, fontWeight: 600}}
                                                    >

                                                        Back

                                                    </Button>

                                                )}

                                            </Grid>

                                            <Grid item>

                                                <Button variant="contained" color="secondary" onClick={this.handleNext}
                                                        disabled={nextDisable
                                                        }
                                                        size={"small"}
                                                        style={{
                                                            fontWeight: 600,
                                                            backgroundColor: (nextDisable) ? null : colorCodes.buttonLightGreen
                                                        }}
                                                >

                                            <span style={{color: 'white'}}>

                                                Next

                                            </span>

                                                </Button>

                                            </Grid>

                                        </Grid>

                                    </div>

                                )}
                    </div>
                </div>

            </Fragment>
        );
    }
}

const mapStateToProps = state => {

    return {

        activeStepFromRedux: state.account.activeStep,
        changeStepFromRedux: state.account.changeStep,
        changeToStepFromRedux: state.account.changeToStep,
        formErrorFromRedux: state.account.formError,
        accountFromRedux: state.account.account,
        disableFormButtonFromRedux: state.account.disableFormButton,
        paymentInfo: state.account.paymentInfo,

    };

};

const mapDispatchToProps = dispatch => {

    return {

        changeStepMethod: () => {
            dispatch({type: 'changeStep', value: true})
        },
        changeStepToMethod: (value) => {
            dispatch({type: 'changeToStep', value: value})
        },
        changeActiveStepToMethod: (value) => {
            dispatch({type: 'changeActiveStep', value: value})
        },
        changeFormError: (value) => {
            dispatch({type: 'formError', value: value})
        },
        resetForm: () => {
            dispatch({type: 'resetForm'})
        },
        setPaymentResponse: (value) => {
            dispatch({type: 'setPaymentResponse', value: value})
        },
        resetOnUnmount: () => {
            dispatch({type: 'resetOnUnmount'});
        }

    };

};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(SignUpStepper)));