const initialState = {
    selectedTest: {},
};

const NonScheduledTestListState = (state = initialState, action) => {

    switch (action.type) {

        case 'changeSelectedSubTest':

            return {
                selectedTest: action.value
            };

        case 'clearSelectedSubTest':

            return {
                ...initialState
            };


        default:

            return state

    }

};


export default NonScheduledTestListState;