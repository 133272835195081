import React, {Component} from 'react';
import ItemsCarousel from "react-items-carousel";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {withStyles, withWidth} from "@material-ui/core";
import BlogView from "../BlogView/BlogView";
import {withRouter} from "react-router-dom";
import {blogContent} from "../../../../Utils/Constants/BlogContent";

const style = theme => ({
    root: {},
    changeIcon: {
        color: '#637e93'
    },
    blogView: {
        paddingLeft: '3.5%',
        paddingRight: '3.5%'
    }
});


class BlogSliderComponent extends Component {

    state = {
        activeItemIndex: 0,
        numberOfCards: 3,
        itemList: ['','', '', '', '']
    };

    setActiveItemIndex = (event) => {
        this.setState({activeItemIndex: event});
    };

    componentDidMount() {
        if (this.props.width === 'md' || this.props.width === 'lg' || this.props.width === 'xl') {
            this.setState({numberofCards: 3});
        } else if (this.props.width === 'xs') {
            this.setState({numberofCards: 1});
        } else if (this.props.width === 'sm') {
            this.setState({numberofCards: 2});

        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {


        if (this.props.width !== prevProps.width) {
            if (this.props.width === 'md' || this.props.width === 'lg' || this.props.width === 'xl') {
                this.setState({numberOfCards: 3});
                if (prevProps.width === 'sm') {
                }
            } else if (this.props.width === 'xs') {
                this.setState({numberOfCards: 1});
                if (prevProps.width === 'sm') {
                }
            } else if (this.props.width === 'sm') {
                this.setState({numberOfCards: 2});
                if (prevProps.width === 'xs') {
                } else if (prevProps.width === 'md') {
                }
            }
        }
    }

    render() {
        const {classes} = this.props;
        let cardNumber;
        switch (this.props.width) {
            case 'xs':
                cardNumber = 1;
                break;
            case 'sm':
                cardNumber = 2;
                break;
            default:
                cardNumber = 3;
                break;

        }

        return (
            <div>
                <div style={{marginLeft: '5%', marginRight: '5%', padding: this.props.width === 'xs' ? 10 : null}}>
                    <ItemsCarousel
                        requestToChangeActive={this.setActiveItemIndex}
                        activeItemIndex={this.state.activeItemIndex}
                        numberOfCards={cardNumber}
                        infiniteLoop={true}
                        gutter={20}
                        leftChevron={<ArrowBackIosIcon fontSize={'large'} className={classes.changeIcon}/>}
                        rightChevron={<ArrowForwardIosIcon fontSize={'large'} className={classes.changeIcon}/>}
                        outsideChevron
                        chevronWidth={this.props.width === 'xs' ? 10 : 50}
                    >
                        {
                            this.state.itemList.map((item, index) => {

                                return (

                                    <div className={classes.blogView} key={index}>

                                        <BlogView blogIndex={index} content={blogContent[(index + 1).toString()]}/>

                                    </div>

                                )

                            })
                        }

                    </ItemsCarousel>
                </div>
            </div>
        );
    }
}

export default withWidth()(withStyles(style)(withRouter(BlogSliderComponent)));