import React from 'react';
import {makeStyles} from "@material-ui/styles";
import {colorCodes, globalFontFamily} from "../../../Utils/Constants/Constants";

const useStyles = makeStyles(theme => ({

    root: {
        padding: 0,
        fontFamily: globalFontFamily,
        background: '#f2f2f2',
    },
    blogContainer: {
        background: '#f2f2f2',
        margin: '0 auto',
        maxWidth: 850,
        padding: '20px 20px 30px 20px'
    },
    textContainer: {
        color: colorCodes.textBlue,
        letterSpacing: 0.3,
        lineHeight: 1.4,
        fontSize: 14,
    }

}));

const AboutUsLayout = () => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.root}>
                <div className={classes.blogContainer}>

                    <div className={classes.textContainer}
                         style={{
                             width: '100%',
                             fontSize: 18,
                             wordSpacing: '0.15rem',
                             color: '#383838',
                             textAlign: 'left'
                         }}
                    >
                        <p>
                            We are a team of experienced doctors trying to innovate in the domain of medical education
                            and
                            healthcare. In the realm of PG preparation, we have a reading room - “Central Readers’
                            Club”,
                            that has been serving PG aspirants for over 2 years now. With the combined experience of our
                            versatile team members who have a plethora of experience of not only preparing for PG and
                            above
                            but also working as instructors for medical entrance preparation classes for over a decade,
                            we
                            bring a lot into the table. Once we realized how much time on an average a doctor has to
                            spend
                            to prepare for PG and the opportunity cost of leaving work to conduct such preps, we were
                            determined provide a better solution in this domain. Hence began the PrePG Nepal project.
                            Through this platform we are trying to address all the major pain points faced by PG
                            aspirants.
                            We have gathered a good amount of resources and extensively used technology to bring in
                            features
                            that we believe will truly help all the students.
                        </p>
                        <p>
                            And to make it even better, we are just getting started. We promise to make this platform a
                            go-to household name for all the doctors when it comes to preparing for PG. We hope you can
                            be a
                            part of this platform and help us build a community in this domain. See you on the inside!!
                        </p>
                        Lets Go………
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUsLayout;
