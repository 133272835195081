import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import TopBar from "../TopBar/TopBar";
import RegisterSection from "./RegisterSection/RegisterSection";
import {colorCodes} from "../../../../Utils/Constants/Constants";
import Grid from "@material-ui/core/Grid";
import LoginLayout from "./RegisterSection/LoginLayout/LoginLayout";

const style = theme => ({
    root: {
        // paddingTop: 10,
        // height: '100vh',
        // height: `calc(var(--vh, 1vh) * 100)`,
        background: colorCodes.backgroundMain,
        // backgroundPosition: 'center center',
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
        // backgroundImage: `url('https://image.freepik.com/free-photo/view-details-doctor-hands-typing-keyboard-with-blank-screen_1715-1048.jpg')`,
        paddingLeft: '5%',
        paddingRight: '5%'
    },
    topBar: {
        paddingTop: 10,
        paddingLeft: '5%',
        paddingRight: '5%'
    },
    topBarContainer: {
        marginLeft: '5%',
        marginRight: '5%',
        marginBottom: 20
    },
    formContainer: {
        boxSizing: 'border-box',
        marginLeft: '5%',
        marginRight: '5%',
        boxShadow: `0px 0px 5px 2px ${colorCodes.homeShadow}`
        // '-1px 0px 10px -4px #999999, 2px 2px 5px #999999, 10px 5px 10px -5px #999999, -5px -7px 10px -1px #999999',
    }
});

class HeroArea extends Component {

    componentDidMount() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        window.addEventListener('resize', () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });
    }

    render() {

        const {classes} = this.props;

        return (
            <div>
                <div className={classes.root}>
                    <Grid container>
                        <Grid item xs={12}>
                            <TopBar/>
                        </Grid>

                        <div className={classes.formContainer}>

                        <Grid item xs={12}>
                            <LoginLayout/>
                        </Grid>
                        </div>
                    </Grid>
                    {/*    <div>*/}
                    {/*        <TopBar/>*/}
                    {/*    </div>*/}
                    {/*    <div style={{height: "auto"}}>*/}
                    {/*        <RegisterSection/>*/}
                    {/*    </div>*/}
                </div>
            </div>
        );
    }
}

export default withStyles(style)(HeroArea);
