import React, {Component, Fragment} from 'react';
import PackageLayout from "./PackageLayout/PackageLayout";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import {CircularProgress} from "@material-ui/core";
import {getRequest} from "../../../../../Utils/HttpRequests/HttpRequests";
import {withRouter} from "react-router-dom";
import ReCaptchaForm from "../ReCaptchaForm/ReCaptchaForm";
import Collapse from "@material-ui/core/Collapse";
import {colorCodes} from "../../../../../Utils/Constants/Constants";

class PackageForm extends Component {

    state = {
        selected: this.props.accountFromRedux.packageType,
        packageList: ['FREE', 'LITE', 'PREMIUM', 'GOLD'],
        submitting: false,
        submitted: false,
        errorMessage: '',
        package: [],
        handleServerCall: false,
        captchaResponse: ''

    };


    componentDidMount() {

        this.setState({submitting: true});
        this.props.disableFormButton(true);

        getRequest(this.props, 'planList').then(response => {
            if (response.status === "success") {
                let tempArr = response.body.data.data.filter(item => item.status.split('-')[0] === 'visible');
                if(tempArr.length === 0) return ;
                this.setState({package: tempArr, submitting: false});
                if (this.props.accountFromRedux.packageType !== "FREE") {
                    this.props.disableFormButton(false);
                }
            }
        });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            if (this.props.changeStepFromRedux === true) {
                this.handleSubmit();
            }
        }
    }

    componentWillUnmount() {
        this.props.changeFormError('');
    }

    setCaptchaResponse = (response) => {
        this.setState({captchaResponse: response});
        this.props.disableFormButton(false);

    };

    handleSubmit = () => {

        this.props.changeStepMethod();

        if (this.props.accountFromRedux.packageType === "FREE") {
            if (this.state.captchaResponse) {

                this.setState({handleServerCall: true})
            } else {
                this.props.changeFormError('Captcha not verified!');
            }
            // this.props.changeActiveStepToMethod(2);
        } else {
            this.props.changeActiveStepToMethod(2);
        }


    };

    handleSubmittingChange = (value) => {

        this.setState({submitting: value})

    };

    handleServerCallChange = () => {
        this.setState({handleServerCall: false})
    };

    changeSelected = (newSelected) => () => {

        if (newSelected !== 'FREE') {
            this.setState({captchaResponse: ''});
            this.props.disableFormButton(false);
        } else {

            if (!this.state.captchaResponse) this.props.disableFormButton(true);

        }
        this.props.changeFormValue('packageType', newSelected);
        this.setState({selected: newSelected})
    };

    render() {
        return (
            <div>
                {this.state.submitting ? (
                    <Grid container alignItems={"center"} justify={"center"} style={{minHeight: 339}}>
                        <Grid item>
                            <CircularProgress color="primary" size="3rem"/>
                        </Grid>
                    </Grid>
                ) : (
                    <Fragment>
                        <div style={{
                            padding: '0 10px 20px 10px',
                            marginTop: -15,
                            fontSize: 14,
                            textAlign: 'center',
                            color: colorCodes.textBlue
                        }}>
                            **If you do not want to subscribe, you can create a free account and give live model tests at Rs.
                            100/Test
                        </div>
                        <Grid container justify={"space-evenly"} alignItems={"center"} spacing={2}
                              style={{minHeight: 339}}>


                            {this.state.package.map(packageItem => {
                                return (
                                    <Grid item key={packageItem.id} style={{perspective: 1000}}>
                                        <PackageLayout text={packageItem.id} package={packageItem}
                                                       isSelected={this.state.selected}
                                                       handleClick={this.changeSelected}/>
                                    </Grid>
                                )
                            })
                            }


                        </Grid>
                    </Fragment>
                )}
                <div style={{marginTop: 30}}>
                    <Collapse in={this.props.accountFromRedux.packageType === 'FREE'} mountOnEnter unmountOnExit>
                        <ReCaptchaForm setCaptchaResponse={this.setCaptchaResponse}
                                       submitting={this.state.submitting}
                                       handleSubmittingChange={this.handleSubmittingChange}
                                       handleServerCall={this.state.handleServerCall}
                                       handleServerCallChange={this.handleServerCallChange}
                        />
                    </Collapse>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        activeStepFromRedux: state.account.activeStep,
        changeStepFromRedux: state.account.changeStep,
        changeToStepFromRedux: state.account.changeToStep,
        accountFromRedux: state.account.account,
        disableFormButtonFromRedux: state.account.disableFormButton

    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeStepMethod: () => {
            dispatch({type: 'changeStep', value: false})
        },
        changeStepToMethod: (value) => {
            dispatch({type: 'changeToStep', value: value})
        },
        changeActiveStepToMethod: (value) => {
            dispatch({type: 'changeActiveStep', value: value})
        },
        changeFormValue: (formField, value) => {
            dispatch({type: 'changeAccount', formField: formField, value: value})
        },
        changeFormError: (value) => {
            dispatch({type: 'formError', value: value})
        },

        disableFormButton: (value) => {
            dispatch({type: 'disableFormButton', value: value})
        },
        // changeFormButtonState: (value) => {
        //     dispatch({type: 'disableFormButton', value: value})
        // }

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PackageForm));
