const initialState = {
    open: false,
    slide: false,
    quickTest: [],
    anchor: null,
    showCompletedQuickTestList: false,
};

const QuickTestReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'showQuickTestDialog':

            return {
                ...state,
                open: true
            };

        case 'setShowCompletedQuickTestList':

            return {
                ...state,
                showCompletedQuickTestList: action.value
            }


        case 'setSlideQuickTest':

            return {
                ...state,
                slide: true
            };

        case 'setQuickTest':

            return {
                ...state,
                quickTest: action.value
            }

        case 'clearQuickTest':

            let tempQuickTest = state.quickTest.filter(item => item.id !== action.value)

            return {
                ...initialState,
                quickTest: tempQuickTest
            }

        case 'setQuickTestAnchor':

            return {
                ...state,
                anchor: action.value
            }


        case 'hideQuickTestDialog':

            return {
                ...state,
                open: false
            };

        default:

            return state

    }

};


export default QuickTestReducer;
