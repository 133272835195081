import React, {Component, Fragment} from 'react';
import SignUpStepper from "../../../Components/HomePage/SignUp/SignUpStepper/SignUpStepper";
import {withStyles} from "@material-ui/core";
import TopBar from "../Home/TopBar/TopBar";
import {colorCodes, metaDescription} from "../../../Utils/Constants/Constants";
import ErrorDisplayerTestList from "../../ErrorDisplayer/ErroDisplayerTestList/ErrorDisplayerTestList";
import ErrorWrapper from "../../ErrorBoundary/ErrorWrapper/ErrorWrapper";
import Helmet from "react-helmet";

const style = theme => ({
    root: {
        background: colorCodes.backgroundMain,
        minHeight: '100vh',
        width: '100%',
        boxSizing: 'border-box',
        paddingBottom: 80,
    },
    hiddenDiv: {
        height: 80
    },
    topBarContainer: {
        marginLeft: '5%',
        marginRight: '5%',
        marginBottom: 20
    },
    formContainer: {
        boxSizing: 'border-box',
        marginLeft: '5%',
        marginRight: '5%',
        boxShadow: `0px 0px 5px 2px ${colorCodes.homeShadow}`
        // '-1px 0px 10px -4px #999999, 2px 2px 5px #999999, 10px 5px 10px -5px #999999, -5px -7px 10px -1px #999999',
    }
});

class SignUp extends Component {
    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>

                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Sign Up</title>
                    <meta name="robots" content="disallow"/>
                    <meta name="description"
                          content={"Ready to practice CEE Pattern Live test and subject wise test in the best PG/MDMS preparation platform in Nepal? Sign-up now!\n"}
                    />
                </Helmet>

                <div className={classes.topBarContainer}>
                    <TopBar/>
                </div>

                <div className={classes.formContainer}>

                    <ErrorWrapper
                        fallbackComponent={
                            <div style={{maxWidth: 750, margin: '0 auto', paddingTop: 30, paddingBottom: 30}}>
                                <ErrorDisplayerTestList
                                    message={
                                        <Fragment>
                                            <div>
                                                An error occurred.
                                            </div>
                                            <div>
                                                Please try again later.
                                            </div>
                                        </Fragment>
                                    }/>
                            </div>
                        }
                    >
                        <SignUpStepper/>
                    </ErrorWrapper>
                </div>
            </div>
        );
    }
}

export default withStyles(style)(SignUp);
