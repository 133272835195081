import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {CircularProgress} from "@material-ui/core";
import './AccountForm.css';
import Grid from "@material-ui/core/Grid";
import {getRequest} from "../../../../../Utils/HttpRequests/HttpRequests";
import {withRouter} from "react-router-dom";
import ReCaptchaForm from "../ReCaptchaForm/ReCaptchaForm";

class AccountForm extends Component {

    state = {
        account: {
            accountType: this.props.accountFromRedux.accountType,
            paymentMethod: this.props.accountFromRedux.paymentMethod,
            userRole: this.props.accountFromRedux.userRole,
        },
        formSubmitting: false,
        submitting: false,
        captchaResponse: '',
        paymentOptions: []
    };

    componentDidMount() {
        this.setState({submitting: true});
        this.props.disableFormButton(true);

        getRequest(this.props, 'paymentList', null, null, 'public').then(response => {

            if (response.status === "success") {
                this.setState({paymentOptions: response.body.data.data, submitting: false});
            } else {
                //TODO error handle
            }
        });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props !== prevProps) {
            if (this.props.changeStepFromRedux === true) {
                this.handleSubmit();
            }
        }
    }

    handleSubmittingChange = (value) => {

        if (value === false) {
            this.props.disableFormButton(true);
        }
        this.setState({submitting: value})

    };

    handleServerCallChange = () => {
        this.setState({handleServerCall: false})
    };

    setCaptchaResponse = (response) => {
        this.setState({captchaResponse: response});
        this.props.disableFormButton(false);
    };


    handleSubmit = () => {
        this.props.changeStepMethod();

        if (this.state.captchaResponse) {
            this.setState({handleServerCall: true})
        } else {
            this.props.changeFormError('Captcha not verified!');
        }

        this.props.changeActiveStepToMethod(2);

    };

    handleChange = (name) => ({target: {value}}) => {
        this.props.changeFormValue(name, value);
        this.setState({
            account: {
                ...this.state.account,
                [name]: value
            }
        })
    };


    render() {

        let tempForm;
        if (this.state.submitting) {
            tempForm = (
                <Grid container alignItems={"center"} justify={"center"} style={{minHeight: 180}}>
                    <Grid item>
                        <CircularProgress color="primary" size="3rem"/>
                    </Grid>
                </Grid>
            );
        } else {
            tempForm = (
                <Fragment>
                    <div style={{marginTop: '16px'}}>
                        <div>
                            <div className="cc-selector">
                                {this.state.paymentOptions.map((option) => {
                                    return (
                                        <Fragment key={option.paymentName}>
                                            <div style={{padding: 10}}>
                                                <input id={option.paymentName} type="radio" name="paymentMethod"
                                                       onClick={this.handleChange('paymentMethod')}
                                                       value={option.paymentName}
                                                       checked={this.state.account.paymentMethod === option.paymentName}/>
                                                <label className={"paymetGateways-cc " + option.paymentName}
                                                       htmlFor={option.paymentName}/>
                                            </div>
                                        </Fragment>
                                    );
                                })
                                }
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }
        return (
            <div>
                <div>
                    {tempForm}
                </div>
                <div style={{marginTop: 20}}>

                    <ReCaptchaForm setCaptchaResponse={this.setCaptchaResponse}
                                   submitting={this.state.submitting}
                                   handleSubmittingChange={this.handleSubmittingChange}
                                   handleServerCall={this.state.handleServerCall}
                                   handleServerCallChange={this.handleServerCallChange}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeStepFromRedux: state.account.activeStep,
        changeStepFromRedux: state.account.changeStep,
        changeToStepFromRedux: state.account.changeToStep,
        accountFromRedux: state.account.account

    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeStepMethod: () => {
            dispatch({type: 'changeStep', value: false})
        },
        changeStepToMethod: (value) => {
            dispatch({type: 'changeToStep', value: value})
        },
        changeActiveStepToMethod: (value) => {
            dispatch({type: 'changeActiveStep', value: value})
        },
        changeFormValue: (formField, value) => {
            dispatch({type: 'changeAccount', formField: formField, value: value})
        },
        changeFormError: (value) => {
            dispatch({type: 'formError', value: value})
        },
        disableFormButton: (value) => {
            dispatch({type: 'disableFormButton', value: value})
        },

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountForm));