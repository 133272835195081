import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core";
import AboutUs from "../../../../Components/HomePage/Home/FooterLayout/AboutUs/AboutUs";
import Newsletter from "../../../../Components/HomePage/Home/FooterLayout/Newsletter/Newsletter";
import FollowUs from "../../../../Components/HomePage/Home/FooterLayout/FollowUs/FollowUs";
import {colorCodes, globalFontFamily} from "../../../../Utils/Constants/Constants";

const style = theme => ({
    root: {
        color: 'white',
        paddingTop: 50,
        // paddingBottom:
        marginLeft: '5%',
        marginRight: '5%',
        boxShadow: `-3px 3px 3px 1px ${colorCodes.homeShadow}, 0 0, 3px 3px 3px 1px ${colorCodes.homeShadow}`
    },
    gridContainer: {
        background: colorCodes.topBarButton,
        // marginLeft: '2%',
        // marginRight: '2%',
        paddingLeft: '5%',
        paddingRight: '5%',
        paddingTop: 30,
        paddingBottom: 30
    },
    gridItem: {
        paddingLeft: 20,
        paddingRight: 20,
        textAlign: 'center'
    }
});

class Footer extends Component {
    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root} style={{paddingTop: this.props.hideExtraSpace ? 0 : 0}}>
                <Grid container justify={"space-around"} alignItems={""} className={classes.gridContainer}>
                    <Grid item xs={12} sm={4} className={classes.gridItem} style={{textAlign: 'justify', fontFamily: globalFontFamily}}>
                        <AboutUs/>
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.gridItem}>
                        <Newsletter/>
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.gridItem}>
                        <FollowUs/>
                    </Grid>

                </Grid>

            </div>
        );
    }
}

export default withStyles(style)(Footer);
