import React, {Component, lazy, Suspense} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";

import ErrorBoundary from "../../Containers/ErrorBoundary/ErrorBoundary";
import AboutUs from "../../Containers/HomePage/AboutUs/AboutUs";
import SignUp from "../../Containers/HomePage/SignUp/SignUp";
import Blogs from "../../Containers/HomePage/Blogs/Blogs";
import Home from "../../Containers/HomePage/Home/Home";
import EsewaNativePage from "../../Containers/EsewaNativePage/EsewaNativePage";
import * as ReactGa from "react-ga";
import HowToUseHome from "../../Containers/HomePage/HowToUse/HowToUseHome";
import ErrorPage from "../../Containers/ErrorPage/ErrorPage";
import {getRequest} from "../HttpRequests/HttpRequests";
import {connect} from "react-redux";


const Verification = lazy(() => import( "../../Containers/HomePage/Verification/Verification"));
const PrivacyPolicy = lazy(() => import("../../Containers/HomePage/PrivacyPolicy/PrivacyPolicy"));
const TermsAndConditions = lazy(() => import("../../Containers/HomePage/TermsAndConditions/TermsAndConditions"));
const PasswordReset = lazy(() => import("../../Containers/HomePage/ForgetPassword/PasswordReset/PasswordReset"));
const ForgetPasswordLinkCreation = lazy(() => import("../../Containers/HomePage/ForgetPassword/ForgetPasswordLinkCreation/ForgetPasswordLinkCreation"));
const Drawer = lazy(() => import("../../Containers/StudentDashboard/Drawer/Drawer"));


class Routes extends Component {


    componentDidMount() {


        if (!localStorage.getItem('new_packages')) {
            getRequest(this.props, 'popupStatus', null, null, 'public').then(response => {

                if (response.status === 'success') {
                    localStorage.setItem('new_packages', response.body.data ? 1 : 0)

                    this.props.changePopUpStatus(response.body.data);
                }

            })
        }



        // if (typeof window !== "undefined") {
        //     if (window.fbq != null) {
        //         window.fbq('track', 'Purchase', {currency: "USD", value: 30.00});
        //     }
        // }


        // ReactGa.initialize('UA-151965691-1', {
        //     siteSpeedSampleRate: 100,
        //     debug: true
        // });
        // ReactGa.pageview(window.location.pathname + window.location.search);

        //
        // let ga= ReactGa.ga();
        //

        // ga('send', {
        //     hitType: 'pageview',
        //     page: window.location.pathname + window.location.search
        // });

    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        ReactGa.pageview(window.location.pathname + window.location.search);

    }


    render() {

        return (
            <div style={{width: '100%'}}>

                <ErrorBoundary>

                    <Suspense fallback={null}>

                        <Switch>

                            <Redirect exact from="/demo" to="/"/>
                            <Route exact path="/" component={Home}/>
                            <Route path="/user/sign-up" component={SignUp}/>

                            {/*<Route path={'/page-not-found'} component={PageNotFound}/>*/}
                            <Route exact path="/user/verify" component={Verification}/>
                            <Route exact path="/user/blog/:id" component={Blogs}/>

                            <Route exact path="/user/how-to-use" component={HowToUseHome}/>

                            <Route exact path="/user/about-us" component={AboutUs}/>
                            <Route exact path="/native-esewa/:id" component={EsewaNativePage}/>

                            <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
                            <Route exact path="/terms-and-condition" component={TermsAndConditions}/>

                            <Route exact path="/user/reset-password" component={PasswordReset}/>

                            <Route exact path="/user/forget-password" component={ForgetPasswordLinkCreation}/>

                            <Route exact path="/error" component={ErrorPage}/>

                            {
                                localStorage.getItem('user') !== null ?         //checking if the localstorage has token,
                                    null
                                    // <Redirect exact from="/user/login" to="/user/dashboard"/>       //sending this to the protected route created re-rendering problem
                                    :
                                    <Redirect exact from="/user/dashboard" to="/"/>
                            }

                            <PrivateRoute path="/user/dashboard">
                                <Drawer/>
                            </PrivateRoute>


                            {/*<Redirect exact from="/**" to="/page-not-found"/>*/}
                        </Switch>

                    </Suspense>

                </ErrorBoundary>

            </div>
        );
    }
}

function PrivateRoute({children, ...rest}) {
    return (
        <Route
            {...rest}
            render={({location}) =>
                localStorage.getItem('user') === null ? (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: {from: location}
                        }}
                    />
                ) : (
                    children
                )
            }
        />
    );
}

function PrivateLoginRoute({children, ...rest}) {
    return (
        <Route
            {...rest}
            render={({location}) =>
                localStorage.getItem('user') !== null ? (
                    <Redirect
                        to={{
                            pathname: '/user/dashboard',
                            state: {from: location}
                        }}
                    />
                ) : (
                    children
                )
            }
        />
    );
}


const mapStateToProps = state => {
    return {
        activeStepFromRedux: state.account.activeStep,
        changeStepFromRedux: state.account.changeStep,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changePopUpStatus: (value) => {
            dispatch({type: 'changePopUpStatus', value: value})
        },


    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Routes));
