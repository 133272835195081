const initialState = {};

const TestPreviewReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'changeTestPreview':

            return {

                ...action.value,
                testListType: action.testListType

            };

        case 'clearTestPreview':

            return {

                ...initialState

            };


        default:
            return state;
    }

};


export default TestPreviewReducer;
