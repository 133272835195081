const initialState = {
    open: false,
    severity: null,
    message: null
};

const AlertReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'removeAlert':

            return {
                ...state,
                open: false
            };

        case 'showAlert':

            return {
                open: action.value.open,
                severity: action.value.severity,
                message: action.value.message
            };

        default:

            return state

    }

};


export default AlertReducer;